import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import LpowMap from "../components/LpowMap";
import { showLigplek, createLigplek, updateLigplek, setZoomLevel } from '../actions/LpowActions'

import { get_liglekken_near_position  } from '../components/LpowUtils'

const mapStateToProps = state => {
    // let position = state.position;
    // console.log("LpowMapContainer mapStateToProps state: "+state + " state.position: "+state.position);

    let _favourite_lpow_markers = [];
    if(state.lpowState.favourite_ligplekken?.ligplekken) {
       _favourite_lpow_markers = state.lpowState.favourite_ligplekken.ligplekken;
    }

    let _adopted_lpow_markers = [];
    if(state.lpowState.adopted_ligplekken?.ligplekken) {
       _adopted_lpow_markers = state.lpowState.adopted_ligplekken.ligplekken;
    }
    // todo: onderstaande moet verdwijnen ten favoure van bovenstaande
    if(state.adoptedLigplek.adopted_ligplekken?.ligplekken) {
       _adopted_lpow_markers = state.adoptedLigplek.adopted_ligplekken.ligplekken;
    }

    let _created_lpow_markers = [];
    if(state.lpowState.created_ligplekken?.ligplekken) {
       _created_lpow_markers = state.lpowState.created_ligplekken?.ligplekken;
    }

    let _selected_lpow_markers = [];
    if(state.lpowState.selected_ligplekken?.ligplekken) {
       _selected_lpow_markers = state.lpowState.selected_ligplekken.ligplekken;
    }

    let _search_result_lpow_markers = [];
    if(state.search.selected_ligplekken) {
       _search_result_lpow_markers = state.search.selected_ligplekken;
    }

    let _categorieen_markers = [];
    if(state.lpowState.selected_categorieen?.ligplekken) {
        _categorieen_markers = state.lpowState.selected_categorieen.ligplekken;
    }

    let _vaarwegen_markers = [];
    if(state.lpowState.selected_vaarwegen?.ligplekken) {
        _vaarwegen_markers = state.lpowState.selected_vaarwegen.ligplekken;
    }

    let _lpow_markers_at_location = get_liglekken_near_position(state.lpowState.ligplekken?.ligplekken, state.position);
    return { // refresh: Date.now(),
             base_layer_mapnik: state.lpowMap.base_layer_mapnik,
             base_layer_black_white: state.lpowMap.base_layer_black_white,

             zoom: state.lpowMap.zoom,
             center: state.lpowMap.center,
             position: state.position,

             ligplek_list: state.lpowState.ligplekken?.ligplekken,
             lpow_markers: state.lpowMap.lpow_markers,

             selected_lpow_markers: _selected_lpow_markers,
             favourite_lpow_markers: _favourite_lpow_markers,
             adopted_lpow_markers: _adopted_lpow_markers,
             created_lpow_markers: _created_lpow_markers,
             search_result_lpow_markers: _search_result_lpow_markers,
             lpow_markers_at_location: _lpow_markers_at_location,
             lpow_markers_categorieen: _categorieen_markers,
             lpow_markers_vaarwegen: _vaarwegen_markers,

             overlay_indebuurt: state.lpowMap.overlay_indebuurt,
             overlay_alleligplekken: state.lpowMap.overlay_alleligplekken,

             overlay_categorieen: state.lpowMap.overlay_categorieen,
             overlay_vaarwegen: state.lpowMap.overlay_vaarwegen,

             overlay_geselecteerdeligplekken: state.lpowMap.overlay_geselecteerdeligplekken,
             overlay_zoekresultaat: state.lpowMap.overlay_zoekresultaat,
             overlay_favorieteligplekken: state.lpowMap.overlay_favorieteligplekken,
             overlay_adoptedligplekken: state.lpowMap.overlay_adoptedligplekken,
             overlay_createdligplekken: state.lpowMap.overlay_createdligplekken,

             location_popup_show: state.lpowMap.location_popup_show,
             location_popup_show_level: 1 // wat is dit?
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
      dispatch,
    }
}


const LpowMapContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LpowMap)

export default LpowMapContainer
