import fetch from 'cross-fetch'
import {getCookie, } from "../components/LpowUtils"
import {Auth, } from "../components/Auth"
import {protDomPort, requestMode} from './LpowActions'
import {receiveLoginFail, } from './AuthActions'

export const AUTH_PROFILE_SUCCES = "AUTH_PROFILE_SUCCES";
export const AUTH_PROFILE_FAIL = "AUTH_PROFILE_FAIL";
export const AUTH_PROFILE_UPDATE = "AUTH_PROFILE_UPDATE";
export const AUTH_PROFILE_UPDATE_SUCCES = "AUTH_PROFILE_UPDATE_SUCCES";
export const AUTH_PROFILE_UPDATE_FAIL = "AUTH_PROFILE_UPDATE_FAIL";
export const VIEW_PROFILE = 'VIEW_PROFILE';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const SET_PROFILE_FIRST_NAME = 'SET_PROFILE_FIRST_NAME';
export const SET_PROFILE_LAST_NAME = 'SET_PROFILE_LAST_NAME';
export const SET_PROFILE_SHIP_NAME = 'SET_PROFILE_SHIP_NAME';
export const SET_PROFILE_SHIP_IMG = 'SET_PROFILE_SHIP_IMG';
// export const CLEAR_AUTH_PROFILE = "CLEAR_AUTH_PROFILE";
export const PROFILE_CLEAR = "PROFILE_CLEAR";
export const CLEAR_AUTH_PROFILE = "CLEAR_AUTH_PROFILE";


export function editProfile() {
  return { type: EDIT_PROFILE,

         }
}

export function viewProfile() {
  return { type: VIEW_PROFILE,

         }
}

export function getProfile(token) {
    const requestOptions = {
        method: 'GET',
        mode: requestMode,
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   // 'X-CSRFToken': csrftoken,
                   'Authorization': 'Token '+token  // + Auth.getAuthToken()
                 },
    };
  return function (dispatch) {
      return fetch(protDomPort+"/lpow/api/user/profile/", requestOptions)
            .then(
                  (response) => {//console.log(response);
                                 if (response.status == 200)  {
                                     response.json().then(function(data) {
                                       // console.log(data);
                                       localStorage.setItem('lpowProfile', JSON.stringify(data));
                                       dispatch(receiveProfileSucces(data))
                                     });

                                 } else {
                                     response.json().then(function(data) {
                                       localStorage.removeItem('lpowProfile'); // Profile weghalen
                                       dispatch(receiveLoginFail({"MSG":'Token Error'})); // triggers localStorage.removeItem('lpowAuth');
                                       dispatch(receiveProfileFail(data))
                                     });
                                 }
                              },
                  error => dispatch(receiveLoginFail(error))
            )
  }

}

export function receiveProfileSucces(profile) {

  return { type: AUTH_PROFILE_SUCCES,
           profile,
         }

}

export function receiveProfileFail(error_data) {

  return { type: AUTH_PROFILE_FAIL,
           error_data,
         }

}


export function clearProfile() {

  return { type: PROFILE_CLEAR
         }

}


export function doProfileUpdate(newProfile) {
    // Todo: Dit lijkt niet gebruikt te worden
    const requestOptions = {
        method: 'POST',
        mode: requestMode,
        headers: { 'Accept': 'application/json',
                   'Authorization': 'Token '+this.props.token
                 },
        body: JSON.stringify(newProfile)
    };
    return function (dispatch) {
      // /lpow/api/user/1/profile/
      return fetch(protDomPort+"/lpow/api/rest-auth/profile/", requestOptions)
             .then(
                  (response) => {
                                 if (response.status == 200)  {
                                     response.json().then(function(data) {
                                       dispatch(receiveProfileUpdateSucces(data))
                                     });

                                 } else {
                                     response.json().then(function(data) {
                                       // console.log(data);
                                       // non_field_errors
                                       // email
                                       dispatch(receiveProfileUpdateFail(data))
                                     });
                                 }
                              },
                  error => dispatch(receiveLoginFail(error)) )
    }

}


export function receiveProfileUpdateSucces(profile) {
  console.log("receiveProfileUpdateSucces: "+profile);
  return { type: AUTH_PROFILE_UPDATE_SUCCES,
           profile,
         }
}


export function receiveProfileUpdateFail(error_data) {

  return { type: AUTH_PROFILE_UPDATE_FAIL,
           error_data,
         }

}

export function fetchProfile() {
  var empty_profile = { user: { first_name: '',
                                last_name: '',
                                username: ''},
                          ship_name: '',
                          ship_img: '',
                          ship_icon: '/img/ship-marker-default.png',
                          ship_img_thumbnail_url: ''};

  return function (dispatch) {
      // initial action
      var lpowProfile = localStorage.getItem('lpowProfile');
      try {
          lpowProfile = JSON.parse(lpowProfile);
      } catch (e) {
          // console.error("Using empty Settings because of error: "+e);
          lpowProfile = empty_profile;
      }
      if (lpowProfile) { // Using the locally stored data
          dispatch(receiveProfileSucces(lpowProfile));
      }
      else {
          // for now:
          // initialize an empty favourite list.
          // if we are logged in, a serverside stored favourite list could be retrieved
          localStorage.setItem('lpowProfile', JSON.stringify(empty_profile));
          dispatch(receiveProfileSucces(empty_profile));
          /* inform that the API call is starting.
          dispatch(requestSelectedLigplekken())
          return fetch('///'+siteDomain+'/lpow/api/selected-ligplek-list/')
                .then(
                      response => response.json(),
                      // Do not use catch,... https://github.com/facebook/react/issues/6895
                      error => console.log('An error occurred.', error)
                )
                .then(
                    // followup action
                    json=> { localStorage.setItem('lpowFavouriteLigplekken', JSON.stringify(json));
                             dispatch(receiveFavouriteLigplekken(json)) }
                )
          }
          */
      }
  }

}


export function updateProfileLocationSuccess(result) {
}


export function updateProfileLocationFail(error_data) {
}


export function updateProfileLocation(location) {

    return function (dispatch) {
      // /lpow/api/user/1/profile/
      return fetch(protDomPort+"/lpow/api/rest-auth/profile/update-ship-space-time/", requestOptions)
             .then(
                  (response) => {
                                 if (response.status == 200)  {
                                     response.json().then(function(data) {
                                       dispatch(receiveProfileUpdateLocationSucces(data))
                                     });

                                 } else {
                                     response.json().then(function(data) {
                                       dispatch(receiveProfileUpdateLocationFail(data))
                                     });
                                 }
                              },
                  error => dispatch(receiveLoginFail(error)) )
    }


}