import fetch from 'cross-fetch'
import {getCookie, } from "../components/LpowUtils"
import {Auth, } from "../components/Auth"
import {protDomPort, requestMode } from './LpowActions'
import {getProfile, } from './ProfileActions'
import {getAdoptedLigplekken, } from './AdoptedLigplekActions'


export const AUTH_REGISTER = "AUTH_REGISTER";
export const AUTH_REGISTER_REQUEST = "AUTH_REGISTER_REQUEST";
export const AUTH_REGISTER_SUCCES = "AUTH_REGISTER_SUCCES";
export const AUTH_REGISTER_FAIL = "AUTH_REGISTER_FAIL";
export const AUTH_LOGIN_NO_PWD = "AUTH_LOGIN_NO_PWD";
export const AUTH_LOGIN_NO_PWD_REQUEST = "AUTH_LOGIN_NO_PWD_REQUEST";
export const AUTH_LOGIN_NO_PWD_SUCCES = "AUTH_LOGIN_NO_PWD_SUCCES";
export const AUTH_LOGIN_NO_PWD_FAIL = "AUTH_LOGIN_NO_PWD_FAIL";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_REQUEST = "AUTH_LOGIN_REQUEST";
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";
export const AUTH_LOGIN_SUCCES = "AUTH_LOGIN_SUCCES";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_LOGOUT_REQUEST = "AUTH_LOGOUT_REQUEST";
export const AUTH_LOGOUT_FAIL = "AUTH_LOGOUT_FAIL";
export const AUTH_LOGOUT_SUCCES = "AUTH_LOGOUT_SUCCES";
// export const CLEAR_AUTH_PROFILE = "CLEAR_AUTH_PROFILE";
export const AUTH_CLEAR = "AUTH_CLEAR";

export const AUTH_PWD_RESET = "AUTH_PWD_RESET";
export const AUTH_PWD_RESET_SUCCES = "AUTH_PWD_RESET_SUCCES";
export const AUTH_PWD_RESET_FAIL = "AUTH_PWD_RESET_FAIL";

// console.log("AuthActions: getCookie "+getCookie);

export function showRegister() {
  // console.log("showPasswordReset");
  return { type: AUTH_REGISTER,
           error: {},
         }
}

export function register(first_name, last_name, email, password) {
  return { type: AUTH_REGISTER_REQUEST,
           requestedAt: Date.now(),
           first_name,
           last_name,
           email,
           password,
         }
}

function XXgetCookie(name) {
    if (!document.cookie) {
      return null;
    }
    const token = document.cookie.split(';')
      .map(c => c.trim())
      .filter(c => c.startsWith(name + '='));

    if (token.length === 0) {
      return null;
    }
    return decodeURIComponent(token[0].split('=')[1]);
}

export function doRegister(username, password1, password2) {

    const csrftoken = getCookie('csrftoken')
    const requestOptions = {
        method: 'POST',
        mode: requestMode,
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'X-CSRFToken': csrftoken,
                 },
        body: JSON.stringify({ email: username,
                               username,
                               password: password1,
                               password1,
                               password2})
    };


    return function (dispatch) {
      return fetch(protDomPort+"/lpow/api/rest-auth/registration/", requestOptions)
            .then(
                  (response) => {
                                 if (response.status == 201)  {
                                       dispatch(receiveRegisterSucces()) // email adres meegeven voor terugkoppeling?
                                 } else {
                                     try {
                                         response.json().then(function(data) {
                                             dispatch(receiveRegisterFail(data))
                                         });
                                     }
                                     catch (e) {
                                         const error_data = "Er is iets verkeerd gegaan met Registreren. "
                                         dispatch(receiveRegisterFail(error_data))
                                     }
                                 }
                              },
                  error => dispatch(receiveLoginFail(error))
            )
    }

}

export function receiveRegisterSucces() {

  return { type: AUTH_REGISTER_SUCCES,
         }

}

export function receiveRegisterFail(error_data) {

  return { type: AUTH_REGISTER_FAIL,
           error_data,
         }

}


export function  fetchAuthentication() {

  return function (dispatch) {
      // initial action
      let lpowAuth = localStorage.getItem('lpowAuth');
      if (lpowAuth != null) { // Using the locally stored data
          const _lpowAuth = JSON.parse(lpowAuth);
          const token_key = _lpowAuth.token_key;
          // test the token!!!!
          if(token_key) {
             dispatch(getProfile(token_key)); // Dit is dan de Test van De Token
             dispatch(receiveLoginSucces( token_key ));
             dispatch(getAdoptedLigplekken(token_key));
          }
      } else {
          const json= []
          localStorage.setItem('lpowAuth', JSON.stringify(json));
          // dispatch(receiveAuthentication(json));
      }
  }

}

export function requestLogin() {
  return { type: AUTH_LOGIN_REQUEST
         }
}

export function doLogin(username, password) {
    const csrftoken = getCookie('csrftoken');
    const requestOptions = {
        method: 'POST',
        mode: requestMode,
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   // 'Access-Control-Allow-Headers': 'Content-Type',
                   // 'Access-Control-Allow-Headers': 'X-CSRF-Token, Content-Type',
                   // 'Access-Control-Allow-Origin': "*",
                   'X-CSRFToken': csrftoken,
                 },
        body: JSON.stringify({ email: username,
                               username,
                               password })
    };

  return function (dispatch) {
      dispatch(requestLogin());
      return fetch(protDomPort+"/lpow/api/get-auth-token/", requestOptions)
            .then(
                   response => { if (response.status == 200)  {
                                    response.json().then( json => {
                                       dispatch(receiveLoginSucces(json.token));
                                       dispatch(getProfile(json.token));
                                       dispatch(getAdoptedLigplekken(json.token));
                                    });
                                 } else {
                                     response.json().then(function(data) {
                                       // console.log(data);
                                       // non_field_errors
                                       // email
                                       dispatch(receiveLoginFail(data))
                                     });
                                 }
                              },
                  error => dispatch(receiveLoginFail(error))
            )
  }
}


export function receiveLoginSucces(token_key) {
  const _lpowAuth = {
      token_key,
  }
  localStorage.setItem('lpowAuth', JSON.stringify(_lpowAuth));
  return { type: AUTH_LOGIN_SUCCES,
           token_key,
         }
}


export function receiveLoginFail(error) {
  console.log("receiveFoginFail: "+error);
  localStorage.removeItem('lpowAuth');
  return { type: AUTH_LOGIN_FAIL,
           error,
         }
}


export function doLogout(key) {
    const csrftoken = getCookie('csrftoken')

    const requestOptions = {
        method: 'POST',
        mode: requestMode,
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'X-CSRFToken': csrftoken,
                 },
        body: JSON.stringify({ key })
    };

  return function (dispatch) {
      return fetch(protDomPort+"/lpow/api/rest-auth/logout/", requestOptions)
            .then(
                  (response) => {if (response.status == 200)  {
                                     response.json().then(function(data) {
                                       dispatch(receiveLogoutSucces());
                                     });
                                 } else {
                                     response.json().then(function(data) {
                                       dispatch(receiveLogoutFail(data));
                                     });
                                 }
                              },
                  error => dispatch(receiveLogoutFail(error))
            )
  }
}

export function receiveLogoutSucces() {
  const _lpowAuth = {
      token_key: "",
  }
  const empty_profile = { user: { first_name: '',
                                last_name: '',
                                username: ''},
                          ship_name: '',
                          ship_img: '',
                          ship_icon: '/img/ship-marker-default.png',
                          ship_img_thumbnail_url: ''};
  localStorage.setItem('lpowAuth', JSON.stringify(_lpowAuth));
  localStorage.setItem('lpowProfile', JSON.stringify(empty_profile));
  localStorage.setItem('lpowAdoptedLigplekken', JSON.stringify([]));
  return { type: AUTH_LOGOUT_SUCCES,
         }
}

export function receiveLogoutFail(error) {
  return { type: AUTH_LOGOUT_FAIL,
           error,
         }
}

export function receivePasswordResetSucces(data) {
  console.log("receivePasswordResetSucces "+data);
  const _lpowAuth = {
      token_key: "",
  }
  localStorage.setItem('lpowAuth', JSON.stringify(_lpowAuth));
  return { type: AUTH_PWD_RESET_SUCCES,
           // data,
         }
}

export function receivePasswordResetFail(error) {
  const _lpowAuth = {
      token_key: "",
  }
  localStorage.setItem('lpowAuth', JSON.stringify(_lpowAuth));
  return { type: AUTH_PWD_RESET_FAIL,
           error,
         }
}

export function showPasswordReset() {
  // console.log("showPasswordReset");
  return { type: AUTH_PWD_RESET,
           error: {},
         }
}

export function doPasswordReset(email) {
    const csrftoken = getCookie('csrftoken')
    const requestOptions = {
        method: 'POST',
        mode: requestMode,
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'X-CSRFToken': csrftoken, },
        body: JSON.stringify({ email })
    };

  return function (dispatch) {
      return fetch(protDomPort+"/lpow/api/rest-auth/password/reset/", requestOptions)
            .then((response) => {if (response.status == 200)  {
                                     response.json().then(function(data) {
                                       dispatch(receivePasswordResetSucces(data));
                                     });

                                 } else {
                                     response.json().then(function(data) {
                                       dispatch(receivePasswordResetFail(data))
                                     });
                                 }
                              },
                  error => dispatch(receiveLogoutFail(error))
            )
  }

}

//function receiveAuthentication(json) {
//
//}
