import React from "react"
import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import App from '../components/App'


const mapStateToProps = state => {
    return {
       // map: state.lpowMap.map,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch,
    }
}

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(App)


export default AppContainer
