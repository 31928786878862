import { connect } from 'react-redux'
import LigplekSearch from "../components/LigplekSearch";

import { toggleLigplekToSearchResult, } from '../actions/SearchActions'

// Gebeurd hier omdat .lpowState.ligplekken de lijst levert, en .search de zoek-waarden
function createSearchResult(state){
      console.log(state+" ............ "+state.search);
      var re = null;
      if (state.search.filter_text && state.search.filter_text.length>=1) {
         re =new RegExp(state.search.filter_text, 'i');
      }
      let newFilteredLigpleklist = [];
      state.lpowState.ligplekken.ligplekken.forEach(function(ligplek) {
              // console.log("test ligplek "+ligplek.id);
              //if (ligplek.max_length < action.searchFilters.lengthFilter) {
              //   return // reject
              //}
              //if (ligplek.min_length < action.searchFilters.lengthFilter) {
              //   return // reject
              //}

              if (ligplek.distance &&
                  ligplek.distance > state.search.distance_filter) {
                 return // reject
              }

              if (ligplek.maximale_diepgang < state.search.draft_filter) {
                 return // reject
              }

              if(re) {
                  if (ligplek.name.search(re) == -1 &&
                      ligplek.description.search(re) == -1 &&
                      ligplek.description_long.search(re) == -1){
                     return // reject
                  }
              }
              // accept
              newFilteredLigpleklist.push(ligplek);
      });
      // console.log("newFilteredLigpleklist.length: "+newFilteredLigpleklist.length);
      return newFilteredLigpleklist
}




/**
 * zoek criteria worden geinitialiseerd met de waarden in Settings
 *   - diepgang (draft)
 *   - afstand (distance) hemeslbrede afstandtussen 'locatie' en 'coordinaten ligplek'
 *
 *
 *
 */
const mapStateToProps = state => {
    const _settings = state.settings;
    let _preferedDraft = state.search.draft_filter ? parseInt(state.search.draft_filter) : parseInt(_settings.draft_filter);
    let _preferedDistance = state.search.distance_filter ? parseInt(state.search.distance_filter) : parseInt(_settings.distance_filter);
    // let search_result = createSearchResult(state);
    // console.log("state.selected: "+state.selected);
    return { search_result: state.search.search_result,
             selected_ligplekken: state.search.selected_ligplekken,
             filter_text: state.search.filter_text,
             filter_draft: _preferedDraft,
             filter_distance: _preferedDistance,
             last_changed: state.search.changed,
             position: state.position
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch,
      // onderstaande functies worden via algemen functie-calls, gemapped naar specefieke uitvoeringen
      selectLigplek: id => { // hernoemen naar toggleLigplek?
          dispatch( toggleLigplekToSearchResult(id) );
      },
    }
}


const LigplekSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LigplekSearch)

export default LigplekSearchContainer
