import { combineReducers } from 'redux'

// import { reducer as formReducer } from 'redux-form'

import AboutReducer from './AboutReducer'
import LpowReducer from './LpowReducer'
import AuthReducer from './AuthReducer'
import ProfileReducer from './ProfileReducer'
import SettingsReducer from './SettingsReducer'
import SearchReducer from './SearchReducer'
import MapReducer from './MapReducer'
import PopupModalReducer from './PopupModalReducer'
import PositionReducer from './PositionReducer'
import LigplekModalReducer from './LigplekModalReducer'
import AdoptedLigplekReducer from './AdoptedLigplekReducer'
import MessageReducer from './MessageReducer'


const root_reducer =  combineReducers( {
   about: AboutReducer,
   lpowState: LpowReducer, // algemene Ligolekdata van de server
   search: SearchReducer,

   auth: AuthReducer, // Authenticatie
   profile: ProfileReducer,  // Gebruiker specifieke data
   adoptedLigplek: AdoptedLigplekReducer,
   settings: SettingsReducer,

   lpowMap: MapReducer,
   position: PositionReducer,
   react_message: MessageReducer,
   ligplekModal: LigplekModalReducer,
   popupModal: PopupModalReducer, // weet niet waar deze gebuikt wordt
//   form: formReducer
   })

export default root_reducer;