import { connect } from 'react-redux'
import LpowAbout from "../components/LpowAbout"


const mapStateToProps = state => {
    return {
        version: state.about.version,
        date: state.about.date,
        buildinfo: state.about.buildinfo
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
       dispatch,
    }
}


const LpowAboutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LpowAbout)

export default LpowAboutContainer
