import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import { LigplekList, } from "../components/LigplekListModule"
import { showLigplek,  } from '../actions/LpowActions'
import {} from "../actions/AdoptedLigplekActions"

class AdoptedList extends React.Component {

  constructor(props) {
    super(props);
  }

  static propTypes = {
      isAuthenticated: PropTypes.bool.isRequired,
      isFetching: PropTypes.bool.isRequired,
      list_data: PropTypes.object.isRequired,
      // showLigplek: PropTypes.func.isRequired,
      removeAdoptedLigplek: PropTypes.func.isRequired,
  }

  render() {
    if (this.props.isAuthenticated) {
        return (
            <div className="panel panel-primary adopted-list" id="result_panel">
              <div className="panel-heading">
                 <h3 className="panel-title">Geadopteerd</h3></div>
              <div className="panel-body">
                <LigplekList isFetching={this.props.isFetching}
                             ligpleklist={this.props.list_data.ligplek_list}
                             list_data={this.props.list_data}
                             dispatch={this.props.dispatch}
                             selectLigplek={this.props.removeAdoptedLigplek} // selecteren staat gelijk aan verwijderen uit geadopteerde lijst
                             selected_ligplekken={this.props.list_data.ligplek_list} // alle geadopteerde ligplekken krijgen een 'checked'
                             />
              </div>
            </div>
        );
    }
    return (
        <div className="panel panel-primary adopted-list" id="result_panel">
          <div className="panel-heading">
             <h3 className="panel-title">Geadopteerd</h3></div>
          <div className="panel-body">
            <span>Log in voor het bezien van Geadopteerde ligplekken.</span>
          </div>
        </div>
    );

  }
}

const mapStateToProps = state => {
    //console.log("Do AdoptedList state ", state.adoptedLigplek);
    let list = [];
    let is_fetching = false;
    try{
       //console.log("Do AdoptedList state ", state.adoptedLigplek);
       //console.log("Do AdoptedList state.adopted_ligplekken ", state.adoptedLigplek.adopted_ligplekken);

       list = state.adoptedLigplek?.adopted_ligplekken?.ligplekken ? state.adoptedLigplek.adopted_ligplekken.ligplekken : [];
       is_fetching = state.adoptedLigplek?.adopted_ligplekken?.isFetching ? state.adoptedLigplek.adopted_ligplekken.isFetching : false;
    } catch (e) {
      console.error("Nog Geen Geadpteerde Ligplekken.: "+e);
      list = [];
      is_fetching = false;
    }
    var list_data = {
       name: "Geadopteerd",
       ligplek_list: list,
    }

    return {
       isAuthenticated: state.auth.token_key ? true : false,
       // isFetching: state.adoptedLigplek.adopted_ligplekken.isFetching,
       isFetching: is_fetching,
       list_data,
       lastUpdate: Date.now(),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch,
      // onderstaande functies worden via algemen functie-calls, gemapped naar specefieke uitvoeringen
      removeAdoptedLigplek: id => {
         console.log("Dispatch  remove Adopted Ligplek("+id+")");
         dispatch( removeAdoptedLigplek(id ) );
      }

    }
}

const AdoptedListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdoptedList)

export default AdoptedListContainer;
