import React from 'react';
import PropTypes from "prop-types";
import { useDispatch } from 'react-redux';
import LinkContainer from 'react-router-bootstrap';
import { Button, Collapse,
         Navbar, NavbarToggler, NavbarBrand, Nav, NavItem,NavLink,
         UncontrolledDropdown,DropdownToggle,DropdownMenu, DropdownItem, } from 'reactstrap';
import { createLigplek } from '../actions/LpowActions';
import { setZoomLevel, hideAllOverlays, showOverlay } from '../actions/MapActions';
import { POSITION_HAND, POSITION_GPS_ONCE, POSITION_GPS_WATCH, POSITION_GPS_OFF } from '../actions/PositionActions';
import { updateCurrentPosition, startWatchPosition, stopWatchPosition } from '../actions/PositionActions';

import { setMessage } from '../actions/MessageActions';
import { addZero, precisionOnClass  } from './LpowUtils';



class AuthenticationMenu extends React.Component {

  constructor(props) {
    super(props)
  }

  static propTypes = {
    toggleNavBar: PropTypes.func.isRequired,
  };

  renderRegisterLogin() {
   return (
       <NavItem>
            <NavLink href="#/login/">Aanmelden</NavLink>
       </NavItem>
   )
  }

  renderLogout() {
   return (
       <NavItem>
            <NavLink href="#/logout/">Afmelden</NavLink>
       </NavItem>
   )
  }

  render() {
   // const loggedin = false;
   if (this.props.token) {
     return (
            <div>
            <DropdownToggle nav caret>
              Logout
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem  href="#/logout/" onClick={this.props.toggleNavBar} >
               Afmelden
              </DropdownItem>
            </DropdownMenu>
         </div>
     )
   } else {
     return (
         <div>
            <DropdownToggle nav caret>
              Login
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem href="#/register/" onClick={this.props.toggleNavBar} >
               Registeren
              </DropdownItem>
              <DropdownItem href="#/login/passwd/" onClick={this.props.toggleNavBar} >
               Aanmelden met wachtwoord
              </DropdownItem>
              <DropdownItem href="#/login/no-passwd/" onClick={this.props.toggleNavBar} >
               Aanmelden zonder wachtwoord
              </DropdownItem>
            </DropdownMenu>
         </div>
      )
   }
  }
}



function AutomaticLocationUpdate({map}) {
}

function createNewLigplek(position, zoom) {
     if(zoom<18) {
         const text_message = "Te lage Zoom("+zoom+"). Zoom naar niveau 18 voor aanmelden nieuwe ligplek";
         // alert = useAlert();
         const new_message = {
           message: text_message,
           next: true,
           error: false,
           icon: "info",
           duration:5000,
         }
         this.props.dispatch(setMessage( new_message ));
         // console.log("SHOW MESSAGE: "+text_message);
         // alert.show(text_message);
     } else {
         let dispatch = useDispatch();
         const latlng = [position.coords.latitude,
                         position.coords.longitude];
         dispatch(createLigplek(latlng));
     }
  }

function LigplekAddIcon(data) {
      console.log("renderAddLigplekIcon zoomLEvel "+data.zoom);
      let clazzNames = "ligplek_add"
      if(data.zoom==18) {
           clazzNames += " add_active"
      }
      return( <img src="/img/aangebracht_ster_512x512.png" className={clazzNames} alt="+Ligplek" /> )
  }

function LpowPostition(data){
    console.log("LpowPostition(data): "+data);
    const _classes = "lpow-location "+precisionOnClass(data.position.coords.accuracy);
    // const alert = useAlert();
    const dispatch = useDispatch();

    if(data.position) {
      let _latitude  = data.position.coords.latitude.toFixed(4);
      let _longitude = data.position.coords.longitude.toFixed(4);
      let _precision = data.position.coords.accuracy.toFixed(0);
      let _date_stamp = new Date(data.position.timestamp);
      let _minutes = addZero(_date_stamp.getMinutes());
      let _hour = addZero(_date_stamp.getHours());

      const text_message = "Te lage Zoom("+data.zoom+"). Zoom naar niveau 18 voor aanmelden nieuwe ligplek";
      // return(<div className={_classes}  onClick={this.startAutomPositionUpdate}>
      return(<div className={_classes}  onClick={() =>
                    { if(data.zoom<18) {
                      const new_message = {
                           message: "Aanbrengen Ligplek: "+text_message,
                           next: true,
                           error: false,
                           icon: "info",
                           duration:5000,
                        }
                        dispatch(setMessage(new_message));

                      } else {
                         const latlng = [data.position.coords.latitude,
                                         data.position.coords.longitude];
                         dispatch(createLigplek(latlng));
                      }
                     }}>
               <span>Lat: {_latitude}</span> <span>Lng: {_longitude}</span><br/>
               <span>prec: {_precision} / tijd: {_hour}:{_minutes}
                     <LigplekAddIcon zoom={data.zoom} /> </span><br/>
             </div>)
    }

    // return an ERROR like location indicator
    return(<div className={_classes} >
             <span>Lng: --.----</span><br/>
             <span>Lat: --.----</span><br/>
             <span>prec: -- / tijd: --:--</span>
           </div>)

}

class LpowNavBar extends React.Component {

  constructor(props) {
    super(props);
    console.log("LpowNavBrar constructor");
    this.toggle = this.toggle.bind(this);
    this.renderPosition = this.renderPosition.bind(this);
    this.renderPositionSource = this.renderPositionSource.bind(this);
    this.renderZoomAndLock = this.renderZoomAndLock.bind(this);
    this.renderAddLigplekIcon = this.renderAddLigplekIcon.bind(this);
    this.renderPositionAndZoom = this.renderPositionAndZoom.bind(this);
    this.createNewLigplek = this.createNewLigplek.bind(this);

    this.closeNavBar = this.closeNavBar.bind(this);
    this.togglePane = this.togglePane.bind(this);
    this.toggleZoomToPosition = this.toggleZoomToPosition.bind(this);
    this.startAutomPositionUpdate = this.startAutomPositionUpdate.bind(this); // Naar "Instellingen"?
    this.stopAutomPositionUpdate = this.stopAutomPositionUpdate.bind(this);
    this.requestNewPosition = this.requestNewPosition.bind(this);
    this.renderAuthenticationProfile = this.renderAuthenticationProfile.bind(this);
  }

  state = {
      isOpen: false
  }

  static propTypes = {
    position: PropTypes.object.isRequired,
    zoom: PropTypes.number.isRequired,
    token: PropTypes.string.isRequired,
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  togglePane(ligplekId) {
    this.props.dispatch( showLigplek(ligplekId) );
  }

  toggleZoomToPosition() {
    this.toggle();
    this.props.dispatch( hideAllOverlays() );
    this.props.dispatch( showOverlay("overlay_indebuurt") );
    this.props.dispatch( updateCurrentPosition() );
    this.props.dispatch( setZoomLevel(10) );
  }

  startAutomPositionUpdate() {
      // eerst een keer doen voordat WatchPosition actief wordt
      this.props.dispatch( updateCurrentPosition() );
      this.props.dispatch( setZoomLevel(10) );
      this.props.dispatch( startWatchPosition() );
  }

  stopAutomPositionUpdate() {
      this.props.dispatch( stopWatchPosition() );
  }

  createNewLigplek() {
     if(this.props.zoom<18) {
         const text_message = "Te lage Zoom("+this.props.zoom+"). Zoom naar niveau 18 voor aanmelden nieuwe ligplek";
         const new_message = {
           message: text_message,
           next: true,
           error: false,
           icon: "info",
           duration:5000,
         }
         this.props.dispatch(setMessage( new_message ));
         // alert.show(text_message);
         // console.log("SHOW MESSAGE: "+text_message);
     } else {
         const latlng = [this.props.position.coords.latitude,
                         this.props.position.coords.longitude];
         this.props.dispatch(createLigplek(latlng));
     }
  }

  requestNewPosition() {
     this.props.dispatch( updateCurrentPosition() );
  }

  closeNavBar() {
      this.setState({
      isOpen: false
    });

  }

  doStartHelp(){ // copy van LpowAbout
    var ref = cordova.InAppBrowser.open("https://www.lvbhb.nl/wp-content/uploads/2019/07/LPOW-handleiding0.9.12.pdf", "_system", "");
  }

  renderAuthenticationProfile() {
      if(this.props.token) {
          return (<NavItem>
                    <NavLink href="#/profile/" onClick={this.toggle}>Profiel/Logout</NavLink>
                  </NavItem>)
      }
      return (<NavItem>
                <NavLink href="#/auth/" onClick={this.toggle}>Registeren/Login</NavLink>
              </NavItem>)
  }

  renderZoomAndLock() {
      // console.log("renderZoomAndLock this.props.zoom: "+this.props.zoom);
      // <div className="zoom-locked zoom-unlocked">Lck/Unlck</div>
      return( <div className="zoom-and-lock" onClick={this.toggleZoomLock}>
                 <div className="zoom">Zoom: {this.props.zoom}</div>
              </div>)
  }

  renderAddLigplekIcon(zoom) {
      console.log("renderAddLigplekIcon zoomLevel "+zoom);
      let clazzNames = "ligplek_add"
      if(zoom==18) {
           clazzNames += " add_active"
      }
      return( <img src="/img/aangebracht_ster_512x512.png" className={clazzNames} alt="+Ligplek" /> )
  }

  renderPositionSource(position) {
    switch (position.positionSource) {
        case POSITION_HAND:
            return( <div className="positionsource hand">HAND</div> )
        case POSITION_GPS_ONCE:
            return( <div className="positionsource gps_once">GPS(1x)</div> )
        case POSITION_GPS_WATCH:
            let _title = "WatchId:"+position.watchId;
            return( <div  className="positionsource gps_repeat" title={_title}  onClick={this.stopAutomPositionUpdate} >
                       GPS(Cont)
                    </div> )
        case POSITION_GPS_OFF:
            return( <div className="positionsource gps_off">GPS Uit</div> )
        default:
            return( <div className="positionsource ">UNKNOWN SOURCE({position.positionSource})</div> )
    }
    return( <div  className="positionsource fail">ERROR</div> )
  }

  renderPosition() {
    // console.log("renderPositionSource this.props.position.watchId: "+this.props.position.watchId);
    let _classes = "lpow-location "+precisionOnClass(this.props.position.coords.accuracy);

    if(this.props.position) {
      let _latitude  = this.props.position.coords.latitude.toFixed(4);
      let _longitude = this.props.position.coords.longitude.toFixed(4);
      let _precision = this.props.position.coords.accuracy.toFixed(0);
      let _date_stamp = new Date(this.props.position.timestamp);
      let _minutes = addZero(_date_stamp.getMinutes());
      let _hour = addZero(_date_stamp.getHours());

      return(<div className={_classes}  onClick={this.createNewLigplek}>
               <span>Lat: {_latitude}</span> <span>Lng: {_longitude}</span><br/>
               <span>prec: {_precision} / tijd: {_hour}:{_minutes}
                     { this.renderAddLigplekIcon(this.props.zoom) }
                     </span><br/>
             </div>)
    }

    // return an ERROR like location indicator
    return(<div className={_classes} >
             <span>Lng: --.----</span><br/>
             <span>Lat: --.----</span><br/>
             <span>prec: -- / tijd: --:--</span>
           </div>)
  }

  /*  <LpowPostition position={this.props.position} zoom={this.props.zoom} />  */
  renderPositionAndZoom() {
      console.log("renderPositionAndZoom zoom: "+this.props.zoom);
      return(<div className="position-and-zoom">
                { this.renderPosition() }
                <div className="postitionsource-and-zoom">
                    { this.renderPositionSource(this.props.position) }
                    { this.renderZoomAndLock() }
                </div>
             </div>)
  }


  render() {
    console.log("Navbar render")
    return (
        <Navbar color="light" light expand="md">
          <NavbarBrand href="#/about/" onClick={this.closeNavBar} ></NavbarBrand>

          { this.renderPositionAndZoom() }

          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>

              <NavItem>
                <NavLink href="#/about/" onClick={this.toggle}>Help</NavLink>
              </NavItem>

              { this.renderAuthenticationProfile() }

              <NavItem>
                <NavLink href="#/map/" onClick={this.toggle}>Kaart</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#/settings/" onClick={this.toggle} >Instellingen</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#/search/"  onClick={this.toggle}>Zoeken</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Ligplekken
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem  href="#/map/" onClick={this.toggleZoomToPosition}>
                    @Positie
                  </DropdownItem>
                  <DropdownItem href="#/ligplek/favorieten/"  onClick={this.toggle}>
                   Favorieten
                  </DropdownItem>
                  <DropdownItem href="#/ligplek/adopted/"  onClick={this.toggle}>
                   Geadopteerd
                  </DropdownItem>
                  <DropdownItem href="#/ligplek/categorie/" onClick={this.toggle}>
                   / Categorie
                  </DropdownItem>
                  <DropdownItem  href="#/ligplek/vaarweg/" onClick={this.toggle}>
                   / Vaarweg
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem  href="#/search/" onClick={this.toggle}>
                    Zoeken
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
    );
  }
}

export default LpowNavBar;
