import { connect } from 'react-redux'
import Profile from "../components/Profile"


const mapStateToProps = state => {
    let profileIsFetching = (state.profile ? false : true);
    let profileIsUpdating = (state.profile.updating ? true : false);
    return {
         // Stoppen en starten GPS zit nu even hier
         position: state.position, // en position is daar voor nodig

         initialValues: state.profile,  //todo: dit moet dus weg omdat
         profile: state.profile, // dit is een betere variable naam
         token: state.auth.token_key,
         isFetching: profileIsFetching,
         updatingProfile: profileIsUpdating
    }
}

const  mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
}

const ProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)


export default ProfileContainer;
