import React, { Component, useEffect, createRef, useRef, useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux'
import { useMap, useMapEvents, Marker,  Popup } from 'react-leaflet'
import LeafletTrackingMarker from 'react-leaflet-tracking-marker'
import { createShipIcon, precisionOnClass } from "../components/LpowUtils"; // Deze ook naar MapActionsReducer
import * as positionActions from '../actions/PositionActions';
import { setZoomLevel, setCenter } from "../actions/MapActions";



// Deze Als Tracking Marker: https://www.npmjs.com/package/react-leaflet-tracking-marker

function ShipMarkerPopup({position}) {
    let _longitude = position.coords.longitude.toFixed(4); // "-----";
    let _latitude = position.coords.latitude.toFixed(4); // "-----";
    const [shipMarkerPopup, setShipMarkerPopup] = useState(true)
    const toggleShipMarkerPopup = useCallback(() => {
        setShipMarkerPopup((d) => !d)
    }, []);

    if(shipMarkerPopup) {
        return (<Popup>
                    <div>Huidige Locatie  <div><span>Lat: {_latitude}</span>
                                               <span>Lng: {_longitude} </span></div>
                          <div>
                             <br/>Precisie is Afhankelijk van Gebruikte Voorziening.
                             <br/>
                             Verplaats deze marker naar een andere plek op de kaart om ligplekken rond een andere locatie te vinden.
                             <br/>
                             Voor het aanmelden van een ligplek: Zoom naar niveau 18 en klik op de locatie boven in het scherm.
                             <br/> <button value="0" onClick={toggleShipMarkerPopup}>Niet Meer Tonen</button>
                          </div>
                    </div>
                </Popup>)
    } else {
       return null
    }


}


// from https://react-leaflet.js.org/docs/example-external-state/
function DisplayPosition({ map }) {
  const [position, setPosition] = useState();//map.getCenter())

  const onClick = useCallback(() => {
    map.setView(center, zoom)
  }, [map])

  const onMove = useCallback(() => {
    setPosition(map.getCenter())
  }, [map])

  useEffect(() => {
    map.on('move', onMove)
    return () => {
      map.off('move', onMove)
    }
  }, [map, onMove])

  return (
    <p>
      latitude: {position.lat.toFixed(4)}, longitude: {position.lng.toFixed(4)}{' '}
      <button onClick={onClick}>reset</button>
    </p>
  )
}


function useEventListener(eventName, handler, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = event => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}



function AirplaneMarker({ data }) {
  const { latitude, longitude } = data
  const [prevPos, setPrevPos] = useState([latitude, longitude])

  useEffect(() => {
    if (prevPos[1] !== longitude && prevPos[0] !== latitude) setPrevPos([latitude, longitude])
  }, [latitude, longitude, prevPos])

  return <LeafletTrackingMarker icon={icon} position={[latitude, longitude]} previousPosition={prevPos} duration={1000} />
}

// Forceren zIndex op ShipMarker
// https://gist.github.com/up209d/4c68f2391f2302e510eb81aa8bcd4514

export function ShipMarker({state_position}) { // with or without popup
    const markerRef = useRef(null);
    const map = useMap();
    const dispatch = useDispatch();
    const _icon = createShipIcon("img/ship-marker-default.png");
    // const [zoom, setZoom] = useState(7);
    // const draggable = true;
    // const [shipMarkerPopup, setShipMarkerPupup] = useState(true)
    const eventHandlers = useMemo(
                            () => ({dragend() {
                                        const marker = markerRef.current;
                                        if (marker != null) {
                                          let markerLatLng = marker.getLatLng(); // get new Position now before it gets reset to center
                                          dispatch(positionActions.stopWatchPosition());
                                          //console.error("GPSWatch gestopt, bepalen nieuwe (hand)Posititie,  HAND indicatie aan");
                                          dispatch(positionActions.setPositionSource(positionActions.POSITION_HAND));
                                          // console.log("markerLatLng "+markerLatLng.lng+" "+markerLatLng.lat );
                                          let manualPosition = { coords: { latitude: markerLatLng.lat,
                                                                           longitude: markerLatLng.lng,
                                                                           accuracy: positionActions.POSITION_ACCURACY_BY_HAND,
                                                                           altitude: null,
                                                                           altitudeAccuracy: null,
                                                                           heading: null,
                                                                           speed: null},
                                                                 timestamp: Date.now() };
                                          dispatch(positionActions.receiveCurrentPosition(manualPosition));
                                          // console.error("panTo "+markerLatLng);
                                          // map.panTo(markerLatLng);
                                          // console.error("PANNING DONE");
                                          dispatch(setCenter([manualPosition.coords.latitude,
                                                              manualPosition.coords.longitude]));
                                        }
                                      },
                                      dblclick(event) {
                                        console.log("LpowShipMarker DUBBEL KLICK: "+event);
                                      },
                                    }
                                     ), []);
    return (
          <Marker key="location-of-vessel-1"
                className="my-vessel"
                alt="mijn vaartuig"
                ref={markerRef}
                draggable="true"
                eventHandlers={eventHandlers}
                zIndexOffset="11" // Scheepje altijd zichtbaar? hogere waarde geeft problemen?
                forceZIndex="6000000" // zie index.js waarom forceIndex
                position={[state_position.coords.latitude, state_position.coords.longitude]}
                icon={_icon}
                >
              <ShipMarkerPopup position={state_position}/>
          </Marker>)
}

// ShipMarker met een witte afbeelding. Blijft als een soort van 'schaduw' achter als ShipMarker wordt verplaatst
export function ShipMarkerShade(props) {
    // console.log("props.icon_url: "+props.icon_url);
    let _icon = createShipIcon("img/ship-marker-shade.png");
    return (
          <Marker key="location-of-vessel-1-shade"
                className="my-vessel"
                zIndexOffset="0" // Scheepje altijd zichtbaar? hogere waarde geeft problemen?
                forceZIndex="6000000" // zie index.js waarom forceIndex
                position={[props.position.coords.latitude, props.position.coords.longitude]}
                icon={_icon}
                >
          </Marker>)
}


function  createNewLigplek(position) {
     let map=useMap();
     let dispatch = useDispatch();
     let zoom = map.getZoom();
     if(zoom<18) {
         let new_message = {
           message:"Te lage Zoom("+zoom+"). Zoom naar niveau 18 voor aanmelden nieuwe ligplek",
           next: true,
           error: false,
           icon: "info",
           duration:5000,
         }
         dispatch(setMessage( new_message ));

     } else {
         const latlng = [position.coords.latitude,
                         position.coords.longitude];
         dispatch(createLigplek(latlng));
     }
  }


// todo: naar LpowPosition
export function DisplayPositionNavBar({state_position}) { // with or without popup
    // console.log("DisplayPositionNavBar state_position.coords.accuracy: "+state_position.coords.accuracy);
    let _classes = "lpow-location "+precisionOnClass(state_position.coords.accuracy);
    if(state_position) {
      let _latitude  = state_position.coords.latitude.toFixed(4);
      let _longitude = state_position.coords.longitude.toFixed(4);
      let _precision = state_position.coords.accuracy.toFixed(0);
      let _date_stamp = new Date(state_position.timestamp);
      let _minutes = addZero(_date_stamp.getMinutes());
      let _hour = addZero(_date_stamp.getHours());

      return(<div className={_classes}  onClick={createNewLigplek(state_position)}>
               <span>Lat: {_latitude}</span> <span>Lng: {_longitude}</span><br/>
               <span>prec: {_precision} / tijd: {_hour}:{_minutes}</span>
             </div>)
    }

    // return an ERROR like location indicator
    return(<div className={_classes} >
             <span>Lng: --.----</span><br/>
             <span>Lat: --.----</span><br/>
             <span>prec: -- / tijd: --:--</span>
           </div>)
  }

function  renderPositionAndZoom() {
      // { this.renderAutomaticLocationUpdate }
      // of <AutomaticLocationUpdate />

      return(<div className="location-and-zoom">
               { this.renderPosition()}

               { this.renderZoomAndLock() }
             </div>)
  }


// export default ShipMarker;
