import React, { Component } from 'react'
import PropTypes from "prop-types"
import fetch from 'cross-fetch';
import parse  from 'html-react-parser';
import {protDomPort, requestMode} from '../actions/LpowActions'

class LpowHelp extends Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.renderData = this.renderData.bind(this);
  }

  static propTypes = {
     title: PropTypes.string.isRequired,
     url: PropTypes.string.isRequired,
  }

  state = {
     show: false,
     fetching: false,
     data: null
  }


}

class LpowVersion extends Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.renderData = this.renderData.bind(this);
  }

  static propTypes = {
     // title: PropTypes.string.isRequired,
     version: PropTypes.string.isRequired,
     buildinfo: PropTypes.object.isRequired,
  }

  state = {
     show: false,
     fetching: false,
     data: null
  }

  toggle() {
    this.setState({show: !this.state.show});
    this.getData()
  }

  getData() {
    const requestOptions = {
                method: 'GET',
                mode: requestMode,
            headers: { 'Accept': 'application/html',
                       'Content-Type': 'application/html'//,
                       //'Authorization': 'Token '+token_key
                     },
    }

    if(!this.state.data){
       // console.log("Fetch "+this.props.url);
       this.setState({fetching: true});
       fetch(this.props.url, requestOptions)
          .then((res) => {
            if (res.status >= 400) {
              this.setState({fetching: false,
                             data: "Foutmelding van Server: "+res.status});
              throw new Error("Bad response from server");
            }
            return res.text();
          })
          .then((html_data) => {
            //console.log("server response: "+html_data);
            this.setState({fetching: false,
                           data:html_data})
          })
          .catch(err => {
            console.error("Server Error: "+err);
            this.setState({fetching: false,
                           data: "Fout Halen Gegevens Van Server: "+err})

          });

    }
  }


  renderData() {
    // console.log("state.data: "+this.state.data);
    // console.log("state.fetching: "+this.state.fetching);
    if(this.state.show) {
           if(this.state.fetching) {
           return(<div className="lpow_help_main">
                    Colofon Halen vanaf {this.props.url}
                   </div>
                  )
       }
       if(this.state.data) {
           return(<div className="lpow_help_main">
                    <div className="lpow-version-date">
                       <p>Versie: {this.props.version} </p>
                    </div>
                    { parse(this.state.data) }
                    <div className="lpow-version-date">
                       <p className="git-hash">GitHash: {this.props.buildinfo.gitHash} </p>
                       <p className="git-hash">GitHashShort: {this.props.buildinfo.gitHashShort} </p>
                    </div>

                   </div>
                  )
        }

    }
    return null
    //return(
    //   <div className="lpow_help_main">
    //    Help komt vanaf {this.props.url}
    //   </div>
    //)


  }

  render() {
      return(<div className="lpow_help_wrapper">
               <h1 className="lpow_help" onClick={this.toggle}>Colofon</h1>
               {this.renderData()}
             </div>
             )
  }


}


class LpowHelpTopic extends Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.getData = this.getData.bind(this);
    this.renderData = this.renderData.bind(this);
  }

  static propTypes = {
     title: PropTypes.string.isRequired,
     url: PropTypes.string.isRequired,
  }

  state = {
     show: false,
     fetching: false,
     data: null
  }

  componentDidMount(){
    this.getData();
  }

  toggle() {
    this.setState({show: !this.state.show});
    this.getData()
  }

  getData() {
    const requestOptions = {
                method: 'GET',
                mode: requestMode,
            headers: { 'Accept': 'application/html',
                       'Content-Type': 'application/html'//,
                       //'Authorization': 'Token '+token_key
                     },
    }

    if(!this.state.data){
       // console.log("Fetch "+this.props.url);
       this.setState({fetching: true});
       fetch(this.props.url, requestOptions)
          .then((res) => {
            if (res.status >= 400) {
              this.setState({fetching: false,
                             data: "Foutmelding van Server: "+res.status});
              throw new Error("Bad response from server");
            }
            return res.text();
          })
          .then((html_data) => {
            //console.log("server response: "+html_data);
            this.setState({fetching: false,
                           data:html_data})
          })
          .catch(err => {
            //console.error("Server Error: "+err);
            this.setState({fetching: false,
                           data: "Fout Halen Gegevens Van Server: "+err})

          });

    }
  }

  renderData() {
    // console.log("state.data: "+this.state.data);
    // console.log("state.fetching: "+this.state.fetching);
    if(this.state.show) {
       if(this.state.fetching) {
           return(<div className="lpow_help_main">
                    Help Halen vanaf {this.props.url}
                   </div>
                  )
       }
       if(this.state.data) {
           return(<div className="lpow_help_main">
                    { parse(this.state.data) }
                   </div>
                  )
        }
      return(
       <div className="lpow_help_main">
        Help komt vanaf {this.props.url}
       </div>
      )

    }
  }

  render() {
      return(<div className="lpow_help_wrapper">
               <h1 className="lpow_help" onClick={this.toggle}>{this.props.title} {this.state.fetching}</h1>
               {this.renderData()}
             </div>
             )
  }
}

module.exports = {
    LpowVersion,
    LpowHelp,
    LpowHelpTopic
}
