import React from "react";
import ReactDom from 'react-dom'

import {isMobile} from 'react-device-detect';

//import {POSITION_ACCURACY_BY_HAND} from '../actions/PositionActions'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    console.log(error);
    console.info(info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return ReactDom.render(<h1>Something went wrong.</h1>);
    }
    return this.props.children;
  }
}

class ErrorBoundaryX extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

function createIcon(iconUrl) {
     // console.log("createIcon.....");
     return L.icon({
             iconRetinaUrl: iconUrl,
             iconUrl: iconUrl,
             shadowUrl: '',
             iconSize: [20, 20],});
}

function createShipIcon(iconUrl) { // ,iconSize {
     // console.log("createShipIcon.....");
     return L.icon({
             iconRetinaUrl: iconUrl,
             iconUrl: iconUrl,
             shadowUrl: '',
             iconSize: [30, 30],});
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1);
  var a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  var d = R * c; // Distance in km
  return d;
}

const p = 0.017453292519943295;    // Math.PI / 180
const R = 6371; // Radius of the earth in km
                // km (change this constant to get miles)

function distance_km_m(lat1,lon1,lat2,lon2) {
	let dLat = (lat2-lat1) * Math.PI / 180;
	let dLon = (lon2-lon1) * Math.PI / 180;
	let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
		Math.cos(lat1 * Math.PI / 180 ) * Math.cos(lat2 * Math.PI / 180 ) *
		Math.sin(dLon/2) * Math.sin(dLon/2);
	let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
	let d = R * c;
	if (d>1) return Math.round(d)+"km";
	else if (d<=1) return Math.round(d*1000)+"m";
	return d;
}

function distance_km(lat1,lon1,lat2,lon2) {
  let dLat = deg2rad(lat2-lat1);  // deg2rad below
  let dLon = deg2rad(lon2-lon1);
  let a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  let d = R * c; // Distance in km
  return d;
}

function distance(lat1, lon1, lat2, lon2) {
  let c = Math.cos;
  let a = 0.5 - c((lat2 - lat1) * p)/2 +
          c(lat1 * p) * c(lat2 * p) *
          (1 - c((lon2 - lon1) * p))/2;
  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

function inRange(position, range, lng, lat) {
   var maxLeft = position.coords.latitude - range;
   var maxRight = position.coords.latitude + range;
   var maxTop = position.coords.longitude - (2*range);
   var maxBottom = position.coords.longitude + (2*range);
   // console.info("inRange("+position+", "+range+", "+lng+", "+lat+")");
   // console.info("maxLeft: "+maxLeft+"     MaxRight: "+ maxRight);
   if((maxLeft<lat && maxRight>lat) &&
      (maxTop<lng && maxBottom>lng)) {
       return true
   }
   return false
}


function toggleElement(array, element) {
    if(!element){
       return array;
    }
    //console.log("toggleElement element: ", element)
    const index = array.findIndex(function(index_element){
        return index_element.id == element.id
    });
    console.log("toggleElement index: ", index)
    if (index != -1) {
        array.splice(index, 1);
    } else {
       array.push(element)
    }
    return array;
}


function empty(data)
{
  if(typeof(data) == 'number' || typeof(data) == 'boolean')
  {
    return false;
  }
  if(typeof(data) == 'undefined' || data === null)
  {
    return true;
  }
  if(typeof(data.length) != 'undefined')
  {
    return data.length == 0;
  }
  var count = 0;
  for(var i in data)
  {
    if(data.hasOwnProperty(i))
    {
      count ++;
    }
  }
  return count == 0;
}

function getCookie(name) {
    if (!document.cookie) {
      return null;
    }
    const token = document.cookie.split(';')
      .map(c => c.trim())
      .filter(c => c.startsWith(name + '='));

    if (token.length === 0) {
      return null;
    }
    return decodeURIComponent(token[0].split('=')[1]);
}

function getLocalStorageLigpleklist(name) {
    let ligpleklist = localStorage.getItem(name)
    if(ligpleklist) {
        let parsed_ligpleklist = JSON.parse(ligpleklist)
        return(parsed_ligpleklist);
    }
    return []
}


function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

// RxEmail = new RegExp( '/(?:(?:"[\w-\s]+")|(?:[\w-]+(?:\.[\w-]+)*)|(?:"[\w-\s]+")(?:[\w-]+(?:\.[\w-]+)*))(?:@(?:(?:[\w-]+\.)*\w[\w-]{0,66})\.(?:[a-z]{2,6}(?:?:\.[a-z]{2})?))|(?:@\[?(?:(?:25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?)/gi' );

const RxEmail = new RegExp( / \w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+/gi );

function enhance_email( htmlString ) {
    //console.log("enhance_email: " + htmlString);
    let email_list = htmlString.match(RxEmail);
    //console.log("email_list: "+email_list);
    if (email_list && email_list.length>0) {
       let enhanced_htmlString = htmlString;
       for (var index in email_list) {
          let email = email_list[index].trim();
          var email_as_a = "<a href='mailto:"+email+"'>"+email+"</a>";
          enhanced_htmlString = enhanced_htmlString.replace(email, email_as_a);
          //console.log("enhanced_htmlString: " + enhanced_htmlString);
       }
       return enhanced_htmlString;
    }
    return htmlString
}

// const RxPhone = new RegExp( / \d{10}/gi ); // e.g.: ' 0987654321'
const RxPhone = new RegExp( /[0-9 -]{10,13}/gi ); // e.g.: '0987654321' of '022-35 468 78'

function enhance_phone( htmlString ) {
    //console.log("enhance_phone: " + htmlString);
    let phone_list = htmlString.match(RxPhone);
    //console.log("phone_list: "+phone_list);
    if (phone_list && phone_list.length>0) {
       let enhanced_htmlString = htmlString;
       for (var index in phone_list) {
          let phone = phone_list[index].trim()
          let tel_phone = phone.replace(/[^\d]/g, '');
          let phone_as_a = "<a href='tel:"+tel_phone+"'>"+phone+"</a>";
          enhanced_htmlString = enhanced_htmlString.replace(phone, phone_as_a);
          //console.log("enhanced_htmlString: " + enhanced_htmlString);
       }
       return enhanced_htmlString;
    }
    return htmlString
}


function enhanceHtml(htmlString) {
      //console.log("enhanceHtml: "+htmlString)
      if (htmlString !==  null ) {
          let extended_desc = enhance_email( htmlString );
          //console.log("isMobile: "+isMobile);
          if( isMobile ) {
              extended_desc = enhance_phone(extended_desc);
          }
          return extended_desc
      }
      return ""
}


function precisionOnClass(precision) {
    let _precision = precision.toFixed(0);
    try {
        if(_precision == -1 ) { // POSITION_ACCURACY_BY_HAND) {
            return "precision_manual"
        }
        if(1<_precision & _precision <= 100) {
            return "precision_high"
        }
        if(100 < _precision & _precision  <=500) {
            return "precision_medium"
        }
    } catch(e) {
        console.log("Geen Precisie Gevonden");
    }
    return "precision_low"
}


function get_liglekken_near_position(ligplek_list, position) {
    let new_lpow_markers_near_location = [];
    try{
        new_lpow_markers_near_location = ligplek_list.filter((ligplek) => {
                                                       ligplek.distance = distance(position.coords.latitude,
                                                                                   position.coords.longitude,
                                                                                   ligplek.lat, ligplek.lng);
                                                       ligplek.distance_time = Date.now();
                                                       return inRange(position, 0.2, ligplek.lng, ligplek.lat)
                                                    });
    } catch (e) {
        console.log("get_liglekken_near_position Error: "+e);
        new_lpow_markers_near_location = []
    }
    return new_lpow_markers_near_location
}


/*
 get item data from localstorage and convert to json format.
 return the json data.
 if any problem occurs: remove DateLastUpdate and {item_name}DateLastUpdate and return an empty set
*/
function getJsonFromLocalStorage(item_name) {
    let item_data_json = [];
    let item_data = localStorage.getItem(item_name);
    try {
     item_data_json = JSON.parse(item_data);
     if( !item_data_json ) {
        item_data_json = [];
        localStorage.removeItem(item_name+'DateLastUpdate');
        localStorage.removeItem('lpowDateLastUpdate');
     }
    } catch (e) {
        console.error("localstorage item_name ERROR: "+e);
        item_data_json = []; // there is Error, return empty list
        // remove all item_name data from localstorage
        localStorage.removeItem(item_name);
        localStorage.removeItem(item_name+'DateLastUpdate'); // wordt hieronder ook gedaan, als len ==0
        localStorage.removeItem('lpowDateLastUpdate');  // dus deze en regel hierboven overbodig?
    }
    if (item_data_json.length==0) {
        localStorage.removeItem(item_name+'DateLastUpdate');
        localStorage.removeItem('lpowDateLastUpdate');
    }
    return item_data_json
}

// console.log("Moduel Exports: "+getCookie);

module.exports = {
    ErrorBoundary,
    createIcon,
    createShipIcon,
    inRange,
    empty,
    toggleElement,
    getCookie,
    getLocalStorageLigpleklist,
    distance,
    addZero,
    enhanceHtml,
    precisionOnClass,
    get_liglekken_near_position,
    getJsonFromLocalStorage
}
