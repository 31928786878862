import * as modalLigplekActions from '../actions/ModalLigplekActions';


const initialState = {
   id: 0,
   // ligplek: null,
   position: null,
   displayModal: false,
   displayLigplekUpdate: false,
   displayLigplekAdopt: false,
   displayLigplekDetails: false,
   displayLigplekCreate: false
}

const LigplekModalReducer= (state = initialState, action) => {

  // console.log("MapReducer: "+action.type);
  switch (action.type) {

    case modalLigplekActions.DISPLAY_LIGPLEK:
      // console.log("REDUCE DISPLAY_LIGPLEK: "+action.id);
      return Object.assign({},
                           state,
                           {id: action.id,
                            // ligplek: selected_ligplek,
                            position: state.position, // waarvoor is dit?
                            displayModal: true,
                            displayLigplekUpdate: false,
                            displayLigplekAdopt: false,
                            displayLigplekDetails: true,
                            displayLigplekCreate: false }
                            )

    case modalLigplekActions.HIDE_LIGPLEK:
      return Object.assign({},
                           state,
                           {id: 0,
                            displayModal: false,
                            displayLigplekAdopt: false,
                            displayLigplekUpdate: false,
                            displayLigplekDetails: false,
                            displayLigplekCreate: false }
                            )

    case modalLigplekActions.CREATE_LIGPLEK:
      return Object.assign({},
                           state,
                           {id: null,
                            latlng: action.latlng,
                            displayModal: true,
                            displayLigplekUpdate: false,
                            displayLigplekAdopt: false,
                            displayLigplekDetails: false,
                            displayLigplekCreate: true }
                            )



    case modalLigplekActions.UPDATE_LIGPLEK:
      return Object.assign({},
                           state,
                           {id: action.id,  // hiermee kan ligplekdata  opgehaald worden van de server
                            displayModal: true,
                            displayLigplekUpdate: true,
                            displayLigplekAdopt: false,
                            displayLigplekDetails: false,
                            displayLigplekCreate: false }
                            )

    case modalLigplekActions.SHOW_ADOPT_LIGPLEK:
      return Object.assign({},
                           state,
                           {id: action.id,  // hiermee kan ligplekdata  opgehaald worden van de server
                            displayModal: true,
                            displayLigplekUpdate: false,
                            displayLigplekAdopt: true,
                            displayLigplekDetails: false,
                            displayLigplekCreate: false }
                            )


    default:
      return state
    }

}

export default LigplekModalReducer
