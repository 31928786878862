import * as adoptedLigplekActions from '../actions/AdoptedLigplekActions';


// function adoptedLigplekReducer(state=[], action) {
const initialState = {
	// val_five: 1
}

const AdoptedLigplekReducer= (state = initialState, action) => {

  // console.log("adoptedLigplekReducer: "+action.type);
  switch (action.type) {

    case adoptedLigplekActions.VIEW_ADOPTED_LIGPLEKKEN:
      // localStorage.setItem('lpowProfile', JSON.stringify(action.profile));
      return Object.assign({}, state,
                                {updating: false})

    case adoptedLigplekActions.SHOW_ADOPT_LIGPLEK:
      return Object.assign({}, state, {
          ligplekModal: {id: action.id,  // hiermee kan ligplekdata  opgehaald worden van de server
                         displayModal: true,
                         displayLigplekUpdate: false,
                         displayLigplekAdopt: true,
                         displayLigplekDetails: false,
                         displayLigplekCreate: false }
        })

    case adoptedLigplekActions.REQUEST_ADOPTED_LIGPLEKKEN:
      return Object.assign({},
                           state,
                           {adopted_ligplekken:{ isFetching: true,
                                                   didInvalidate: false,
                                                   ligplekken: [],
                                                   requestedAt: action.requestedAt}
      })

    case adoptedLigplekActions.CLEAR_ADOPTED_LIGPLEKKEN:
      localStorage.setItem('lpowAdoptedLigplekken', JSON.stringify([]));
      return Object.assign({},
                           state,
                           {adopted_ligplekken: { isFetching: false,
                                                   didInvalidate: false,
                                                   ligplekken: [],
                                                   requestedAt: action.requestedAt}
      })

    case adoptedLigplekActions.RECEIVE_ADOPTED_LIGPLEKKEN:
      localStorage.setItem('lpowAdoptedLigplekken', JSON.stringify(action.ligplekken));
      return Object.assign({},
                           state,
                           {adopted_ligplekken:{ isFetching: false,
                                                 ligplekken: action.ligplekken,
                                                 receivedAt: action.receivedAt}
                           })

    case adoptedLigplekActions.REQUEST_ADOPT_LIGPLEK:
      // console.log("Als ingelogd Adopteren Ligplek: "+action.id);
      return Object.assign({},
                           state,
                           {ligplekModal: {id: action.id,
                                         // token_key,
                                         requestedAdoptAt: action.requestedAdoptAt,
                                         displayModal: true,
                                         displayLigplekUpdate: false,
                                         displayLigplekAdopt: true,
                                         displayLigplekDetails: false,
                                         displayLigplekCreate: false }
                           })

    case adoptedLigplekActions.ADOPT_LIGPLEK_SUCCES:
      return Object.assign({}, state, {
          ligplekModal: {id: action.id,
                         token_key: null, // waarom is dit null?
                         displayModal: true,
                         displayLigplekUpdate: false,
                         displayLigplekAdopt: true,
                         displayLigplekDetails: false,
                         displayLigplekCreate: false }
      })

    case adoptedLigplekActions.ADOPT_LIGPLEK_FAIL:
      return Object.assign({}, state, {
          ligplekModal: {id: null,
                         error: action.error,
                         token_key: null, // waarom is dit null?
                         displayModal: true,
                         displayLigplekUpdate: false,
                         displayLigplekAdopt: true,
                         displayLigplekDetails: false,
                         displayLigplekCreate: false }
      })


    default:
      return state
    }

}

//module.exports = {
//  adoptedLigplekReducer,
//}

export default AdoptedLigplekReducer
