export const DISPLAY_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';

export function showPopup(component) {
  return { type: DISPLAY_POPUP,
           isOpen: true,
           popupContent: component,
         }
}

export function hidePopup() {
  return { type: HIDE_POPUP,
           isOpen: false,
         }
}

