import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Navigate } from 'react-router-dom';
import { Label, Input, Button, ButtonGroup } from 'reactstrap';
import { doLogout, receiveLogoutSucces } from "../actions/AuthActions";
import { protDomPort,  } from "../actions/LpowActions";
import { doUpdateProfile, receiveProfileUpdateSucces, clearProfile,
         receiveProfileUpdateFail, editProfile, viewProfile} from "../actions/ProfileActions";
import { setZoomLevel } from '../actions/MapActions'
import { viewAdoptedLigplekken, } from "../actions/AdoptedLigplekActions";
import { ProfileUpdateForm } from "../components/LpowForms";
import { updateCurrentPosition,  startWatchPosition, stopWatchPosition } from '../actions/PositionActions'

class ProfileView extends React.Component {

  constructor(props) {
    super(props);
    this.changeProfile = this.changeProfile.bind(this);
    this.doLogout = this.doLogout.bind(this);

    this.startAutomPositionUpdate = this.startAutomPositionUpdate.bind(this); // Naar "Instellingen"?
    this.stopAutomPositionUpdate = this.stopAutomPositionUpdate.bind(this);
    this.renderGpsStartStopButton = this.renderGpsStartStopButton.bind(this);
  }

  static propTypes = {
     currentProfile: PropTypes.object.isRequired,
     position: PropTypes.object.isRequired
  }

  changeProfile() {
      this.props.dispatch(editProfile());
  }

  doLogout() {
      this.props.dispatch(receiveLogoutSucces());
      this.props.dispatch(clearProfile());
  }

  // Start Positie Update nu even vanuit Profile
  startAutomPositionUpdate() {
      // eerst een keer doen voordat WatchPosition actief wordt
      this.props.dispatch( updateCurrentPosition() );
      this.props.dispatch( setZoomLevel(10) );
      this.props.dispatch( startWatchPosition() );
  }
  stopAutomPositionUpdate() {
      this.props.dispatch( stopWatchPosition() );
      // this.props.dispatch( updateCurrentPosition() );
  }
  // Stoppen ook. Uitzoeken waar het beter naar toe kan
  renderGpsStartStopButton() {
     //console.log("this.props.position.watchId: "+this.props.position.watchId);
     if(this.props.position.watchId==-1) {
         return(<Button onClick={this.startAutomPositionUpdate}>Start GPS-Positie-Update</Button>)
     }
     return ( <Button onClick={this.stopAutomPositionUpdate}>Stop GPS-Positie-Update</Button>)
  }
  // En een Knop voor het gemak

  render() {
     let _share_profile = (this.props.currentProfile.share_profile ? "Ja" : "Nee");
     let _share_location = (this.props.currentProfile.share_location ? "Ja" : "Nee");
     return (
            <div className="small-not-on-mobile">
              <div>
                   <fieldset>
                      <legend>Schipper</legend>
                      <div className="lpow-label-value">
                        <Label className="lpow-label">Voornaam</Label>
                        <span className="lpow-value">{this.props.currentProfile.user.first_name}</span>
                      </div>

                      <div className="lpow-label-value">
                        <Label className="lpow-label">Achternaam</Label>
                        <span className="lpow-value">{this.props.currentProfile.user.last_name}</span>
                      </div>

                  </fieldset>
                  <fieldset>
                      <legend>Schip</legend>
                      <div className="lpow-label-value">
                        <Label className="lpow-label">Naam</Label>
                        <span className="lpow-value">{this.props.currentProfile.ship_name}</span>
                      </div>

                      <div className="lpow-label-value">
                        <Label className="lpow-label">Afbeelding</Label>
                        <span className="lpow-value lpow-img-select">
                           <img height="150"  src={this.props.currentProfile.ship_img_thumbnail_url} />
                        </span>
                      </div>

                      <div className="lpow-label-value">
                        <Label className="lpow-label">Kaart marker</Label>
                        <span className="lpow-value lpow-icon-select">
                           <img height="50"  src={this.props.currentProfile.ship_icon} />
                        </span>
                        {this.renderGpsStartStopButton()}
                      </div>

                      <div className="lpow-label-value">
                        <Label className="lpow-label">Thuishaven</Label>
                        <span className="lpow-value">{this.props.currentProfile.ship_berth}</span>
                      </div>

                      <div className="lpow-label-value">
                        <Label className="lpow-label">Huidige locatie</Label>
                        <span className="lpow-value">Niet Actief{this.props.currentProfile.ship_location}</span>
                      </div>

                      <div className="lpow-label-value">
                        <Label className="lpow-label">Laatste locatie opname</Label>
                        <span className="lpow-value">Niet Actief{this.props.currentProfile.ship_location_lastupdate}</span>
                      </div>

                  </fieldset>

                  <fieldset>
                      <legend>Delen</legend>
                      <div className="lpow-label-value">
                        <Label className="lpow-label">Profiel</Label>
                        <span className="lpow-value">{_share_profile}</span>
                      </div>

                      <div className="lpow-label-value">
                        <Label className="lpow-label">Locatie</Label>
                        <span className="lpow-value">{_share_location}</span>
                      </div>
                  </fieldset>

              </div>
              <ButtonGroup>
                  <Button color="primary"  onClick={this.doLogout} >Uitloggen</Button>
                  <Button color="primary"  onClick={this.changeProfile} >Aanpassen profiel</Button>
               </ButtonGroup>
           </div>
     )
  }

}


class Profile extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.setViewProfile = this.setViewProfile.bind(this);
    this.setViewAdoptedLigplekken = this.setViewAdoptedLigplekken.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.doLogout = this.doLogout.bind(this);

  }

  static propTypes = {
     initialValues: PropTypes.object.isRequired,
     token: PropTypes.string.isRequired,
     isFetching: PropTypes.bool.isRequired,
     updatingProfile: PropTypes.bool.isRequired,
  }

  state ={
  }

  handleChange(e) {
      this.setState({[e.target.name]: e.target.value,});
  }

  fileChangedHandler = (event) => {
    this.setState({[event.target.name]: event.target.files[0],})
  }

  updateProfile() {
     this.props.dispatch(doUpdateProfile(this.state));
     this.props.dispatch(receiveProfileUpdateSucces(data));
  }

  // handleSubmit = e => {
  handleSubmit(e) {
    e.preventDefault();
    //console.log("Profile handleSubmit "+e);
    let data = new FormData();
    const { first_name, last_name, ship_name, ship_img } = this.state;
    data.append('user.first_name', first_name);
    data.append('user.last_name', last_name);
    data.append('ship_name', ship_name);
    data.append('ship_img', ship_img, ship_img.name);
    //console.log("data: "+data);
    const conf = {
      method: "PUT",
      headers: { 'Authorization': 'Token '+this.props.token
         },
      body: data, // JSON.stringify(data)

    };
    const endpoint = "/lpow/api/user/profile/"
    const new_endpoint = protDomPort+endpoint;
    fetch(new_endpoint, conf)
         .then( (response) => { return response.json(); }
         )
         .then(response => { this.props.dispatch(receiveProfileUpdateSucces(response));
                           }
         );
  };


  doLogout() {
      this.props.dispatch(receiveLogoutSucces());
      this.props.dispatch(clearAuthAndProfile())
  }

  submit = values => {
    // print the form values to the console
    console.log(values)
  }

  setViewProfile() {
     this.props.dispatch(viewProfile())
  }

  setViewAdoptedLigplekken() {
     this.props.dispatch(viewAdoptedLigplekken())
  }

  renderViewOrUpdate() {
     // console.log("this.props.updatingProfile: "+this.props.updatingProfile);
     if(this.props.updatingProfile) {
        return(
                 <ProfileUpdateForm initialValues={this.props.initialValues}
                                    token={this.props.token}
                                    dispatch={this.props.dispatch}
                                    onSubmit={this.submit} />
             )
     }

     return(
               <ProfileView dispatch={this.props.dispatch}
                            currentProfile={this.props.initialValues}
                            position={this.props.position} />
             )
  }

  render() {
    if (!this.props.token) {
        // geen token, door naar Auth
        return( <div>
                  <Navigate to="/auth/" push />
               </div>)
    }
    if (this.props.isFetching) {
        return (
            <div>
                 <div className="small-not-on-mobile">
                    Profile is Loading
                 </div>
            </div>
        )
    }

    return (
        <div className="small-not-on-mobile">
             <div className="small-not-on-mobile">
                  <ButtonGroup>
                     <Button color="primary" active
                             onClick={this.setViewProfile}>Profiel</Button>
                  </ButtonGroup>
             </div>
             <br/>
             <div className="small-not-on-mobile">

                 {this.renderViewOrUpdate()}

             </div>
        </div>
     )}
}

export default Profile
