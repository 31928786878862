import React from 'react';

import PropTypes from "prop-types"
import ReactDOM from "react-dom"
import { bindActionCreators } from 'redux'
import { HashRouter, } from 'react-router-dom'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactMessagesContainer from "../containers/ReactMessagesContainer";
// import PopupModalContainer from "../containers/PopupModalContainer"
import LigplekModalContainer from "../containers/LigplekModalContainer"
import LpowNavBarContainer from "../containers/LpowNavBarContainer"
import AppModules from "../containers/AppModules"

class TestApp extends React.Component {
  constructor(props) {
    super(props);
    console.log("App constructor");
  }
  state = {
      zoom:5 ,
  };
  render() {
      console.log("App render");
      return (
         <div>
            Test
         </div>
      )
  }
}

class App extends React.Component {

  constructor(props) {
    super(props);
  }

  state = {
      zoom:5 ,
  };

  render() {
    return (
      <div>
        <HashRouter>
        <div>
          <LpowNavBarContainer />
          <AppModules />
        </div>
      </HashRouter>

      <LigplekModalContainer />
      <ReactMessagesContainer />
      </div>
    )
  }

};

//       <LigplekModalContainer />

//      <ReactMessagesContainer />

export default App
