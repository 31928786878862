import 'babel-polyfill' // https://redux.js.org/advanced/async-actions , 'Note on fetch'
import React from 'react'
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware } from 'redux'


/* misc stuff */
import registerServiceWorker from './registerServiceWorker';
import { getJsonFromLocalStorage, } from './components/LpowUtils'

/* Actions */
import { getVersionDate } from './actions/AboutActions'
import { fetchSelectedCategorieen, fetchSelectedVaarwegen,
         fetchFavouriteLigplekken, fetchSelectedLigplekken,
         fetchCreatedLigplekken } from './actions/LpowActions'
import { setZoomLevel, hideAllOverlays, showOverlay } from './actions/MapActions'
import { fetchAdoptedLigplekken, } from './actions/AdoptedLigplekActions'
import { fetchSettings } from './actions/SettingsActions'
import { updateCurrentPosition, suspendPositionWatch, restartSuspendedPositionWatch, startWatchPosition } from './actions/PositionActions'
import { fetchAuthentication, } from './actions/AuthActions'
import { fetchProfile, } from  './actions/ProfileActions'
import {fetchData, protDomPort,
         requestKarakterestieken, receiveKarakterestieken,
         requestVaarwegen, receiveVaarwegen, fetchLigplekken,
         requestCategorieen, receiveCategorieen,
         // requestSelectedLigplekken, receiveSelectedLigplekken,
         requestLigplekken, receiveLigplekken } from './actions/LpowActions'


import root_reducer from './reducers/RootReducer'
import AppContainer from './containers/AppContainer'


import "leaflet/dist/leaflet.css"

import 'react-image-gallery/styles/css/image-gallery.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import '../css/App.css';
import '../css/JimboGo.css';
import '../css/LpowHelp.css';

// import logoVlak from '../img/logo-vlak.png'

/*=== from https://github.com/PaulLeCam/react-leaflet/issues/255 ===*/

// stupid hack so that leaflet's images work after going through webpack
import L from 'leaflet';
import marker from 'leaflet/dist/images/marker-icon.png';
import marker2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: marker2x,
    iconUrl: marker,
    shadowUrl: markerShadow
});

/*=== end webpack garble hash fix ===*/

// Force zIndex of Leaflet
// from: https://gist.github.com/up209d/4c68f2391f2302e510eb81aa8bcd4514
(function(global){
  var MarkerMixin = {
    _updateZIndex: function (offset) {
      this._icon.style.zIndex = this.options.forceZIndex ? (this.options.forceZIndex + (this.options.zIndexOffset || 0)) : (this._zIndex + offset);
    },
    setForceZIndex: function(forceZIndex) {
      this.options.forceZIndex = forceZIndex ? forceZIndex : null;
    }
  };
  if (global) global.include(MarkerMixin);
})(L.Marker);


const initalAdoptedLigplek = {
   ligplekken: [],
   isFetching: false,
}

// optional configuration for AlertProvider
//const alert_provider_options = {
  // you can also just use 'bottom center'
//  position: positions.TOP_CENTER,
//  timeout: 5000,
//  offset: '30px',
  // you can also just use 'scale'
//  transition: transitions.SCALE
//}

const appState  = {}

var store = null;
const window_location = ""+window.location;
console.log("window.location: "+window_location);
if(window_location.startsWith("http://localhost:800")) {
    console.debug("LOAD DEBUGGING STUFF ");
    const loggerMiddleware = createLogger();
    // console.debug("DEBUGGING STUFF >"+(typeof loggerMiddleware)+"<  >"+(typeof thunk)+"<");
    store = createStore(
               root_reducer,
               appState,
               applyMiddleware(
                  thunk, // thunkMiddleware, // lets us dispatch() functions
                  loggerMiddleware, // neat middleware that logs actions
               )
    )
} else {
    store = createStore(
               root_reducer,
               appState,
               applyMiddleware(
                  thunk, // lets us dispatch() functions
               )
    )
}


store
  .dispatch(fetchAuthentication())

store
  .dispatch(fetchProfile())

store
  .dispatch(fetchAdoptedLigplekken())

store
  .dispatch(fetchSettings())

store
  .dispatch(fetchData("lpowKarakterestieken",
                      protDomPort+"/lpow/api/karakterestiek-list/",
                      requestKarakterestieken, receiveKarakterestieken))

store.dispatch(fetchLigplekken())

store
  .dispatch(fetchFavouriteLigplekken())

store
  .dispatch(fetchSelectedLigplekken())


store
  .dispatch(fetchCreatedLigplekken())

store
  .dispatch(fetchData("lpowCategorien",
                      protDomPort+"/lpow/api/categorie-list/",
                      requestCategorieen, receiveCategorieen));

store
  .dispatch(fetchData("lpowVaarwegen",
                      protDomPort+"/lpow/api/vaarweg-list/",
                      requestVaarwegen, receiveVaarwegen));

store
  .dispatch(fetchSelectedCategorieen());

store
  .dispatch(fetchSelectedVaarwegen());

// bij opstarten alle overlays uit, en 'in-de-buurt weer aan.
store.dispatch( hideAllOverlays() );
store.dispatch( showOverlay("overlay_indebuurt") );
// Pas de Positie (1 keer) aan en zoom naar 10
store.dispatch( updateCurrentPosition() );
store.dispatch( setZoomLevel(10) );


function onPause() {
   let state = store.getState();
   console.info("onPause  Stop PositionWatching watchId: "+state.position.watchId);
   if(state.position.watchId > 0) {
     console.info("Suspenden, voor weer herstarten bij Resume.  watchId: "+state.position.watchId)
     store.dispatch(suspendPositionWatch()); // if it's running
     navigator.geolocation.clearWatch(state.position.watchId); // VIA DISPATCHER STOPPEN LUK TNIET. Dan hier
   }

   // we gan nu echt stoppen, is het werk al gedaan?
   state = store.getState();
   console.info("Suspend, watchId: "+state.position.watchId)
   // console.info("Suspend, watchSuspended: "+state.position.watchSuspended)
   // alert("On Pause Done");
}

function onResume() {
   let state = store.getState();
   console.info("onResume. ReStart? Position.watchId: "+state.position.watchId);
   if( state.position.watchId == 0) {
      // if it was running
      console.log("onResume: Start Watch Position");
      store.dispatch(startWatchPosition());
   }
}

const startApp = () => {
  window.open = window.cordova.InAppBrowser.open;
  store.dispatch(getVersionDate()); // version and date from config.xml

  document.addEventListener("pause", onPause, false);
  document.addEventListener("resume", onResume, false);

  const container = document.getElementById('app');
  const root = ReactDOMClient.createRoot(container);
  // console.log("To Start Rendering Root in root: "+root);
  root.render( <Provider store={store} >
                 <AppContainer />
               </Provider> );

  if ('serviceWorker' in navigator) {
    console.log('serviceWorker  Supported!');
    // registerServiceWorker();
    // store.dispatch(startWatchLocation())
  }

};

if(window.cordova) {
  document.addEventListener('deviceready', startApp, false);
} else {
  console.log("Geen Cordova")
  startApp();
}
