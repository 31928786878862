import React from 'react';
import PropTypes from "prop-types";
import parse from 'html-react-parser';
import { Nav, NavItem, NavLink, TabContent, TabPane, Button, ButtonGroup } from 'reactstrap';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {protDomPort  } from "../actions/LpowActions";
import {updateLigplek, doAdoptLigplek } from "../actions/ModalLigplekActions";
import { LigplekCreateForm, LigplekUpdateForm, LigplekImgForm,
         LigplekKarakterestiekForm, LigplekWebsiteForm } from "./LpowForms";
import { enhanceHtml } from "./LpowUtils";
// import { Gallery, Item } from 'react-photoswipe-gallery'
import ImageGallery from "react-image-gallery";


class LigplekCreate extends React.Component {

  static propTypes = {
    latlng: PropTypes.array.isRequired,
    doLigplekCreate: PropTypes.func.isRequired,
  }

  render() {
    return (
        <div className="panel panel-primary ligplek-create" id="result_panel">
          <div className="panel-heading">
             <h3 className="panel-title">Aanmelden Ligplek</h3></div>
          <div id="ligplek-create-panel-body" className="modal-panel-body">
             <LigplekCreateForm latlng={this.props.latlng}
                                endpoint="/lpow/api/ligplek-create/"
                                doLigplekCreate={this.props.doLigplekCreate}/>
          </div>
        </div>
    );
  }

}

class LigplekAdopt extends React.Component {

  constructor(props) {
    super(props);
    this.renderMessage = this.renderMessage.bind(this);
    this.doAdopt = this.doAdopt.bind(this);
  }

  static propTypes = {
    ligplekId: PropTypes.number.isRequired,
    // ligplek: PropTypes.object,
    doLigplekAdopt: PropTypes.func.isRequired,
    username_email: PropTypes.string.isRequired
  }

  state = {
        dropdownOpen: false,
        dropdownChoosen: "....",
        activeTab: '0',
        ligplek: [],
        adoptButtonDisabled: false
  }

  doAdopt() {
    // console.log("LigplekAdopt componentDidMount");
    // this.props.dispatch(doAdoptLigplek()); geen dispatch hier
    this.props.doLigplekAdopt();
    this.setState({'adoptButtonDisabled': true});
  }


  renderControl() {
    return(  <div className="control">
              <Button color="primary" disabled={this.state.adoptButtonDisabled}
                      onClick={this.doAdopt}>Bevestig Adopteren Ligplek</Button>
             </div>)
  }

  renderMessage() {
    if (!this.state.adoptButtonDisabled) {
      return( <span>Na klikken van bevestigen wordt een link verstuurd naar uw emailadres({this.props.username_email}),
                   daarmee kan de ligpek beheerd worden.
             </span>
)
    } else {
      return(<span>Het Adoptie verzoek is verzonden.
             </span>
      )
    }
  }

  render() {
    return (
        <div className="panel panel-primary ligplek-update" id="result_panel">
          <div className="panel-heading">
             <h3 className="panel-title">{this.state.ligplek.name}</h3></div>
          <div id="ligplek-update-panel-body" className="modal-panel-body">
             {this.renderMessage() }
             <div className="control">
              <Button color="primary" disabled={this.state.adoptButtonDisabled}
                      onClick={this.doAdopt}>Bevestig Adopteren Ligplek</Button>
             </div>
          </div>
        </div>
        )
  }
}


class LigplekUpdate extends React.Component {

  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.renderLocalMsg = this.renderLocalMsg.bind(this)
  }

  static propTypes = {
    ligplekId: PropTypes.number.isRequired,
    ligplek: PropTypes.object,
  }

  state = {
        dropdownOpen: false,
        dropdownChoosen: "....",
        activeTab: '0',
        ligplek: [],
  }

  componentDidMount() {
    // console.log("LigplekUpdate componentDidMount");
    // fetch data for Ligplek to be updated
    let ApiUrl = protDomPort+"/lpow/api/ligplek/"+this.props.ligplekId+"/";
    fetch(ApiUrl)
      .then(res => res.json())
      .then(
        ligplek => this.setState({ loading: false, ligplek }),
        error => this.setState({ loading: false, error })
      )
  }

  toggle(event, tab) {
    let target = event.target
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        dropdownChoosen: target.innerText,
      });
    }
  }

  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  renderLocalMsg() {
      console.log("this.state.ligplek: "+this.state.ligplek);
      console.log("this.state.ligplek.local_msg: "+this.state.ligplek.local_msg);
      if(this.state.ligplek.local_msg) {
         return( <span>{this.state.ligplek.local_msg}</span> )
      }
  }

  render() {
    let ligplekCommentUrl = "/lpow/api/ligplek/"+this.props.ligplekId+"/comment/";  // protDomPort+
    return (
        <div className="panel panel-primary ligplek-update" id="result_panel">
          <div className="panel-heading">
             <h3 className="panel-title">{this.state.ligplek.name}</h3>
             { this.renderLocalMsg() }
             </div>

          <div id="ligplek-update-panel-body" className="modal-panel-body">
             <div className="control">
                 <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                    <DropdownToggle caret color="primary">
                      Toevoegen van {this.state.dropdownChoosen}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={(e) => { this.toggle(e, '1'); }}>Kerngegevens</DropdownItem>
                      <DropdownItem onClick={(e) => { this.toggle(e, '2'); }}>Karakterstieken</DropdownItem>
                      <DropdownItem onClick={(e) => { this.toggle(e, '3'); }}>Afbeeldingen</DropdownItem>
                      <DropdownItem onClick={(e) => { this.toggle(e, '4'); }}>Websites</DropdownItem>
                    </DropdownMenu>
                 </Dropdown>
             </div>

             <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="0">
                <div>
                  <p>
                    Selecteer informatiesoort dat toegevoegd moet worden.
                  </p>
                  <p>
                    Aangebrachte infomatie wordt door de beheerder bekeken en naar believen geactiveerd.
                  </p>
                </div>
              </TabPane>

              <TabPane tabId="1">
                <div>
                  <LigplekUpdateForm endpoint={ligplekCommentUrl}
                                     ligplekId={this.props.ligplekId}/>
                </div>
              </TabPane>

              <TabPane tabId="2">
                <div>
                  <LigplekKarakterestiekForm endpoint="/lpow/api/ligplekkarakterestiek-create/"
                                             ligplekId={this.props.ligplekId}/>
                </div>
              </TabPane>

              <TabPane tabId="3">
                <div>
                  <LigplekImgForm endpoint="/lpow/api/ligplekimg-create/"
                                  ligplekId={this.props.ligplekId} />

                </div>
              </TabPane>

              <TabPane tabId="4">
                <div>
                 <LigplekWebsiteForm endpoint="/lpow/api/ligpleksite-create/"
                                     ligplekId={this.props.ligplekId} />
                </div>
              </TabPane>

              <TabPane tabId="5">
                <div>
                  <p>
                    Aanmelden Succesvol
                  </p>
                </div>
              </TabPane>

             </TabContent>

             <div id="ligplek_update_result" className="ligplek-update-result">
               <span>Hier verschijnt het resultaat van de actie</span>
             </div>
          </div>
        </div>
    );
  }

}


class LigplekDetails extends React.Component {

  constructor(props) {
    super(props)
  }

  state = { loading: true,
            ligplek: [],
  }

  static propTypes = {
    ligplekId: PropTypes.number.isRequired,
    ligplek: PropTypes.object
  }

  componentDidMount() {
    if(this.props.ligplek != null) {
        this.setState({ loading: false,
                        ligplek: this.props.ligplek });
    } else {
        const ApiUrl = protDomPort+"/lpow/api/ligplek/"+this.props.ligplekId+"/";
        fetch(ApiUrl)
          .then(res => res.json())
          .then(
            ligplek => this.setState({ loading: false,
                                       ligplek }),
            error => this.setState({ loading: false,
                                     error })
          )
        }
  }

  renderDistance() {
      if(this.state.ligplek.distance){
         // 0.5 erbij zodat 't niet snel te weinig is
         let _roud_dist = Math.round((this.state.ligplek.distance+0.5))+"km";
         return (
            <li key="6"><label>Afstand:</label> {_roud_dist}</li>
            )
      }
  }

  renderDescriptionLong() {
      if (this.state.ligplek?.description_long) {
          let extended_desc = enhanceHtml( this.state.ligplek.description_long );
          return (<li key="lange-desc-4"><label>Omschrijving:</label> {parse(extended_desc)}</li>)
      }
      return ( <li key="lange-desc-4"><label>Omschrijving:</label> - </li> )
  }

  renderDiepgang() {
      return(<li key="diepgang-3"><label>Maximale Diepgang:</label> {this.state.ligplek.maximale_diepgang}cm</li>)
  }

  renderContact() {
      return(<li key="contact-0"><label>Contact:</label> <a href="tel:0651521379">Braas: 0651521379</a></li>)
  }

  render() {
    return (
        <div id="result_panel" className="panel panel-primary ligplek-view" >
          <div className="panel-heading">
             <h3 className="panel-title">{this.state.ligplek.name}</h3></div>

          <div className="modal-panel-body">
             <ul className="ligplek-info">
               <li id="id_ligplek-info-top"
                   key="categorie-1"><label>Categorie:</label> {this.state.ligplek.categorie_name}</li>
               <li key="vaarweg-2"><label>Vaarweg:</label> {this.state.ligplek.vaarwater}</li>
               {this.renderDiepgang()}
               {this.renderDescriptionLong()}
               <li key="last-change-5"><label>Laatste aanpassing:</label> {this.state.ligplek.lastUpdate}</li>
               {this.renderDistance()}

               <LigplekKarakterestieken  ligplekId={this.props.ligplekId} />

               <LigplekImages ligplekId={this.props.ligplekId} />

               <LigplekWebsites ligplekId={this.props.ligplekId} />

               <LigplekJimboGo ligplekId={this.props.ligplekId} />
             </ul>
          </div>
        </div>
    );
  }

}


class LigplekKarakterestieken extends React.Component {

  state = { loading: true,
            ligplek_karakterestieken: [],
           }

  static propTypes = {
    ligplekId: PropTypes.number.isRequired,
  }

  componentDidMount() {
    const ApiUrl = protDomPort+"/lpow/api/ligplek/"+this.props.ligplekId+"/karakterestieken/";
    fetch(ApiUrl)
      .then(res => res.json())
      .then(
        ligplek_karakterestieken => this.setState({ loading: false,
                                                    ligplek_karakterestieken }),
        error => this.setState({ loading: false,
                                 error })
      )
  }

  renderLoading() {
    return  <li key="data-fetching-0"><label>Karakterestieken </label> worden opgehaald.</li>;
  }

  renderError() {
    return <li key="data-error-0"><label>Karakterestieken </label>Een fout helaas...</li>;
  }

  renderNoData() {
     return  <li key="no-data-0"><label>Karakterestieken </label>Niet aanwezig.</li>;
  }

  renderData() {
     let karakterestieken = this.state.ligplek_karakterestieken.map((karakterestiek,index) => {
                                const li_key = this.props.ligplekId+-"k-"+karakterestiek.karakterestiek+"-"+index;
                                // kan ook email en telefoonr in zitten, enhanceHtml maakt dat klikbaar
                                let extended_desc = enhanceHtml( karakterestiek.description );
                                return (
                                      <li key={li_key}>
                                        <label>{karakterestiek.karakterestiek_name}:</label>
                                        { parse(extended_desc) }
                                      </li>
                                     )
                                })
     return karakterestieken
  }

  render () {
      if (this.state.loading) {
         return this.renderLoading()
      } else if (this.state.ligplek_karakterestieken.length==0) {
         return this.renderNoData()
      } else if (this.state.ligplek_karakterestieken.length>0) {
         return this.renderData()
      } else {
         return this.renderError()
      }
  }

}


class LigplekImages extends React.Component {

     constructor(props) {
        super(props);

        this.state = {
          photoIndex: 0,
          isOpen: false,
          loading: true,
          ligplek_images: [],
        };
      }

      // state = {
      //        }

      static propTypes = {
        ligplekId: PropTypes.number.isRequired,
      }

      componentDidMount() {
        const ApiUrl = protDomPort+"/lpow/api/ligplek/"+this.props.ligplekId+"/images/";
        fetch(ApiUrl)
          .then(res => res.json())
          .then(
            ligplek_images => this.setState({ loading: false, ligplek_images }),
            error => this.setState({ loading: false, error })
          )
      }

      renderLoading() {
        return <div>Afbeeldingen worden opgehaald.</div>;
      }

      renderError() {
        return <div>Een fout helaas... {this.state.error}..</div>;
      }

      renderNoData() {
        return(
               <li key="images-no-data"><label>Afbeeldingen: </label>Geen Afbeeldingen</li>
              )
      }

      renderDataOud() {
        let ligplek_images = this.state.ligplek_images.map(ligplek_image => {
                                    return (<div key={ligplek_image.id} className="img-container">
                                             <img className="img-small" src={ligplek_image.img} />
                                             <span className="img-caption">{ligplek_image.caption}</span>
                                            </div>
                                            )
                                    })
        return ( <li key="images-{ligplekId}"><label>Afbeeldingen:</label>
                   <div className="img-container-list">
                     {ligplek_images}
                   </div>
                 </li>
                )
      }

    renderData() {
        let images = this.state.ligplek_images.map(ligplek_image => {
                                return {'original': ligplek_image.img,
                                        'thumbnail': ligplek_image.thumbnail}
                                })

        return(<li key="images-{ligplekId}"><label>Afbeeldingen:</label>
                <ImageGallery items={images} />
               </li>
               )
    }

    render () {
      if (this.state.loading) {
         return this.renderLoading()
      } else if (this.state.ligplek_images.length==0) {
         return this.renderNoData()
      } else if (this.state.ligplek_images.length>0) {
         return this.renderData()
      } else {
         return this.renderError()
      }
    }

}


class LigplekWebsites extends React.Component {

  state = { loading: true,
            ligplek_websites: [],
           }

  static propTypes = {
    ligplekId: PropTypes.number.isRequired,
  }

  componentDidMount() {
    const ApiUrl = protDomPort+"/lpow/api/ligplek/"+this.props.ligplekId+"/websites/";
    fetch(ApiUrl)
      .then(res => res.json())
      .then(
        ligplek_websites => this.setState({ loading: false, ligplek_websites }),
        error => this.setState({ loading: false, error })
      )
  }

  renderLoading() {
    return <li><label>Websites: </label>data wordt opgehaald.</li>;
  }

  renderError() {
    return <div>Een fout helaas... {this.state.error}..</div>;
  }

  renderNoData() {
    return(
           <li key="websites-no-data"><label>Websites: </label>Geen Websites</li>
          )
  }

  renderData() {
    var ligplek_websites = this.state.ligplek_websites.map(ligplek_website => {
                                return <div className="website-container" key={ligplek_website.id}>
                                          <a href={ligplek_website.url}
                                             target="lpowLigplekSite"> {ligplek_website.caption} </a>
                                       </div>
                                })

    return ( <li  key="websites-{ligplekId}"><label>Websites:</label>
               {ligplek_websites}
             </li>
            )
  }

  render () {
      if (this.state.loading) {
         return this.renderLoading()
      } else if (this.state.ligplek_websites.length==0) {
         return this.renderNoData()
      } else if (this.state.ligplek_websites.length>0) {
         return this.renderData()
      } else {
         return this.renderError()
      }
  }

}


class LigplekJimboGo extends React.Component {

 constructor(props) {
    super(props);

    this.jimboClazz = "com.jimbogo.jimbogoapp";
    this.jimboPlaystoreUrl = "https://play.google.com/store/apps/details?id=com.jimbogo.jimbogoapp&hl=nl";
    this.jimboAppstoreUrl = "https://apps.apple.com/nl/app/jimbogo-storytrails-and-more/id1454253776";

    this.canLaunchSuccessCallback = this.canLaunchSuccessCallback.bind(this);
    this.canLaunchErrorCallback = this.canLaunchErrorCallback.bind(this);
    this.launchSuccessCallback = this.launchSuccessCallback.bind(this);
    this.lanLaunchErrorCallback = this.launchErrorCallback.bind(this);
    this.doStartJimboGo = this.doStartJimboGo.bind(this);
    this.renderBrowser = this.renderBrowser.bind(this);
    this.doStore = this.doStore.bind(this);
    this.scrollIntoView = this.scrollIntoView.bind(this);
  }

  state = {
      routeIndex: 0,
      isOpen: false,
      loading: true,
      jimbogo_routes: [],
  };

  static propTypes = {
    ligplekId: PropTypes.number.isRequired,
  }

  componentDidMount() {
    const ApiUrl = protDomPort+"/lpow/api/ligplek/"+this.props.ligplekId+"/jimbogo/";
    fetch(ApiUrl)
      .then(res => res.json())
      .then(
        result => this.setState({ loading: false,
                                  jimbogo_routes: result.routes }),
        error => this.setState({ loading: false,
                                 error })
      )
  }

  renderLoading() {
    return (<li><label>Fietsen of Wandelen met Verhalen: </label>
                data wordt opgehaald.</li>)
  }

  renderError() {
    return (<li><label>Fietsen of Wandelen met Verhalen: </label>
                Een fout helaas... {this.state.error}..</li>)
  }

  renderNoData() {
    return null
    //return(
    //       <li><label>Fietsen of Wandelen met Verhalen: </label>Geen routes</li>
    //      )
  }

  doStore(url) {
    console.log("Naar URL: "+url);
    var ref = cordova.InAppBrowser.open(url, "_system", "");
  }

  renderBrowser() {
    return(<div id="id_jimbo-platform-install-info" className="jimbo-platform-install-info">
             <span>Installeren voor verschillende platformen.</span>
             <br/>
             <ButtonGroup>
              <Button color="secondary"  onClick={() => {this.doStore(this.jimboPlaystoreUrl)}} >Android</Button>
              <Button color="secondary"  onClick={() => {this.doStore(this.jimboAppstoreUrl)}} >iOS</Button>
             </ButtonGroup>
             <br/>
             Bovenstaande knoppen verwijzen naar de <b>Storytrails</b> app voor het respectivelijke platform.
             Zoek binnen de <b>Storytrails</b> app naar de route van uw keuze.
             <br/>
             Uiteindelijk moet <b>Storytrails</b> de route direct kunnen laden. Tot die tijd is het handwerk.
             <hr/>
           </div>)
  }

  // Default handlers
  canLaunchSuccessCallback(data) {
      // console.log("canLaunch Success! data: "+data);
      // if calling canLaunch() with getAppList:true, data will contain an array named "appList" with the package names of applications that can handle the uri specified.
      window.plugins.launcher.launch({packageName:'com.jimbogo.jimbogoapp',
                                        uri:'https://jimboplatform.nl/api/jimbo/v1.0/routes/857151/',
                                   		dataType:'Parcelable'}, // myParcelable
                                        this.launchSuccessCallback,
                                        this.launchErrorCallback);
  }
  canLaunchErrorCallback(errMsg) {
      const text_message = "canLaunch Error! " + errMsg;
      const new_message = {
           message: text_message,
           next: true,
           error: false,
           icon: "info",
           duration:5000,
      }
      dispatch(setMessage(new_message));
  }

  launchSuccessCallback(data) {
      console.log("JimboGo Launch Done");
  }
  launchErrorCallback(errMsg) {
      console.log("launchError! " + errMsg);
  }

  doStartJimboGo() {

      console.log("device.platform: "+device.platform);
      console.log("device.model: "+device.model);
      console.log("device.uuid: "+device.uuid);
      // als browser, niet op mobiel
      // Als browser op mobiel
      if (device.platform == "browser") {
          // var ref = cordova.InAppBrowser.open("https://steckelfisch.nl/", "_system", "");
          // this.doStore(this.jimboPlaystoreUrl);
      }
      // als app op Android
      if (device.platform == "Android") {
          var canLanchResult = window.plugins.launcher.canLaunch({packageName: "com.jimbogo.jimbogoapp"},
                                                                   this.canLaunchSuccessCallback,
                                                                   this.canLaunchErrorCallback);
      }
      // als app op IOS
      if (device.platform == "IOS") {
          var canLanchResult = window.plugins.launcher.canLaunch({packageName: "com.jimbogo.jimbogoapp"},
                                                                   this.canLaunchSuccessCallback,
                                                                   this.canLaunchErrorCallback);
      }
  }

  scrollIntoView() {
                  var jimbo_info = document.getElementById("id_jimbo-platform-install-info-top");
                  if (jimbo_info) {
                      jimbo_info.scrollIntoView(true);
                  } else {
                     var test = document.getElementById("id_ligplek-info-top");
                     test.scrollIntoView(true);
                  }
  }


  renderData() {
    var jimbogo_route_thumbs = this.state.jimbogo_routes.map((jimbogo_route, index) => {
                                var route_images = jimbogo_route.images[0]; // riskant
                                var route_name = jimbogo_route.name[0].value; // riskant
                                var route_organisation = jimbogo_route.organisation;
                                var route_start_name = jimbogo_route.startingSpot.name[0].value; // riskant
                                var route_start_location = jimbogo_route.startingSpot.location;
                                var route_start_distance = jimbogo_route.distance; // afstand ligplek tot startpunt wandelroute
                                var lengthDesc = jimbogo_route.lengthDescription;
                                var route_lengthDescription = (lengthDesc && lengthDesc.length>0) ? lengthDesc[0].value : "Onbekend";
                                var route_type = jimbogo_route.roles[0]._clazz;
                                var route_organisation_logo = (route_organisation.branding && route_organisation.branding.listLogo) ? route_organisation.branding.listLogo: ''; // no Logo
                                if(route_type =='uso.db.route_roles.WalkRouteRole') {
                                    route_type = 'Wandelroute';
                                }
                                if(route_type =='uso.db.route_roles.BikeRouteRole') {
                                    route_type = 'Fietsroute';
                                }
                                var j_key="jimbo-"+this.props.ligplekId+index
                                return (<div key={j_key} className="jimbogo-container" onClick={this.doStartJimboGo}>
                                         <img className="img-small" src={route_images.imageUrl} />
                                         <span className="heading">
                                             <span className="name" >{route_name}</span>
                                             <span className="brand" >
                                                 <img className="img-brand" src={route_organisation_logo}/>
                                             </span></span>
                                         <span className="label-value">{route_type}</span>
                                         <span className="label-value"><label>Start</label>: <span className="value" >{route_start_name}</span></span>
                                         <span className="label-value"><label>Lengte</label>: <span className="value" >{route_lengthDescription}</span></span>
                                         <span className="label-value"><label>Afstand ligplek tot start</label>: <span className="value" >{route_start_distance} km</span></span>
                                        </div>
                                        )

                                })

    const { routeIndex, isOpen, loading, jimbogo_routes } = this.state;
    const new_style = { 'z-index': 999};

    return ( <li >
               <label id="id_jimbo-platform-install-info-top">Fietsen of Wandelen met verhalen:</label>
               <div >
                {isOpen && (
                  this.renderBrowser()
                )}
                <div className="jimbogo-container-list" onClick={() => {this.setState({ isOpen: true });this.scrollIntoView()}}>
                   {jimbogo_route_thumbs}
                </div>
              </div>
             </li>
            )
  }


  render () {
      if (this.state.loading) {
         return this.renderLoading()
      }
      try {
          if  (this.state.jimbogo_routes.length==0) {
             return this.renderNoData()
          } else if (this.state.jimbogo_routes.length>0) {
             return this.renderData()
          } else {
             return this.renderError()
          }
      } catch (e) {
          console.log("Jimboo fout: "+e)
          return this.renderError()
      }
  }

}


module.exports = {
  LigplekDetails: LigplekDetails,
  LigplekCreate: LigplekCreate,
  LigplekUpdate: LigplekUpdate,
  LigplekAdopt: LigplekAdopt,
}
