import React, { Component } from 'react'
import PropTypes from "prop-types"
import { Navigate, } from 'react-router-dom'
import { Label, InputGroup, InputGroupAddon, InputGroupText,
         Input, Button, ButtonGroup, Form, FormGroup, Alert } from 'reactstrap';
import { showRegister, doRegister,
         doLogin, doLogout, receiveLogoutSucces,
         showPasswordReset, doPasswordReset } from "../actions/AuthActions"


class Register extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this)
    this.sendRegistration = this.sendRegistration.bind(this)
    this.renderRegisterSucces = this.renderRegisterSucces.bind(this)
    this.renderPasswdError = this.renderPasswdError.bind(this)
    //this.renderErrors = this.renderErrors.bind(this)
  }

  static propTypes = {
     dispatch: PropTypes.func.isRequired,
     isFormValid: PropTypes.func.isRequired,
  }

  state ={

  }

  handleChange(e) {
      this.setState({[e.target.name]: e.target.value,});
  }

  sendRegistration() {
      if(this.state.password1 && this.state.password2 && this.state.password1 == this.state.password2){
          this.setState({passwd_set_error: null})
          this.props.dispatch(doRegister(this.state.email, this.state.password1, this.state.password2))
      } else {
          this.setState({passwd_set_error: "Passwords niet goed of niet gelijk"});
      }
  }

  renderRegisterSucces(message) {
      return (
        <div>
         <p>{message}</p>
         <p>{this.state.email}</p>
        </div>
      )
  }

  renderPasswdError() {
      if(this.state.passwd_set_error) {
          return(
                <div>
                 {this.state.passwd_set_error}
                </div>)
      }
  }

  render() {
    if(this.props.succes && this.state.email) {
       return this.renderRegisterSucces(this.props.succes)
    }

    let _email_error = null;
    if (this.props.error['email']) {
      _email_error = (<Alert color="info">{this.props.error['email']}</Alert>);
    }

    let _password1_error = null;
    if (this.props.error['password1']) {
      _password1_error = (<Alert color="info">{this.props.error['password1']}</Alert>);
    }

    let _non_field_errors = null;
    if (this.props.error['non_field_errors']) {
      _non_field_errors = (<Alert color="info">{this.props.error['non_field_errors']}</Alert>);
    }

    return (
        <div>
         <p>Vul email en wachtwoord(2x) in.</p>
         <p>Er zal een bevestigings mail naar het email adres gestuurd worden.</p>
         <Form className="small-not-on-mobile auth-form"  onChange={this.handleChange}>

          <FormGroup className="">
            <Label className="lpow-label">Email</Label>
            <Input type="email" name="email" placeholder="e-mail"
                   autoComplete="email" required="required" className="form-control lpow-input" />
            {_email_error}
          </FormGroup>

          <FormGroup className="">
            <Label className="lpow-label">Wachtwoord</Label>
            <Input type="password" name="password1" id="password1" autoComplete="new-password"  required="required"
                   placeholder="wachtwoord" className="form-control lpow-input" />
            {_password1_error}
          </FormGroup>

          <FormGroup className="">
            <Label className="lpow-label">Wachtwoord controle</Label>
            <Input type="password" name="password2" id="password2" autoComplete="new-password"  required="required"
                   placeholder="wachtwoord verificatie" className="form-control lpow-input" />
          </FormGroup>

          { this.renderPasswdError() }

          {_non_field_errors}

          <Button type="button" onClick={this.sendRegistration}>Registreer</Button>
         </Form>
        </div>
     )}
}


class RegisterSucces extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this)
    //this.sendRegistration = this.sendRegistration.bind(this)
    //this.renderRegisterSucces = this.renderRegisterSucces.bind(this)
  }

  static propTypes = {
     dispatch: PropTypes.func.isRequired,
     succes: PropTypes.string.isRequired,
     isFormValid: PropTypes.func.isRequired,
  }

  state ={
  }

  handleChange(e) {
      this.setState({[e.target.name]: e.target.value,});
  }


  render() {
    // console.log("Register succes: "+this.props.succes)
      return (
        <div>
         <p>{this.props.succes}</p>
         <p>{this.state.email}</p>
        </div>
      )
  }
}

class LoginWithPassw extends React.Component {

  constructor(props) {
    super(props);

    //console.log("LoginWithPassw.constructor "+this.props.error)
    //console.log("LoginWithPassw.constructor ['non_field_errors'] "+this.props.error['non_field_errors'])

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.renderButtons = this.renderButtons.bind(this)
    this.showPasswordReset = this.showPasswordReset.bind(this)
    // console.log("this.props.error: "+this.props.error)
    if (this.props.error['email']) {
      // this.setState({email_error: this.props.error['email']});
      this.state.email_error= this.props.error['email'];
    }

    if (this.props.error['password']) {
      // this.setState({password_error: this.props.error['password']});
      this.state.password_error = this.props.error['password'];
    }

    if (this.props.error['non_field_errors']) {
      // this.setState({non_field_errors: this.props.error['non_field_errors']});
      this.state.non_field_errors = this.props.error['non_field_errors'];
    }
    if (this.props.error['message']) {
      // this.setState({non_field_errors: this.props.error['non_field_errors']});
      this.state.non_field_errors = this.props.error['message'];
    }
    //console.log("this.state.non_field_errors: "+this.state.non_field_errors)
  }

  state = {
      username: null,
      email: null,
      password: null,
      non_field_errors: null,
      email_error: null,
      password_error: null,
  }


  static propTypes = {
     dispatch: PropTypes.func.isRequired,
     doShowResetPassword: PropTypes.func.isRequired,
     // isFormValid: PropTypes.func.isRequired,
  }

  handleChange(e) {
      // console.log("handleChange "+e.target.value)
      this.setState({ [e.target.name] : e.target.value,
                      'email_error': "",
                      'password_error': "",
                      'non_field_errors': "" })
  }

  handleSubmit(e) {
      const username = this.state.email;
      const password = this.state.password;
      this.props.dispatch(doLogin(username, password));
  }

  showPasswordReset() {
      // console.log("laat maar zien");
      this.props.doShowResetPassword();
  }

  renderButtons(non_field_errors) {
      // console.log("non_field_errors: "+non_field_errors)
      if(non_field_errors) {
      return(<ButtonGroup>
                 <Button type="button" onClick={this.handleSubmit}>Login</Button>
                 <Button type="button" onClick={this.showPasswordReset} color="primary" >Wachtwoord Herzet</Button>
             </ButtonGroup>)
      } else {
      return(<ButtonGroup>
                 <Button type="button" onClick={this.handleSubmit}>Login</Button>
             </ButtonGroup>)
      }
  }


  render() {
    let _email_error = null;
    let _password_error = null;
    let _non_field_errors = null;

    if (this.props.error['email']) {
      _email_error = (<Alert color="info">{this.props.error['email']}</Alert>);
    }

    if (this.props.error['password']) {
      _password_error = (<Alert color="info">{this.props.error['password']}</Alert>);
    }

    if (this.props.error['non_field_errors']) {
      _non_field_errors = (<Alert color="info">{this.props.error['non_field_errors']}</Alert>);
    }

    if (this.props.error['non_field_errors']) {
      _non_field_errors = (<Alert color="info">{this.props.error['non_field_errors']}</Alert>);
    }

    //if (this.state['non_field_errors']) {
    //  _non_field_errors = (<Alert color="info">{this.state['non_field_errors']}</Alert>);
      // this.state.non_field_errors = null
    //}

    return (
       <div>
         <p>Vul email en wachtwoord in.</p>
         <Form className="small-not-on-mobile auth-form" onChange={this.handleChange} >
          <FormGroup className="">
            <Label className="lpow-label">Email</Label>
            <Input  type="email" name="email" id="email"
                    autoComplete="email" placeholder="e-mail" className="form-control lpow-input"/>
            {_email_error}
          </FormGroup>

          <FormGroup className="">
            <Label className="lpow-label">Wachtwoord</Label>
            <Input type="password" name="password" id="password" autoComplete="current-password"
                   placeholder="wachtwoord" className="form-control lpow-input" />
            {_password_error}
          </FormGroup>

          {_non_field_errors}

          {this.renderButtons(_non_field_errors)}

         </Form>
       </div>
    )}
}


class LoginNoPassw extends React.Component {

  static propTypes = {
  }

  render() {
    return (
        <div>
         <p>Vul email in.</p>
         <p>Als het account bestaat en actief is, zal een mail met eenmalige inlog token en naar het email adres gestuurd worden</p>
         <Form className="small-not-on-mobile auth-form">
          <FormGroup className="">
            <Label className="lpow-label">Email</Label>
            <Input  type="email" name="email" placeholder="e-mail"
                    autoComplete="email" className="form-control lpow-input" />
          </FormGroup>
          <Button type="Button">Stuur Login Token</Button>
         </Form>
        </div>
     )}
}


class PasswordReset extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this)
    this.sendPasswordReset = this.sendPasswordReset.bind(this)
    this.renderMessage = this.renderMessage.bind(this)

  }

  static propTypes = {
  }

  state = {
      email: null,
  }

  handleChange(e) {
      this.setState({[e.target.name]: e.target.value,});
  }

  sendPasswordReset() {
     if(this.state.email) {
         this.props.dispatch(doPasswordReset(this.state.email));
     } //else {
     //    console.log("Niet aanvragen "+this.state.email);
     //}
  }

  renderMessage(message) {
      return (
        <div>
         <p>{message}</p>
         <p>{this.state.email}</p>
        </div>
      )
  }

  render() {
    if(this.props.pwd_reset_request_succes) {
       return this.renderMessage(this.props.pwd_reset_request_succes)
    }
    if(this.props.pwd_reset_request_error) {
       return this.renderMessage(this.props.pwd_reset_request_error)
    }
    return (
        <div  className="small-not-on-mobile">
         <p>Wachtwoord resetten.</p>
         <p> Vul email in. Als het account bestaat en actief is, zal een mail met reset instructies naar dat adres gestuurd worden.</p>
         <Form className="small-not-on-mobile auth-form" >
          <FormGroup className="">
            <Label>Email</Label>
            <Input  type="email" name="email" placeholder="e-mail" autoComplete="email"
                    onChange={this.handleChange} className="form-control" />
          </FormGroup>
          <Button type="Button" onClick={this.sendPasswordReset}>Stuur Herzet Wachtwoord eMail</Button>
         </Form>
        </div>
     )}
}






class AuthModule extends React.Component {
  constructor(props) {
    super(props);

    //state = {
    //}
    this.state = {
       showLogin: this.props.showLogin,
       showPasswordReset: false,
       loginValid: false,
       showRegister: this.props.showRegister,
       registerValid: false,
       error: this.props.error,
       showProfile: this.props.showProfile, // showProfile
    }

    this.renderAuthContent = this.renderAuthContent.bind(this)
    this.renderButtons = this.renderButtons.bind(this)
    this.setLoginValid = this.setLoginValid.bind(this)
    this.setRegisterValid = this.setRegisterValid.bind(this)
    this.doShowLogin = this.doShowLogin.bind(this)
    this.doShowResetPassword = this.doShowResetPassword.bind(this)
    this.doShowRegister = this.doShowRegister.bind(this)
    this.doLogout = this.doLogout.bind(this)
  }

  static propTypes = {
    token_key: PropTypes.string,
    error: PropTypes.object,

    loginValid: PropTypes.bool.isRequired,
    showLogin:  PropTypes.bool.isRequired,
    showRegister: PropTypes.bool.isRequired,
    showProfile: PropTypes.bool.isRequired,
  }

  componentWillUnmount() {
     // localStorage.setItem('lpowAuth', JSON.stringify(this.state));
  }

  setLoginValid(validity) {
      console.log("Login validity: "+validity)
  }

  setRegisterValid(validity) {
      console.log("Register validity: "+validity)
  }

  doShowLogin() {
    this.setState({showLogin:true,
                   showPasswordReset: false,
                   showRegister:false,
                   error:{}})
  }

  doShowResetPassword() {
    this.props.dispatch(showPasswordReset())
    this.setState({showLogin: true,
                   showPasswordReset: true,
                   showRegister: false,
                   error:{}})
  }

  doShowRegister() {
    this.props.dispatch(showRegister())
    this.setState({showLogin: false,
                   showPasswordReset: false,
                   showRegister: true,
                   error: {}})
  }

  doLogout() {
      this.props.dispatch(doLogout(this.props.token_key))
  }

  renderAuthContent() {
      console.log("renderAuthContent...... Error="+this.state.error);
      console.log("this.props.showProfile: "+this.props.showProfile);
      if (this.props.showProfile) {  // showProfile
       return( <div>
                  <Navigate to="/profile/" />
               </div>)
      } else {
          let _login_error = ""
          if (this.props.login_error) {
              _login_error = this.props.login_error;
              console.log("_login_error="+_login_error);
          }
          let _registration_error = ""
          if (this.props.registration_error) {
              _registration_error = this.props.registration_error;
          }
          let _registration_succes = ""
          if (this.props.registration_succes) {
              _registration_succes = this.props.registration_succes;
          }
          if (this.state.showLogin) {
              if (this.state.showPasswordReset) {
                 return(<div>
                          <PasswordReset dispatch={this.props.dispatch}
                                         doShowLogin={this.doShowLogin}
                                         pwd_reset_request_succes={this.props.pwd_reset_request_succes}/>
                        </div>)
              }
              else {
                 return(<div>
                          <LoginWithPassw dispatch={this.props.dispatch}
                                          error={_login_error}
                                          isFormValid={this.setLoginValid}
                                          doShowResetPassword={this.doShowResetPassword} />
                        </div>)
              }
          }
          if (this.state.showRegister) {
              if (_registration_succes) {
                return(<div>
                         <RegisterSucces dispatch={this.props.dispatch}
                                         succes={_registration_succes}
                                         isFormValid={this.setRegisterValid} />
                       </div>)
              } else {
                return(<div>
                         <Register dispatch={this.props.dispatch}
                                   error={_registration_error}
                                   succes={_registration_succes}
                                   isFormValid={this.setRegisterValid} />
                       </div>)
              }
          }
      }
  }

  renderButtons() {
      if (this.props.showProfile) { //showProfile
          return(<div className="small-not-on-mobile">
                  <ButtonGroup>
                     <Button onClick={this.doLogout} color="primary" >Uitloggen</Button>
                  </ButtonGroup>
                 </div>)
      }
      // show Login/Register
      return(<div className="small-not-on-mobile">
                  <ButtonGroup>
                    <Button onClick={this.doShowLogin}  color="primary" >Inloggen</Button>
                    <Button onClick={this.doShowRegister}  color="primary" >Registreren</Button>
                  </ButtonGroup>
             </div>)
  }

  render() {
     return(
       <div>
         { this.renderButtons() }
         <br/>
         { this.renderAuthContent() }
       </div>
     )
  }

}

module.exports = {
    AuthModule,
    Register: Register,
    LoginWithPassw: LoginWithPassw,
    LoginNoPassw: LoginNoPassw,
    // Profile,
}
