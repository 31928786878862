import * as popupModalActions from '../actions/PopupModalActions';

const initialState = {
   displayModal: false,
}

const PopupModalReducer= (state = initialState, action) => {
  // console.log("popupReducer: "+action.type);
  switch (action.type) {

    case popupModalActions.DISPLAY_POPUP:
      return Object.assign({}, state, {
          popupModal:{ isPaneOpen: true,
                       popupContent: {}
                      }
        })

    case popupModalActions.HIDE_POPUP:
      return Object.assign({}, state, {
          popupModal:{ isPaneOpen: false,
                       popupContent: {}
                      }
        })

    default:
      return state
  }
}


export default PopupModalReducer
