
import * as authActions from '../actions/AuthActions';

// function authReducer(state=[], action) {

const initialState = {
    token_key: "",
    login_error: "",
    login_succes: "",
    logout_succes: "",
    logout_error: "",
    registration_error: "",
    registration_succes: "",
    pwd_reset_request_error: "",
    pwd_reset_request_succes: ""
}

const AuthReducer = (state = initialState, action) => {

  switch (action.type) {
    case authActions.AUTH_REGISTER:
      return Object.assign({}, state,
                             {token_key: "",
                              login_error: "",
                              login_succes: "",
                              logout_succes: "",
                              logout_error: "",
                              registration_error: "",
                              registration_succes: "",
                              pwd_reset_request_error: "",
                              pwd_reset_request_succes: ""})

    case authActions.AUTH_REGISTER_SUCCES:
      return Object.assign({}, state,
                             {token_key: "",
                              login_error: "",
                              login_succes: "",
                              logout_succes: "",
                              logout_error: "",
                              registration_error: "",
                              registration_succes: "Er is een bevestigings e-mail onderweg naar ingevuld adres." ,
                              pwd_reset_request_error: "",
                              pwd_reset_request_succes: ""})

    case authActions.AUTH_REGISTER_FAIL:
      return Object.assign({}, state,
                             {ltoken_key: "",
                              login_error: "",
                              login_succes: "",
                              logout_succes: "",
                              logout_error: "",
                              registration_error: action.error_data,
                              registration_succes: "",
                              pwd_reset_request_error: "",
                              pwd_reset_request_succes: ""})

    case authActions.AUTH_LOGIN: // authActions.AUTH_LOGIN_REQUEST
      // console.log("authActions.AUTH_LOGIN");
      return Object.assign({}, state,
                             {token_key: "",
                              login_error: "",
                              login_succes: "",
                              logout_succes: "",
                              logout_error: "",
                              registration_error: "",
                              registration_succes: "",
                              pwd_reset_request_error: "",
                              pwd_reset_request_succes: ""})

    case authActions.AUTH_LOGIN_SUCCES:
      return Object.assign({}, state,
                                {token_key: action.token_key,
                                 login_error: "",
                                 login_succes: "",
                                 logout_succes: "",
                                 logout_error: "",
                                 registration_error: "",
                                 registration_succes: "",
                                 pwd_reset_request_error: "",
                                 pwd_reset_request_succes: ""})

    case authActions.AUTH_LOGIN_FAIL:
      return Object.assign({}, state,
                                {login_error: action.error,
                                 login_succes: "",
                                 logout_succes: "",
                                 logout_error: "",
                                 registration_error: "",
                                 registration_succes: "",
                                 token_key: "",
                                 pwd_reset_request_error: "",
                                 pwd_reset_request_succes: ""})


    case authActions.AUTH_LOGOUT:
      return Object.assign({}, state,
                                {login_error: "",
                                 logout_error: "",
                                 registration_error: "",
                                 registration_succes: "",
                                 pwd_reset_request_error: "",
                                 pwd_reset_request_succes: ""})


    case authActions.AUTH_LOGOUT_SUCCES:
      localStorage.setItem('lpowAuth', JSON.stringify({"token_key":""}));
      return Object.assign({}, state,
                                {token_key: "",
                                 login_succes: "",
                                 login_error: "",
                                 logout_succes: "",
                                 logout_error: "",
                                 registration_succes: "",
                                 registration_error: "",
                                 pwd_reset_request_succes: "",
                                 pwd_reset_request_error: ""})



    case authActions.AUTH_LOGOUT_FAIL:
      return Object.assign({}, state,
                                {token_key: "",
                                 login_succes: "",
                                 login_error: "",
                                 logout_error: action.error,
                                 logout_succes: "",
                                 registration_error: "",
                                 registration_succes: "",
                                 pwd_reset_request_error: "",
                                 pwd_reset_request_succes: ""})


    case authActions.AUTH_PWD_RESET:
      return Object.assign({}, state,
                                {token_key: "",
                                 login_error: "",
                                 login_succes: "",
                                 logout_succes: "",
                                 logout_error: "",
                                 registration_error: "",
                                 registration_succes: "",
                                 pwd_reset_request_error: "",
                                 pwd_reset_request_succes: ""})


    case authActions.AUTH_PWD_RESET_SUCCES:
      return Object.assign({}, state,
                                {token_key: "",
                                 login_error: "",
                                 login_succes: "",
                                 logout_succes: "",
                                 logout_error: "",
                                 registration_error: "",
                                 registration_succes: "",
                                 pwd_reset_request_error: "",
                                 pwd_reset_request_succes: "Er is een mail onderweg naar ingevulde adres."})


    case authActions.AUTH_PWD_RESET_FAIL:
      return Object.assign({}, state.auth,
                                {token_key: "",
                                 login_error: "",
                                 login_succes: "",
                                 logout_succes: "",
                                 logout_error: "",
                                 registration_error: "",
                                 registration_succes: "",
                                 pwd_reset_request_error: "Het zit allemaal niet mee. Geen account bekend met dit adres.",
                                 pwd_reset_request_succes: ""})


    case authActions.AUTH_CLEAR:
      localStorage.setItem('lpowAuth', JSON.stringify({"token_key":""}));
      return Object.assign({}, state,
                                {token_key: "",
                                 login_succes: "",
                                 login_error: "",
                                 logout_succes: "",
                                 logout_error: "",
                                 registration_succes: "",
                                 registration_error: "",
                                 pwd_reset_request_succes: "",
                                 pwd_reset_request_error: ""})

    default:
      // console.error("authReducer Default Action on '"+action.type+"'")
      return state
    }

}

//module.exports = {
//  authReducer,
//}

export default AuthReducer
