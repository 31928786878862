import * as positionActions from '../actions/PositionActions';

const initialState = {
    coords: {latitude: 51.6,
             longitude: 5.6,
             altitude: 0,
             accuracy: positionActions.POSITION_ACCURACY_BY_HAND,
             altitudeAccuracy: 9999,
             heading: 0,
             speed:0},
    timestamp: Date.now(),
    watchId: -1, // -1 watch not started, 0: watch about to start, >0 process-watchId
    // watchSuspended: false,
    positionSource: positionActions.POSITION_GPS_OFF
}



const PositionReducer= (state = initialState, action) => {
  // console.log("PositionReducer: "+action.type);
  switch (action.type) {
    case positionActions.SET_POSITION_SOURCE:
      return Object.assign({},
                           state,
                           {positionSource: action.positionSource,
                           }
                          )

    case positionActions.RECEIVE_CURRENT_POSITION:
      console.log("RECEIVE_CURRENT_POSITION coords: "+action.position.coords)
      return Object.assign({},
                           state,
                           {coords: action.position.coords}
                          )

    case positionActions.ERROR_CURRENT_POSITION:
      return Object.assign({},
                           state,
                           {coords: action.position.coords}
                           )

    case positionActions.STARTING_WATCH_POSITION:
      // console.log("state.startingWatchingPosition");
      if(state.watchId & state.watchId>0) {
          // kennelijk starten we opnieuw, dus eerst stoppen
          // try-catch voor eventueel falen
          try {
              console.info(positionActions.STARTING_WATCH_POSITION+" Try to clearWatch "+state.watchId)
              navigator.geolocation.clearWatch(state.watchId);
          } catch (e) {
              console.error(positionActions.STARTING_WATCH_POSITION+" Failing to clearWatch "+state.watchId)
          }
      }
      return Object.assign({},
                           state,
                           {watchId: 0 // 0: it is starting
                           })

    case positionActions.START_WATCH_POSITION_SUCCES:
      // console.log("state.WatchPositionId: "+action.watch_id);
      return Object.assign({},
                           state,
                           {watchId: action.watch_id,  // the id of the watchProcess
                            positionSource: positionActions.POSITION_GPS_WATCH
                           })

    case positionActions.SUSPEND_WATCH_POSITION:
      console.log("Stop WatchProcess state.watchId: "+state.watchId);
      // let watchSuspended = false;
      if(state.watchId & state.watchId>0) {
          try{
              navigator.geolocation.clearWatch(state.watchId);
              // watchSuspended = true;
          } catch(e) {
              console.error("Suspend Error: "+e);
          } finally {
              // watchSuspended = true; // ? ook na een error?
          }
      }
      return Object.assign({},
                           state,
                           {watchId: 0,
                            // watchSuspended // the indication that the Watch is Suspended
                           })

    case positionActions.RESTART_SUSPENDED_WATCH_POSITION: // KAN WEG
      console.log("state.RestartSuspendedWatch: TEST HOEFT NIET, DAT MOET GEBEUREN VOOR DE dispatch");
      if(state.watchId>0) {
          navigator.geolocation.clearWatch(state.watchId);
          // Doe de Restart
          positionActions.startWatchPosition();
          // console.log("Clean Alles kleiner dan "+newWatchId);
          // navigator.geolocation.clearWatch(newWatchId-1);
      }
      return Object.assign({},
                           state,
                           {// watchId: -1, // the indication that the Watch is Suspended
                            // watchSuspended: false
                           })

    case positionActions.STOP_WATCH_POSITION:
      console.log("state.stopWatchPosition.watchId: "+state.watchId);
      if(state.watchId>0) {
          try{
              navigator.geolocation.clearWatch(state.watchId);
          } catch(e) {
              console.error("Error Clearing GeoLocation Watch: "+e);
              return state // state niet aanpassen
          } finally {
          }

      }
      return Object.assign({},
                           state,
                           {watchId: -1, // no watch running or starting
                            positionSource: positionActions.POSITION_GPS_OFF // the last GPS data will remain.
                           })


    case positionActions.START_SHARE_POSITION:
    case positionActions.SHARE_POSITION_SUCCES:
    case positionActions.SHARE_POSITION_FAIL:
      console.log("PositionAction Catch All")
      return Object.assign({},
                           state,
                           {})

    default:
      return state
  }

}

export default PositionReducer
