import React from 'react'
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import LigplekModal from "../components/LigplekModal"
import {hideLigplek, showLigplek, updateLigplek} from "../actions/LpowActions"


function getLigplek(lpowState, ligplekId) {
    if (ligplekId==0) {
        return null
    }
    let selected_ligplek = lpowState.ligplekken.ligplekken.find(function(ligplek) {
                     return ligplek.id == ligplekId;
                  });
    // console.log("selected_ligplek: "+selected_ligplek);
    if(!selected_ligplek) {
         // console.log("misschien een aangebrachte ligplek");
         selected_ligplek = lpowState.created_ligplekken.ligplekken.find(function(ligplek) {
                         return ligplek.id == ligplekId;
                      });
         if(!selected_ligplek){
            selected_ligplek = null;
            // console.error("ER IS EEN NIETBESTAANDE LIGPLEK?");
         }
    }
    return selected_ligplek
}


const LigplekModalContainer = connect(

	function mapStateToProps(state) {
	    let ligplekIsFavourite = state.lpowState.favourite_ligplekken.ligplekken.find(
	         function(ligplek){
	             return ligplek.id == state.id
	         }
	    );

	    if (ligplekIsFavourite) {
	      ligplekIsFavourite = true
	    } else {
	      ligplekIsFavourite = false
	    }

        let _selected_ligplekken = state.lpowState.selected_ligplekken.ligplekken ? state.lpowState.selected_ligplekken.ligplekken : []
	    let ligplekIsSelected = _selected_ligplekken.find(
	         function(ligplek){
	             return ligplek.id == state.id
	         }
	    );

	    if (ligplekIsSelected) {
	      ligplekIsSelected = true
	    } else {
	      ligplekIsSelected = false
	    }

        let _username_email = "";
        if( state.profile && state.profile.user ) {
           _username_email = state.profile.user.username ? state.profile.user.username : "";
        }
        let ligplekId = state.ligplekModal.id ? state.ligplekModal.id : 0;
        let _ligplek = getLigplek(state.lpowState, ligplekId);
		return {
			   isPaneOpen: state.ligplekModal.displayModal,
               displayLigplekDetails: state.ligplekModal.displayLigplekDetails,
               displayLigplekUpdate: state.ligplekModal.displayLigplekUpdate,
               displayLigplekAdopt: state.ligplekModal.displayLigplekAdopt,
               displayLigplekCreate: state.ligplekModal.displayLigplekCreate,
               ligplekId,
               ligplek: _ligplek,
               ligplekIsSelected: ligplekIsSelected,
               ligplekIsFavourite: ligplekIsFavourite,
               latlng: state.ligplekModal.latlng,
               position: state.position,
               className: "lpowModal",
               token_key: state.auth.token_key,
               username_email: _username_email
		};
	},

	function mapDispatchToProps(dispatch) {
		return {
		  dispatch,
        }
	}
)(LigplekModal);


export default LigplekModalContainer
