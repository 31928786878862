import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Field, Form } from 'react-final-form'; //Form
import { Label, InputGroup, InputGroupAddon, InputGroupText,
         Input, Button, ButtonGroup, FormGroup, Alert, Spinner } from 'reactstrap';
import {Form as RSForm} from 'reactstrap';
import parse from 'html-react-parser';

import { CategorieDropdown, KarakterestiekDropdown } from "./LpowComponents";
import DropZoneField from "../components/DropzoneField/dropzoneField";
import { doLogout, receiveLogoutSucces, } from "../actions/LpowActions";

import { protDomPort,} from "../actions/LpowActions";
import { viewProfile, doUpdateProfile, receiveProfileUpdateSucces,
         receiveProfileUpdateFail} from "../actions/ProfileActions";

//import { css } from '@emotion/core';
//import { ClipLoader } from 'react-spinners';
//const override = css`
//    display: inline-block;
//    margin: auto;
//    border-color: red;
//`;


const imageIsRequired = value => (!value ? "Required" : undefined);
const notRequired = undefined;


class ProfileForm extends Component {

  constructor(props) {
    super(props);
    this.resetForm = this.resetForm.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleImageOnDrop = this.handleImageOnDrop.bind(this);
    this.handleIconOnDrop = this.handleIconOnDrop.bind(this);
  }

  static propTypes = {
     initialValues: PropTypes.object.isRequired,
     token: PropTypes.string.isRequired,
     onSubmit: PropTypes.func.isRequired
  }

  state = {
       imageFile: [],
       iconFile: [],
       loading: false
  };


  handleImageOnDrop = (newImageFile, onChange) => {
    let imageFile = {
          file: newImageFile[0],
          name: newImageFile[0].name,
          preview: URL.createObjectURL(newImageFile[0]),
          size: newImageFile[0].size
    };
    this.setState({ imageFile: [imageFile] }, () => onChange(imageFile));
  };

  handleIconOnDrop = (newIconFile, onChange) => {
    let iconFile = {
          file: newIconFile[0],
          name: newIconFile[0].name,
          preview: URL.createObjectURL(newIconFile[0]),
          size: newIconFile[0].size
    };
    this.setState({ iconFile: [iconFile] }, () => onChange(iconFile));
  };

  resetForm = () => this.setState({ imageFile: [],
                                    iconFile: []}, () => this.props.reset());

  cancelEdit = () => this.props.dispatch(viewProfile());

  handleFormSubmit = formProps => {
    // console.log("handleFormSubmit formProps: "+JSON.stringify(formProps, null, 4));
    //e.preventDefault();
    const fd = new FormData();
    if (formProps.ship_img && formProps.ship_img.file) {
       fd.append("ship_img", formProps.ship_img.file);
    }
    if(formProps.ship_icon && formProps.ship_icon.file) {
       fd.append('ship_icon', formProps.ship_icon.file)
    }

    // append any additional Redux form fields
    if(formProps.user.first_name) {
       fd.append('user.first_name', formProps.user.first_name)
    }
    if(formProps.user.last_name) {
       fd.append('user.last_name', formProps.user.last_name)
    }
    if(formProps.ship_name) {
       fd.append('ship_name', formProps.ship_name)
    }
    if(formProps.ship_berth) {
       fd.append('ship_berth', formProps.ship_berth)
    }

    if(formProps.share_profile) {
       fd.append('share_profile', formProps.share_profile)
    }
    if(formProps.share_location) {
       fd.append('share_location', formProps.share_location)
    }
    // create an AJAX request here with the created formData
    const conf = {
      method: "PUT",
      headers: { 'Authorization': 'Token '+this.props.token  // + Auth.getAuthToken()
         },
      body: fd, // JSON.stringify(data)
    };
    const endpoint = "/lpow/api/user/profile/"
    let new_endpoint = protDomPort+endpoint;
    this.setState({loading: true});
    fetch(new_endpoint, conf)
         .then( response => { return response.json() } )
         .then( response => { this.props.dispatch(receiveProfileUpdateSucces(response))} )
         .finally( () => {this.setState({loading: false})});
  };

  renderSpinner() {
     if(this.state.loading) {
         return (<Spinner color="primary" />)
     }
  }

  // validate={[imageIsRequired]}
  render() { console.log("ProfileForm this.props.initalValues: "+this.props.initialValues);
             console.error("props.intialValues naar propes.profile")
             let ship_img_thumbnail_url = '';
             if(this.props.initialValues.ship_img_thumbnail_url) {
                ship_img_thumbnail_url = this.props.initialValues.ship_img_thumbnail_url;
             }
             return( <Form onSubmit={this.handleFormSubmit}
                           initialValues={this.props.initialValues}
                           render={({ handleSubmit, form, submitting, pristine, values }) => (

                                 <form className="dropzone"
                                           onSubmit={handleSubmit} >

                                     <fieldset>
                                      <legend>Schipper</legend>
                                      <FormGroup className="">
                                        <Label className="lpow-label">Voornaam</Label>
                                        <Field  component="input" name="user.first_name" placeholder="Voornaam"
                                                required="required"
                                                className="lpow-input" autoComplete="first_name" />
                                      </FormGroup>

                                      <FormGroup className="">
                                        <Label className="lpow-label">Achternaam</Label>
                                        <Field component="input" name="user.last_name" placeholder="Achternaam"
                                               required="required"
                                               className="lpow-input" autoComplete="last_name" />
                                      </FormGroup>
                                      <FormGroup className="">
                                        <Label className="lpow-label">username/email</Label>
                                        <Field component="input" name="user.username" placeholder=""
                                               required="required" disabled
                                               className="lpow-input" autoComplete="last_name" />
                                      </FormGroup>
                                  </fieldset>

                                  <fieldset>
                                      <legend>Schip</legend>
                                      <FormGroup className="">
                                        <Label className="lpow-label">Naam</Label>
                                        <Field component="input" name="ship_name" placeholder="Scheepsnaam"
                                               required="required"
                                               className="lpow-input" autoComplete="ship_name" />
                                      </FormGroup>

                                      <FormGroup className="">
                                        <Label className="lpow-label">Afbeelding</Label>
                                        <div className="lpow-img-select">
                                           <img height="150" src={ship_img_thumbnail_url}/>
                                           <Field name="ship_img"
                                                  component={DropZoneField}
                                                  type="file"
                                                  imagefile={this.state.imageFile}
                                                  handleOnDrop={this.handleImageOnDrop}
                                                  placeholder="Scheepafbeelding"
                                                  className="lpow-input"
                                                  autoComplete="ship_img" />
                                        </div>
                                      </FormGroup>

                                      <FormGroup className="">
                                        <Label className="lpow-label">Thuishaven</Label>
                                        <Field component="input" name="ship_berth"
                                               className="lpow-input"  />
                                      </FormGroup>

                                  </fieldset>

                                  <fieldset>
                                      <legend>Delen</legend>
                                      <FormGroup className="">
                                        <Label className="lpow-label">Profiel</Label>
                                        <Field component="input" type="checkbox" name="share_profile"
                                               className="lpow-input" />
                                      </FormGroup>

                                      <FormGroup className="">
                                        <Label className="lpow-label">Locatie</Label>
                                        <Field component="input" type="checkbox" name="share_location"
                                               className="lpow-input" />
                                      </FormGroup>
                                  </fieldset>

                                  <ButtonGroup>
                                      <button type="submit"
                                              className="btn btn-primary submit"
                                              disabled={submitting} >
                                              Bewaren Profiel
                                              {this.renderSpinner()}
                                      </button>

                                      <button type="button"
                                              className="btn btn-secondary cancel"
                                              onClick={this.cancelEdit}
                                              // style={{ float: "right" }}
                                              >
                                              Afbreken
                                      </button>

                                      <button type="button"
                                              className="btn btn-secondary clear"
                                              disabled={pristine ||     submitting}
                                              onClick={form.reset}
                                              style={{ float: "right" }} >
                                              Clear
                                      </button>
                                  </ButtonGroup>
                           </form> )}
                     /> );
         }
}


class LigplekCreateForm extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static propTypes = {
    endpoint: PropTypes.string.isRequired,
    latlng: PropTypes.array.isRequired,
    doLigplekCreate: PropTypes.func.isRequired,
  }

  state = {
    name: "",
    categorie: 3,
    description: "",
    description_long: "",
    longitude: parseFloat(this.props.latlng[1].toFixed(10)),
    latitude:  parseFloat(this.props.latlng[0].toFixed(10)),
    maximale_diepgang: 0,
    loading: false
  }

  componentDidMount() {
     console.log("componenetDidMount");
     //this.setState( {  latitude: parseFloat(this.props.latlng[0].toFixed(10)),
     //                  longitude: parseFloat(this.props.latlng[1].toFixed(10))
     //                  } )
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = e => {
    e.preventDefault();
    //console.log("LigplekCreateForm create met data: "+JSON.stringify(this.state));
    const conf = {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: new Headers({ "Content-Type": "application/json" })
    };
    const new_endpoint = protDomPort+this.props.endpoint;
    this.setState({loading: true});
    fetch(new_endpoint, conf)
         .then(response => { return response.json() } ) // create on server
         .then(response => { this.props.doLigplekCreate(response) }) // save the result locally
         .finally(() => { this.setState({loading: false}) })  // stop the spinner
  };

  renderSpinner() {
     if(this.state.loading) {
         return (
                    <Spinner color="primary" />
            )
     }
  }

  render() { return (
              <RSForm name="createLigplek" id="id_createLigplek"
                    onSubmit={this.handleSubmit} >
               <FormGroup>
                <Label for="id_lat">Lat: </Label>
                <Input type="text" value={this.state.latitude} name="latitude" id="id_lat" readOnly />
                <Label for="id_lng">Lng: </Label>
                <Input type="text" value={this.state.longitude} name="longitude" id="id_lng" readOnly />
               </FormGroup>

               <FormGroup>
                <Label for="id_name">Naam: </Label>
                <Input type="text" name="name" id="id_name" onChange={this.handleChange}/>
               </FormGroup>


               <FormGroup>
                  <Label for="exampleSelect">Categorie</Label>
                  <CategorieDropdown onChange={this.handleChange}/>

                  <Label for="id_description">Korte beschrijving: </Label>
                  <Input id="id_description" type="text" name="description" onChange={this.handleChange} />

                  <Label for="id_description">Lange beschrijving: </Label>
                  <Input id="id_description_long" type="textarea" name="description_long" onChange={this.handleChange} />
               </FormGroup>

               <div className="control">
                  <Button type="submit"
                          className="button is-info"
                          disabled={this.state.loading} >Verstuur Gegevens</Button>
                  {this.renderSpinner()}
               </div>

              </RSForm>
              )

  }
}

class BaseUpdateForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleResponse = this.handleResponse.bind(this);
  }

  handleResponse(response)  {
        //console.log(response.status); // Will show you the status
        let _tmp_element = document.getElementById("ligplek_update_result");
        const d = new Date();
        const now = d.toLocaleTimeString();
        if(response.status == '201') {
            _tmp_element.insertAdjacentHTML('afterbegin', '<span>'+now+' Aanvulling ontvangen.</span>');
        } else {
            const error_msg = '<span>'+now+' Aanvulling NIET ontvangen. ('+response.status+" "+response.statusText+')</span>'
            _tmp_element.insertAdjacentHTML('afterbegin', error_msg);
        }
        if (!response.ok) {
            // response !OK maakt dat de spnner niet verdwijnt
            // throw new Error("HTTP status " + response.status);
            console.error("HTTP status " + response.status);
            return []
        }
        return response.json()
  }


}

class LigplekUpdateForm extends BaseUpdateForm {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    comment: null,
    loading: false
  }

  static propTypes = {
    endpoint: PropTypes.string.isRequired,
    ligplekId: PropTypes.number.isRequired,
  }

  componentDidMount() {
     this.setState( { ligplek: this.props.ligplekId, })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = e => {
    e.preventDefault()
    const conf = {
      method: "post",
      body: JSON.stringify(this.state),
      headers: new Headers({ "Content-Type": "application/json" })
    };
    const new_endpoint = protDomPort+this.props.endpoint;
    this.setState({loading: true});
    fetch(new_endpoint, conf)
         .then(response =>  { return this.handleResponse(response) })
         .then(response => { // this.setState({loading: false});
                             document.getElementById("id_createLigplekUpdate").reset(); })
         .finally(() => { this.setState({loading: false}) });
  };

  renderSpinner() {
     if(this.state.loading) {
         return ( <Spinner color="primary" /> )
     }
  }

  render() {
   return (
    <RSForm name="createLigplekUpdate" id="id_createLigplekUpdate" onSubmit={this.handleSubmit}>
        <Label>Algemene info</Label>
        <Input type="textarea" name="comment" rows="10" required
               onChange={this.handleChange}
               placeholder="Aanpassingen voor naam, beschrijving, diepgang of zaken die niet op een andere manier aangebracht kunnen worden."/ >
        <div className="control">
          <Button type="submit" className="button is-info" >Verstuur Gegevens</Button>
          {this.renderSpinner()}
        </div>
    </RSForm>
  )}
}


class LigplekImgForm extends BaseUpdateForm {

  constructor(props) {
    super(props)
    this.fileChangedHandler = this.fileChangedHandler.bind(this)
    this.checkValidForm = this.checkValidForm.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  state = {
     img: null,
     caption: null,
     ligplek: null,
     loading: false
  }

  static propTypes = {
    endpoint: PropTypes.string.isRequired,
    ligplekId: PropTypes.number.isRequired,
  }

  componentDidMount() {
     this.setState( {  ligplek: this.props.ligplekId,})
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    // this.checkValidForm()
  }

  fileChangedHandler = (event) => {
    this.setState({img: event.target.files[0],})
  }

  checkValidForm() {
    var button = document.querySelector('button[type="submit"]');
    //console.log("button", button);
    button.disabled=this.state.loading; //false;
    button.removeAttribute("disabled");
    button.classList.remove("disabled");
  }


  handleSubmit = e => {
    e.preventDefault()
    var data = new FormData()
    const { ligplek, caption, img } = this.state
    data.append('ligplek', ligplek)
    data.append('caption', caption)
    data.append('img', img, img.name)
    const conf = {
      method: "POST",
      body: data
    };
    var new_endpoint = protDomPort+this.props.endpoint;
    // console.log("new_endpoint: "+new_endpoint);
    this.setState({loading: true});
    fetch(new_endpoint, conf)
         .then(response =>  { return this.handleResponse(response) })
         .then(response => { document.getElementById("id_createLigplekImage").reset(); })
         .finally(() => { this.setState({loading: false}) } );
  };

  renderSpinner() {
     if(this.state.loading) {
         return (
                    <Spinner color="primary" />
            )
     }
  }

  render() { return (
                  <RSForm name="createLigplekImage" id="id_createLigplekImage"
                        onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <Label>Afbeelding</Label>
                      <Input type="file" name="img" onChange={this.fileChangedHandler}/>
                    </FormGroup>
                    <FormGroup>
                      <Label>Onderschrift</Label>
                      <Input type="text" name="caption" onChange={this.handleChange} />
                    </FormGroup>
                    <div className="control">
                      <Button type="submit" id="id_button_submit" className="button is-info" >Verstuur Gegevens</Button>
                      {this.renderSpinner()}
                    </div>
                  </RSForm>
  )}
}


class LigplekKarakterestiekForm extends BaseUpdateForm {

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  static propTypes = {
    endpoint: PropTypes.string.isRequired,
    ligplekId: PropTypes.number.isRequired,
  }

  state = {
    ligplek: null,
    karakterestiek: null,
    description: null,
    loading: false
  }

  componentDidMount() {
     this.setState( { ligplek: this.props.ligplekId,} )
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = e => {
    e.preventDefault()
    const conf = {
      method: "post",
      body: JSON.stringify(this.state),
      headers: new Headers({ "Content-Type": "application/json" }),
    };
    var new_endpoint = protDomPort+this.props.endpoint;
    this.setState({loading: true});
    fetch(new_endpoint, conf)
         .then((response) =>  { return this.handleResponse(response) })
         .then(response => { document.getElementById("id_createLigplekKarakterestiek").reset() })
         .finally(() => { this.setState({loading: false}) });
  };

  renderSpinner() {
     if(this.state.loading) {
         return (<Spinner color="primary" />)
     }
  }

  render() { return (
                 <RSForm  name="createLigplekKarakterestiek"
                        id="id_createLigplekKarakterestiek"
                        onSubmit={this.handleSubmit}>
                    <Label>Karakterestiek</Label>
                    <KarakterestiekDropdown name="karakterestiek"
                                            handleChange={this.handleChange} />
                    <Label>Waarde</Label><Input type="text" name="description" required
                                                onChange={this.handleChange} />
                    <div className="control">
                      <Button type="submit" className="button is-info">Verstuur Gegevens</Button>
                      {this.renderSpinner()}
                    </div>
                 </RSForm>
  )}

}


class LigplekWebsiteForm extends BaseUpdateForm {

  static propTypes = {
     endpoint: PropTypes.string.isRequired,
     ligplekId: PropTypes.number.isRequired,
  }

  state = {
    ligplek: 0,
    url: null,
    caption: "",
    loading: false
  }

  componentDidMount() {
     this.setState( {  ligplek: this.props.ligplekId,})
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = e => {
    e.preventDefault()
    const conf = {
      method: "post",
      body: JSON.stringify(this.state), // ligplek_website),
      headers: new Headers({ "Content-Type": "application/json" })
    };
    var new_endpoint = protDomPort+this.props.endpoint;
    this.setState({loading: true});
    fetch(new_endpoint, conf)
         .then(response => { return this.handleResponse(response) })
         .then(response => { this.setState({loading: false});
                             document.getElementById("id_createLigplekWebsite").reset(); });
  };

  renderSpinner() {
     if(this.state.loading) {
         return <Spinner color="primary" />
     }
  }

  render() { return (
                <RSForm name="createLigplekWebsite" id="id_createLigplekWebsite"
                      onSubmit={this.handleSubmit} >
                    <FormGroup>
                       <Label>Website Url</Label>
                       <Input type="url" name="url" onChange={this.handleChange} /></FormGroup>
                    <FormGroup>
                      <Label>Beschrijving</Label>
                      <Input type="text" name="caption" onChange={this.handleChange} /></FormGroup>

                    <div className="control">
                      <Button type="submit"  className="button is-info" >Verstuur Gegevens</Button>
                      {this.renderSpinner()}
                    </div>
                </RSForm>
  )}
}


module.exports = {
  LigplekCreateForm,
  LigplekWebsiteForm,
  LigplekKarakterestiekForm,
  LigplekImgForm,
  LigplekUpdateForm,

  ProfileUpdateForm: ProfileForm
}
