import * as settingsActions from '../actions/SettingsActions';


const initialState = {
    order: 1,
    draft_filter: 0,
    distance_filter: 9999,
    zoom_manual: 10,
    zoom_external: 14
}


const SettingsReducer= (state = initialState, action) => {
  // console.log("SettingsReducer: "+action.type);
  let _settings = null;
  switch (action.type) {

    case settingsActions.RECEIVE_SETTINGS:
      _settings = action.settings;
      // console.log("_settings: "+_settings);
      if(_settings.length ==0) {
         return state;
      }
      return Object.assign({},
                           state,
                           // {settings: _settings}
                           _settings
                           )

    case settingsActions.STORE_SETTINGS:
      localStorage.setItem('lpowSettings', JSON.stringify(state));
      return Object.assign({},
                           state,
                           {}
                           )

    case settingsActions.SET_SETTING_DRAFT:
      return Object.assign({},
                           state,
                           {draft_filter: action.value}
                           )

    case settingsActions.SET_LIGPLEK_LENGTH_FILTER:
      return Object.assign({},
                           state,
                           {ligplek_length: action.value}
                           )

    case settingsActions.SET_SETTING_DISTANCE:
      return Object.assign({},
                           state,
                           {distance_filter: action.value}
                           )

    case settingsActions.SET_SETTING_SHIP_LENGTH:
      return Object.assign({},
                           state,
                           {ship_length_filter: action.value}
                           )

    case settingsActions.SET_LIGPLEK_ORDER:
      return Object.assign({},
                           state,
                           {order: action.value}
                           )

    case settingsActions.SET_SETTING_ZOOM_MANUAL:
      return Object.assign({},
                       state,
                       {zoom_manual: action.value}
                       )

    case settingsActions.SET_SETTING_ZOOM_EXTERNAL:
      return Object.assign({},
                           state,
                           {zoom_external: action.value}
                           )

    default:
      return state
    }

}

export default SettingsReducer
