import fetch from 'cross-fetch'
import {getCookie, } from "../components/LpowUtils"
import {Auth, } from "../components/Auth"
import {protDomPort, requestMode, siteDomain, fetchData } from './LpowActions'

export const VIEW_ADOPTED_LIGPLEKKEN = 'VIEW_ADOPTED_LIGPLEKKEN';
export const REQUEST_ADOPTED_LIGPLEKKEN = 'REQUEST_ADOPTED_LIGPLEKKEN_FROM_SERVER';
export const RECEIVE_ADOPTED_LIGPLEKKEN = 'RECEIVE_ADOPTED_LIGPLEKKEN_FROM_SERVER';
export const REQUEST_ADOPTED_LIGPLEKKEN_FAIL = 'REQUEST_ADOPTED_LIGPLEKKEN_FROM_SERVER_FAIL';
export const CLEAR_ADOPTED_LIGPLEKKEN = 'CLEAR_ADOPTED_LIGPLEKKEN';
export const REQUEST_ADOPT_LIGPLEK = 'REQUEST_ADOPT_LIGPLEK';
export const ADOPT_LIGPLEK_SUCCES = 'ADOPT_LIGPLEK_SUCCES';
export const ADOPT_LIGPLEK_FAIL = 'ADOPT_LIGPLEK_FAIL';
export const REQUEST_REMOVE_LIGPLEK_ADOPT = 'REQUEST_REMOVE_LIGPLEK_ADOPT';
export const REMOVE_LIGPLEK_ADOPT_FAIL = 'REMOVE_LIGPLEK_ADOPT_FAIL';
export const REMOVE_LIGPLEK_ADOPT_SUCCES = 'REMOVE_LIGPLEK_ADOPT_SUCCES';

export function requestAdoptLigplek(id) {
  return { type: REQUEST_ADOPT_LIGPLEK,
           id,
           requestedAdoptAt: Date.now(),
         }
}

export function receiveAdoptLigplekSucces(result) {
  return { type: ADOPT_LIGPLEK_SUCCES,
           result,
         }
}

export function receiveAdoptLigplekFail(error) {
  return { type: ADOPT_LIGPLEK_FAIL,
           error,
         }
}

export function requestRemoveLigplekAdoption(id) {
  return { type: REQUEST_REMOVE_LIGPLEK_ADOPT,
           id,
           requestedAt: Date.now(),
         }
}

export function receiveRemoveLigplekAdoptSucces(result) {
  return { type: REMOVE_LIGPLEK_ADOPT_SUCCES,
           result,
         }
}

export function receiveRemoveLigplekAdoptFail(error) {
  return { type: REMOVE_LIGPLEK_ADOPT_FAIL,
           error,
         }
}

export function viewAdoptedLigplekken() {
  return { type: VIEW_ADOPTED_LIGPLEKKEN

         }
}

export function clearAdoptedLigplekken() {
  return { type: CLEAR_ADOPTED_LIGPLEKKEN,
           receivedAt: Date.now(),
         }
}

export function requestAdoptedLigplekken() {
  return { type: REQUEST_ADOPTED_LIGPLEKKEN,
           receivedAt: Date.now(),
         }
}

export function requestAdoptedLigplekkenSucces( ligplekken ) {
  return { type: RECEIVE_ADOPTED_LIGPLEKKEN,
           receivedAt: Date.now(),
           ligplekken
         }
}

export function requestAdoptedLigplekkenFail( error ) {
  return { type: REQUEST_ADOPTED_LIGPLEKKEN_FAIL,
           receivedAt: Date.now(),
           ligplekken
         }
}


export function doAdoptLigplek(ligplekId, token_key) {
  return function (dispatch) {
      const requestOptions = {
            method: 'PUT',
            mode: requestMode,
            headers: { 'Accept': 'application/json',
                       'Content-Type': 'application/json',
                       'Authorization': 'Token '+token_key
                     },
            body: JSON.stringify({ adopted_by: '1'}) // user will be resoved by the Token
      };
      dispatch(requestAdoptLigplek(ligplekId));
      return fetch(protDomPort+"/lpow/api/ligplek/"+ligplekId+"/adopt/", requestOptions)
            .then(
                  (response) => {console.log("doAdoptLigplek "+response);
                                 if (response.status == 200)  {
                                     response.json().then(function(data) {
                                       dispatch(receiveAdoptLigplekSucces(data))
                                     })
                                 } else {
                                     response.json().then(function(data) {
                                       dispatch(receiveAdoptLigplekFail(data))
                                     })
                                 }
                              },
                  error => dispatch(receiveLoginFail(error))
                  // error => dispatch(receiveAdoptLigplekFail(error))
            )
  }

}

export function removeLigplekAdoption(ligplekId, token_key) {
  return function (dispatch) {
      const requestOptions = {
            method: 'DELETE',
            mode: requestMode,
            headers: { 'Accept': 'application/json',
                       'Content-Type': 'application/json',
                       'Authorization': 'Token '+token_key
                     },
            body: JSON.stringify({ adopted_by: '1'}) //
      };
      dispatch(requestRemoveLigplekAdoption(ligplekId));
      return fetch(protDomPort+"/lpow/api/ligplek/"+ligplekId+"/adopt-remove/", requestOptions)
            .then(
                  (response) => {console.log("doRemoveLigplekAdopt "+response);

                                 if (response.status == 200)  {
                                     response.json().then(function(data) {
                                       dispatch(receiveRemoveLigplekAdoptSucces(data))
                                     })
                                 } else {
                                     response.json().then(function(data) {
                                       dispatch(receiveRemoveLigplekAdoptFail(data))
                                     })
                                 }
                              },
                  error => dispatch(receiveLoginFail(error))
            )
  }

}


export function getAdoptedLigplekken(token) {
    const requestOptions = {
        method: 'GET',
        mode: requestMode,
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   // 'X-CSRFToken': csrftoken,
                   'Authorization': 'Token '+token  // + Auth.getAuthToken()
                 },
    };

    return function (dispatch) {
      // inform that the API call is starting.
      // Do not use catch,... https://github.com/facebook/react/issues/6895
      dispatch(requestAdoptedLigplekken())
      return fetch(protDomPort+'/lpow/api/adopted-ligplek/', requestOptions)
            .then((response) =>  {
                                  console.log("getAdoptedLigplekken "+response);
                                  if (response.status == 200)  {
                                     response.json().then(function(data) {
                                       dispatch(requestAdoptedLigplekkenSucces(data))
                                     })
                                  } else {
                                     // alle statussen anders dan 200 ijn op het moment 'fout'
                                     // bv 401, de token is niet goed meer

                                     response.json().then(function(data) {
                                       dispatch(requestAdoptedLigplekkenFail(data))
                                     })
                                  }
                                 },
                  error => {
                            console.log('An error occurred.', error);
                            dispatch(getAdoptedLigplekkenFail(error));
                            }
                  )
  }

}

export function fetchAdoptedLigplekken() {

  return function (dispatch) {
      // console.log("fetchAdoptedLigplekken van local Storage");
      let ligplekken = localStorage.getItem('lpowAdoptedLigplekken');
      try { ligplekken = JSON.parse(ligplekken);
            dispatch(receiveAdoptedLigplekken(ligplekken));
      } catch (e) {
         localStorage.removeItem('lpowAdoptedLigplekken');
         console.error(e);
      }
      localStorage.removeItem('lpowAdoptedLigplekken');
  }

}
