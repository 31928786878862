import { connect } from 'react-redux'
import {AuthModule, Register, LoginWithPassw, LoginNoPassw, Profile } from "../components/Auth";


const mapStateToPropsAuthModule = state => {
    // console.log("AuthModuleContainer mapStateToProps: ", state.lpowState)
    let showProfile = false;
    let showLogin = true;
    let showRegister = false;
    let _auth = state.auth;
    if(_auth.token_key) {
        showProfile = true,
        showLogin = false
    }
    return {
          token_key: _auth.token_key,
          registration_error: _auth.registration_error,
          registration_succes: _auth.registration_succes,
          pwd_reset_request_succes: _auth.pwd_reset_request_succes,
          pwd_reset_request_error: _auth.pwd_reset_request_error,
          login_error: _auth.login_error,
          logout_error: _auth.logout_error,
          showProfile,
          showLogin,
          showRegister,
          loginValid: false,
    }
}

const mapDispatchToPropsAuthModule = (dispatch) => {
    return {
      dispatch,
    }
}

const AuthModuleContainer = connect(
  mapStateToPropsAuthModule,
  mapDispatchToPropsAuthModule
)(AuthModule)



// ####################################################
const mapStateToPropsRegister = state => {
    // console.log("RegisterContainer mapStateToProps: ", state.lpowState)
    return {
          foo: "Bar",
    }
}

const mapDispatchToPropsRegister = (dispatch) => {
    return {
      dispatch,
    }
}

const RegisterContainer = connect(
  mapStateToPropsRegister,
  mapDispatchToPropsRegister
)(Register)




// ####################################################
const mapStateToPropsLoginWithPassw = state => {
    // console.log(" mapStateToProps: ", state);
    return {
          foo: "Bar2"
    }
}

const mapDispatchToPropsLoginWithPassw = (dispatch) => {
    // console.log(" mapDispatchToPropsLoginWithPassw: " );
    return {
      dispatch,
    }
}

const LoginWithPasswContainer = connect(
  mapStateToPropsLoginWithPassw,
  mapDispatchToPropsLoginWithPassw
)(LoginWithPassw)


// ####################################################
const mapStateToPropsLoginNoPassw = state => {
    //console.log("LoginNoPasswContainer mapStateToProps: ", state);
    return {
          foo: "Bar2"
    }
}

const mapDispatchToPropsLoginNoPassw = (dispatch) => {
    return {
      dispatch,
    }
}

const LoginNoPasswContainer = connect(
  mapStateToPropsLoginNoPassw,
  mapDispatchToPropsLoginNoPassw
)(LoginNoPassw)






// export default AuthContainer;
module.exports = {
    AuthModuleContainer,
    RegisterContainer: RegisterContainer,
    LoginWithPasswContainer: LoginWithPasswContainer,
    LoginNoPasswContainer: LoginNoPasswContainer,
}
