import fetch from 'cross-fetch'
import { getCookie, } from "../components/LpowUtils"
import {Auth, } from "../components/Auth"

import { updateCurrentPosition } from "./PositionActions"
import { setZoomLevel } from "./MapActions"
import {CREATE_LIGPLEK, } from './ModalLigplekActions'

const window_location = ""+window.location;
const isDev = window_location.startsWith("http://localhost:800") ? true : false;
const backendDev = window_location.startsWith("http://localhost:8001") ? true : false;
console.log("isDev:"+isDev+"     backendDev:"+backendDev)
// export const siteDomain = (isDev&&!backendDev) ? "ligplekkenonderweg.lvbhb.nl" : "lpow.localhost";
// export const siteDomain = (isDev||!backendDev) ? "ligplekkenonderweg.steckelfisch.nl" : "lpow.localhost";
// export const siteDomain = (isDev||!backendDev) ? "ligplekkenonderweg.lvbhb.nl" : "lpow.localhost";
export const siteDomain = "ligplekkenonderweg.lvbhb.nl";
// export const protDomPort = backendDev ?  'http://lpow.localhost:8000' : 'https://'+siteDomain;
export const protDomPort =  'https://'+siteDomain;
export const requestMode = isDev ? 'cors' : 'same-origin';

//export const protDomPort = 'http://lpow.localhost:8000';
console.log("protDomPort: "+protDomPort);

/*
 * action types
 */

export const REQUEST_LIGPLEKKEN = 'REQUEST_LIGPLEKKEN_FROM_SERVER';
export const RECEIVE_LIGPLEKKEN = 'RECEIVE_LIGPLEKKEN_FROM_SERVER';

export const REQUEST_CATEGORIEEN = 'REQUEST_CATEGORIEEN_FROM_SERVER';
export const RECEIVE_CATEGORIEEN = 'RECEIVE_CATEGORIEEN_FROM_SERVER';

// Dit is voor tonen spul op /Categorie en /Vaarweg
export const SHOW_CATEGORIE_LIGPLEKLIST = 'TOGGLE_SHOW_CATEGORIE_LIGPLEKLIST';
export const DISPLAY_CATEGORIE = 'DISPLAY_CATEGORIE';
export const SHOW_VAARWEG_LIGPLEKLIST = 'TOGGLE_SHOW_VAARWEG_LIGPLEKLIST';
export const VIEW_CREATED_LIGPLEKKEN = 'VIEW_CREATED_LIGPLEKKEN';
export const HIDE_CREATED_LIGPLEKKEN = 'HIDE_CREATED_LIGPLEKKEN';
// export const SHOW_ADOPT_LIGPLEK = 'SHOW_ADOPT_LIGPLEK';


export const SAVE_CREATED_LIGPLEK = 'SAVE_CREATED_LIGPLEK';


export const REQUEST_VAARWEGEN = 'REQUEST_VAARWEGEN_FROM_SERVER';
export const RECEIVE_VAARWEGEN = 'RECEIVE_VAARWEGEN_FROM_SERVER';
export const DISPLAY_VAARWEG = 'DISPLAY_VAARWEG';

export const REQUEST_KARAKTERESTIEKEN = 'REQUEST_KARAKTERESTIEKEN_FROM_SERVER';
export const RECEIVE_KARAKTERESTIEKEN = 'RECEIVE_KARAKTERESTIEKEN_FROM_SERVER';
export const REQUEST_FAVOURITE_LIGPLEKKEN = 'REQUEST_FAVOURITE_LIGPLEKKEN_FROM_SERVER';
export const RECEIVE_FAVOURITE_LIGPLEKKEN = 'RECEIVE_FAVOURITE_LIGPLEKKEN_FROM_SERVER';

export const REQUEST_SELECTED_LIGPLEKKEN = 'REQUEST_SELECTED_LIGPLEKKEN_FROM_SERVER';
export const RECEIVE_SELECTED_LIGPLEKKEN = 'RECEIVE_SELECTED_LIGPLEKKEN_FROM_SERVER';
export const REQUEST_SELECTED_CATEGORIEEN = 'REQUEST_SELECTED_CATEGORIEEN_FROM_SERVER';
export const RECEIVE_SELECTED_CATEGORIEEN = 'RECEIVE_SELECTED_CATEGORIEEN_FROM_SERVER';
export const REQUEST_SELECTED_VAARWEGEN = 'REQUEST_SELECTED_VAARWEGEN_FROM_SERVER';
export const RECEIVE_SELECTED_VAARWEGEN = 'RECEIVE_SELECTED_VAARWEGEN_FROM_SERVER';

export const REQUEST_CREATED_LIGPLEKKEN = 'REQUEST_CREATED_LIGPLEKKEN';
export const RECEIVE_CREATED_LIGPLEKKEN = 'RECEIVE_CREATED_LIGPLEKKEN';

export const VIEW_ADOPTED_LIGPLEKKEN = 'VIEW_ADOPTED_LIGPLEKKEN';
export const REQUEST_ADOPTED_LIGPLEKKEN = 'REQUEST_ADOPTED_LIGPLEKKEN_FROM_SERVER';
export const RECEIVE_ADOPTED_LIGPLEKKEN = 'RECEIVE_ADOPTED_LIGPLEKKEN_FROM_SERVER';
export const REQUEST_ADOPT_LIGPLEK = 'REQUEST_ADOPT_LIGPLEK';
export const ADOPT_LIGPLEK_SUCCES = 'ADOPT_LIGPLEK_SUCCES';
export const ADOPT_LIGPLEK_FAIL = 'ADOPT_LIGPLEK_FAIL';

export const TOGGLE_SELECT_LIGPLEK = 'TOGGLE_SELECT_LIGPLEK';
export const TOGGLE_FAVOURITE_LIGPLEK = 'TOGGLE_FAVOURITE_LIGPLEK';

export const SET_REFRESH_DATA = "SET_REFRESH_DATA";


export const DISPLAY_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';



/*
 * action creators
 */


export function requestKarakterestieken() {
  return { type: REQUEST_KARAKTERESTIEKEN,
           requestedAt: Date.now(),
         }
}

export function receiveKarakterestieken(json) {
  return { type: RECEIVE_KARAKTERESTIEKEN,
           karakterestieken: json,
           receivedAt: Date.now(),
         }
}

export function requestLigplekken() {
  return { type: REQUEST_LIGPLEKKEN,
           requestedAt: Date.now(),
         }
}

export function receiveLigplekken(json) {
  return { type: RECEIVE_LIGPLEKKEN,
           ligplekken: json,
           receivedAt: Date.now(),
         }
}

export function requestFavouriteLigplekken() {
  return { type: REQUEST_FAVOURITE_LIGPLEKKEN,
           receivedAt: Date.now(),
         }
}

export function receiveFavouriteLigplekken(json) {
  return { type: RECEIVE_FAVOURITE_LIGPLEKKEN,
           favourite_ligplekken: json,
           receivedAt: Date.now(),
         }
}


export function receiveSelectedLigplekken(json) {
  return { type: RECEIVE_SELECTED_LIGPLEKKEN,
           selected_ligplekken: json,
           receivedAt: Date.now(),
         }
}


export function receiveCreatedLigplekken(json) {
  return { type: RECEIVE_CREATED_LIGPLEKKEN,
           ligplekken: json,
           receivedAt: Date.now(),
         }
}



export function requestAdoptedLigplekken() {
  return { type: REQUEST_ADOPTED_LIGPLEKKEN,
           receivedAt: Date.now(),
         }
}

export function receiveAdoptedLigplekken(json) {
  return { type: RECEIVE_ADOPTED_LIGPLEKKEN,
           adopted_ligplekken: json,
           receivedAt: Date.now(),
         }
}

export function receiveSelectedCategorieen(json) {
  return { type: RECEIVE_SELECTED_CATEGORIEEN,
           selected_categorieen: json,
           receivedAt: Date.now(),
         }
}

export function receiveSelectedVaarwegen(json) {
  return { type: RECEIVE_SELECTED_VAARWEGEN,
           selected_vaarwegen: json, // dit moet een array zijn. Geen Ovject
           receivedAt: Date.now(),
         }
}


export function requestCategorieen() {
  return { type: REQUEST_CATEGORIEEN,
           requestedAt: Date.now(),
         }
}


export function receiveCategorieen(json) {
  return { type: RECEIVE_CATEGORIEEN,
           categorieen: json,
           receivedAt: Date.now(),
         }
}


export function requestVaarwegen() {
  return { type: REQUEST_VAARWEGEN,
           requestedAt: Date.now(),
         }
}


export function receiveVaarwegen(json) {
  return { type: RECEIVE_VAARWEGEN,
           vaarwegen: json,
           receivedAt: Date.now()
         }
}


export function createLigplek(latlng) {
  return { type: CREATE_LIGPLEK,
           isOpen: true,
           latlng
         }
}

export function saveCreatedLigplek(ligplekjson) {
  return { type: SAVE_CREATED_LIGPLEK,
           ligplekjson,
         }
}


export function requestLigplekImages(ligplek_id) {
  return { type: REQUEST_LIGPLEK_IMAGES,
           id: ligplek_id,
         }
}

export function receiveLigplekimages() {
  return { type: RECEIVE_LIGPLEK_IMAGES,
         }
}



/**
 *  Tonen van stuff op de Map
 */

export const TOGGLE_CATEGORIE_ON_MAP = 'TOGGLE_CATEGORIE_ON_MAP';
export const SHOW_CATEGORIE_ON_MAP = 'SHOW_CATEGORIE_ON_MAP';
export const HIDE_CATEGORIE_ON_MAP = 'HIDE_CATEGORIE_ON_MAP';

export const TOGGLE_VAARWEG_ON_MAP = 'TOGGLE_VAARWEG_ON_MAP';
export const SHOW_VAARWEG_ON_MAP = 'SHOW_VAARWEG_ON_MAP';
export const HIDE_VAARWEG_ON_MAP = 'HIDE_VAARWEG_ON_MAP';

export function toggleCategorieOnMap(categorie_id) {
  return { type: TOGGLE_CATEGORIE_ON_MAP,
           categorie_id,
         }
}

export function showCategorieOnMap(categorie_id) {
  return { type: SHOW_CATEGORIE_ON_MAP,
           categorie_id,
         }
}

export function hideCategorieOnMap(categorie_id) {
  return { type: HIDE_CATEGORIE_ON_MAP,
           categorie_id,
         }
}

export function toggleVaarwegOnMap(vaarweg_id) {
  return { type: TOGGLE_VAARWEG_ON_MAP,
           vaarweg_id,
         }
}

export function showVaarwegOnMap(vaarweg_id) {
  return { type: SHOW_VAARWEG_ON_MAP,
           vaarweg_id,
         }
}

export function hideVaarwegOnMap(vaarweg_id) {
  return { type: hide_VAARWEG_ON_MAP,
           vaarweg_id,
         }
}

/**
 *  Einde Tonen van stuff op de Map
 */



/**
 *  Tonen van stuff in de /Categorie /Vaarweg pagina's
 */

export function showCategorieLigplekList(categorie_id) {
  // console.log("Action toggle categorie_id: "+categorie_id);
  return { type: SHOW_CATEGORIE_LIGPLEKLIST,
           categorie_id
         }
}
export function hideCategorieLigplekList(categorie_id) {
  // console.log("Action  toggle categorie_id: "+categorie_id);
  return { type: HIDE_CATEGORIE_LIGPLEKLIST,
           categorie_id
         }
}


export function showVaarwegLigplekList(vaarweg_id) {
  // console.log("Action show vaarweg_id: "+vaarweg_id);
  return { type: SHOW_VAARWEG_LIGPLEKLIST,
           vaarweg_id
         }
}
export function hideVaarwegLigplekList(vaarweg_id) {
  console.log("Action hide vaarweg_id: "+vaarweg_id);
  return { type: HIDE_VAARWEG_LIGPLEKLIST,
           vaarweg_id
         }
}


export function fetchLigplek(id) {

  return function (dispatch) {
      dispatch(requestLigplek(id))
      return fetch(protDomPort+"/lpow/api/ligplek/"+id+"/")
            .then(
                  response => response.json(),
                  // Do not use catch,... https://github.com/facebook/react/issues/6895
                  error => console.log('Ah... an error occurred.', error)
            )
            .then(
                // followup action
                json=> dispatch(receiveLigplek(json))
            )
  }

}



export function toggleFavouriteLigplek(id) {
  return { type: TOGGLE_FAVOURITE_LIGPLEK,
           id,
         }
}

export function toggleSelectLigplek(id) {
  return { type: TOGGLE_SELECT_LIGPLEK,
           id,
         }
}

export function clearAuthAndProfile() {
  return function (dispatch) {
      console.info("Authenticatie en Profile verwijderen");
      dispatch(clearAuthentication());
      dispatch(clearProfile());

  }
}

function fetchLigplekImages() {
}
function fetchLigplekKarakterestieken() {
}
function fetchLigplekWebsites() {
}



export function fetchLocation() {
  return function (dispatch) {
    dispatch(requestLocation());
  }
}

export function fetchLigplekken() {

  return function (dispatch) {
      console.info("ligplekken updaten vanaf de server");
      dispatch(requestLigplekken())
      let lpowDateLastUpdate = localStorage.getItem('lpowDateLastUpdate');
      console.log("lpowDateLastUpdate: "+lpowDateLastUpdate);
      var query_filter = "";
      try {
          if(lpowDateLastUpdate) { // } & lpowLigplekken.length>0) {
              let date_to_filter = new Date(parseInt(lpowDateLastUpdate));
              let date_filter = date_to_filter.toISOString().substring(0, 10);
              query_filter = "?date="+date_filter;
              console.log("query_filter: "+query_filter);
          }
      } catch(e) {
         console.error("Zetten ligplekfitler ging fout. Geen Filter. Alle ligplekken halen. "+e);
         query_filter = "";
      }
      return fetch(protDomPort+'/lpow/api/ligplek-list/'+query_filter)
            .then(
                  response => response.json(),
                  // Do not use catch,... https://github.com/facebook/react/issues/6895
                  error => console.log('An error occurred.', error)
            )
            .then(
                json => {console.log("fetchLigplekken json: "+json)
                         dispatch(receiveLigplekken(json));
                         //console.log("ligplekken zijn gefetched, nu de rest laden");
                         dispatch(fetchVaarwegenAndCategorieen());
                         dispatch(fetchSelectedCategorieen);
                         dispatch(fetchSelectedVaarwegen);
                         console.info("Fix!\nChrome klaagt met [Violation] Only request geolocation information in response to a user gesture.\nFIX!!")
                         dispatch(updateCurrentPosition());
                         dispatch(setZoomLevel(10))
                       }
            )


      }
  }


export function fetchFavouriteLigplekken() {

  return function (dispatch) {
      // initial action
      var lpowLigplekken = localStorage.getItem('lpowFavouriteLigplekken');
      // console.log("lpowLigplekken: ", lpowLigplekken)
      if (lpowLigplekken != null) { // Using the locally stored data
          dispatch(receiveFavouriteLigplekken(JSON.parse(lpowLigplekken)));
      } else {
          // for now:
          // initialize an empty favourite list.
          // if we are logged in, a serverside stored favourite list could be retrieved
          const json= []
          localStorage.setItem('lpowFavouriteLigplekken', JSON.stringify(json));
          dispatch(receiveFavouriteLigplekken(json));
          /* inform that the API call is starting.
          dispatch(requestFavouriteLigplekken())
          return fetch('///'+siteDomain+'/lpow/api/favourite-ligplek-list/')
                .then(
                      response => response.json(),
                      // Do not use catch,... https://github.com/facebook/react/issues/6895
                      error => console.log('An error occurred.', error)
                )
                .then(
                    // followup action
                    json=> { localStorage.setItem('lpowFavouriteLigplekken', JSON.stringify(json));
                             dispatch(receiveFavouriteLigplekken(json)) }
                )
          }
          */
      }
  }

}



export function fetchSelectedLigplekken() {

  return function (dispatch) {
      // initial action
      var lpowSelectedLigplekken = localStorage.getItem('lpowSelectedLigplekken');
      try { lpowSelectedLigplekken = JSON.parse(lpowSelectedLigplekken); }
      catch (e) { console.error(e); lpowSelectedLigplekken = null; }
      if (lpowSelectedLigplekken) { // Using the locally stored data
          dispatch(receiveSelectedLigplekken(lpowSelectedLigplekken));
      }
      else {
          // for now:
          // initialize an empty favourite list.
          // if we are logged in, a serverside stored favourite list could be retrieved
          const json= []
          localStorage.setItem('lpowSelectedLigplekken', JSON.stringify(json));
          dispatch(receiveSelectedLigplekken(json));
          /* inform that the API call is starting.
          dispatch(requestSelectedLigplekken())
          return fetch('///'+siteDomain+'/lpow/api/selected-ligplek-list/')
                .then(
                      response => response.json(),
                      // Do not use catch,... https://github.com/facebook/react/issues/6895
                      error => console.log('An error occurred.', error)
                )
                .then(
                    // followup action
                    json=> { localStorage.setItem('lpowFavouriteLigplekken', JSON.stringify(json));
                             dispatch(receiveFavouriteLigplekken(json)) }
                )
          }
          */
      }
  }

}

export function fetchCreatedLigplekken() {

  return function (dispatch) {
      // initial action
      let ligplekken = localStorage.getItem('lpowCreatedLigplekken');
      try { ligplekken = JSON.parse(ligplekken); }
      catch (e) { console.error(e); ligplekken = null; }
      if (ligplekken) { // Using the locally stored data
          dispatch(receiveCreatedLigplekken(ligplekken));
      }
      else {
          // for now:
          // initialize an empty favourite list.
          // if we are logged in, a serverside stored favourite list could be retrieved
          const json= []
          localStorage.setItem('lpowCreatedLigplekken', JSON.stringify(json));
          dispatch(receiveCreatedLigplekken(json));
          /* inform that the API call is starting.
          dispatch(requestCreatedLigplekken())
          return fetch('///'+siteDomain+'/lpow/api/created-ligplek-list/') // or adopted-ligplek-list
                .then(
                      response => response.json(),
                      // Do not use catch,... https://github.com/facebook/react/issues/6895
                      error => console.log('An error occurred.', error)
                )
                .then(
                    // followup action
                    json=> { localStorage.setItem('lpowCreatedLigplekken', JSON.stringify(json));
                             dispatch(receiveCreatedLigplekken(json)) }
                )
          }
          */
      }
  }

}




export function fetchSelectedVaarwegen() {

  return function (dispatch) {
      // initial action
      var lpowSelected = localStorage.getItem('lpowSelectedVaarwegen');
      try {
        lpowSelected = JSON.parse(lpowSelected);
      } catch (e) {
        console.error("Error fetching powSelected"+e);
        lpowSelected = null;
      }
      if (lpowSelected) { // Using the locally stored data
          console.log("fetchSelectedVaarwegen lpowSelected "+lpowSelected);
          dispatch(receiveSelectedVaarwegen(lpowSelected));
      }
      else {
          // for now:
          // initialize an empty favourite list.
          // if we are logged in, a serverside stored favourite list could be retrieved
          const json= [];
          localStorage.setItem('lpowSelectedVaarwegen', JSON.stringify(json));
          console.log("fetchSelectedVaarwegen json "+json);
          dispatch(receiveSelectedVaarwegen(json));
          /* inform that the API call is starting.
          dispatch(requestSelectedLigplekken())
          return fetch('///'+siteDomain+'/lpow/api/selected-ligplek-list/')
                .then(
                      response => response.json(),
                      // Do not use catch,... https://github.com/facebook/react/issues/6895
                      error => console.log('An error occurred.', error)
                )
                .then(
                    // followup action
                    json=> { localStorage.setItem('lpowFavouriteLigplekken', JSON.stringify(json));
                             dispatch(receiveFavouriteLigplekken(json)) }
                )
          }
          */
      }
  }
}

export function fetchSelectedCategorieen() {

  return function (dispatch) {
      // initial action
      var lpowSelected = localStorage.getItem('lpowSelectedCategorieen');
      try { lpowSelected = JSON.parse(lpowSelected); }
      catch (e) { console.error(e); lpowSelected = null; }
      if (lpowSelected) { // Using the locally stored data
          dispatch(receiveSelectedCategorieen(lpowSelected));
      }
      else {
          // for now:
          // initialize an empty favourite list.
          // if we are logged in, a serverside stored favourite list could be retrieved
          const json= [];
          localStorage.setItem('lpowSelectedCategorieen', JSON.stringify(json));
          dispatch(receiveSelectedCategorieen(json));
          /* inform that the API call is starting.
          dispatch(requestSelectedLigplekken())
          return fetch('///'+siteDomain+'/lpow/api/selected-ligplek-list/')
                .then(
                      response => response.json(),
                      // Do not use catch,... https://github.com/facebook/react/issues/6895
                      error => console.log('An error occurred.', error)
                )
                .then(
                    // followup action
                    json=> { localStorage.setItem('lpowFavouriteLigplekken', JSON.stringify(json));
                             dispatch(receiveFavouriteLigplekken(json)) }
                )
          }
          */
      }
  }
}





export function fetchVaarwegenAndCategorieen() {
  // Ververs alle data (refresh=true)
  return function (dispatch) {
            dispatch(fetchData("lpowCategorien",
                               protDomPort+"/lpow/api/categorie-list/",
                               requestCategorieen, receiveCategorieen,
                               true))
            dispatch(fetchData("lpowVaarwegen",
                               protDomPort+"/lpow/api/vaarweg-list/",
                               requestVaarwegen, receiveVaarwegen,
                               true))
  }
}


/**
  fetchen:
     - als refresh: localStorage schoonmaken, data ophalen van server.
     - als in localStarage, gebruik dat als cache.
     - als een fout in de cache: localStorage schoonmaken, en data ophalen van de server.
**/
export function fetchData(storageName, serverUrl,
                          requestAction, receiveAction,
                          refresh=false) {

  return function (dispatch) {

      if(refresh) {
         localStorage.removeItem( storageName );
      }

      let data = localStorage.getItem( storageName );
      try { // localstorage data may be corrupted
          data = JSON.parse(data);
      } catch (e) {
          // en de catch vangt dat op.
          console.error(e);
          data = null; // opnieuw ophalen van de server
      }
      if (data) {
          dispatch( receiveAction(data) )
      } else {
          dispatch( requestAction() );
          return fetch(serverUrl)
                .then(
                      response => response.json(),
                      error => console.log('FetchData: An error occurred.', error)
                )
                .then(
                    // followup action
                    json => { localStorage.setItem( storageName, JSON.stringify(json) );
                              dispatch(receiveAction(json));
                            }
                )
      }
  }

}

