export const SET_MAP_CENTER = 'SET_MAP_CENTER';
export const SET_ZOOM_LEVEL = 'SET_MAP_ZOOM';
export const SET_OVERLAY = 'SET_OVERLAY';
export const SHOW_OVERLAY = 'SHOW_OVERLAY';
export const HIDE_OVERLAY = 'HIDE_OVERLAY';
export const HIDE_ALL_OVERLAYS = 'HIDE_ALL_OVERLAYS';
export const SET_BASELAYER = 'SET_BASELAYER';
export const REFRESH_MAP = 'REFRESH_MAP';


export const SELECT_LIGPLEKKEN_NEAR_POSITION = 'SELECT_LIGPLEKKEN_NEAR_POSITION'


export function setZoomLevel(zoom) {
  return { type: SET_ZOOM_LEVEL,
           zoom
         }
}

export function setCenter(center) {
  return { type: SET_MAP_CENTER,
           center
         }
}

export function showOverlay(overlay_name) {
  return { type: SHOW_OVERLAY,
           overlay_name
         }
}

export function hideOverlay(overlay_name) {
  return { type: HIDE_OVERLAY,
           overlay_name
         }
}

export function hideAllOverlays() {
  return { type: HIDE_ALL_OVERLAYS
         }
}
