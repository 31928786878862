import * as actions from '../actions/AboutActions';
// import { toggleElement, getJsonFromLocalStorage } from "../components/LpowUtils";


const buildinfo = require("!webpack-plugin-buildinfo?esModule=false&time&gitHash&gitHashShort!");

const initialState = {
  version: "",
  date: "0000-00-00",
  buildinfo
}

const AboutReducer= (state = initialState, action) => {
  switch (action.type) {
    //case actions.GET_VERSION:
    //    console.log("Reducer getVersion: "+action.version)
    //    return Object.assign({},
    //                         state,
    //                         { version: action.version_state
    //                         })

    case actions.SET_VERSION:

      return Object.assign({},
                           state,
                           {version: action.version_state.version,
                            date: action.version_state.date}
                            )

    default:
      return state
  }
};

export default AboutReducer
