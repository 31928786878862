import React from 'react';
import PropTypes from "prop-types";
import { Routes, Route } from 'react-router-dom';

import {LigplekDetails, LigplekUpdate, LigplekCreate } from "../components/LigplekModule";

import ProfileContainer from "../containers/ProfileContainer";
import LpowAboutContainer from "../containers/LpowAboutContainer";
import LpowMapContainer from "../containers/LpowMapContainer";
import LigplekCategorieListContainer from "./LigplekCategorieContainer";
import LigplekVaarwegContainer from "../containers/LigplekVaarwegContainer";
import LigplekSearchContainer from "../containers/LigplekSearchContainer"
import FavorietListContainer from "../containers/LigplekFavorietenContainer"
import AdoptedListContainer from "../containers/LigplekAdoptedContainer"
import LpowSettingsContainer from "../containers/LpowSettingsContainer"
import {RegisterContainer, LoginWithPasswContainer,
        LoginNoPasswContainer, AuthModuleContainer } from "../containers/AuthContainer"

const collapseNav = (nextState) => {
  console.log("Do Collapse Nav Workaround");
  let nav = $("nav");
  if(nav.find(".collapse").hasClass("in")) {
    nav.find(".navbar-toggle").click();
  }
}



class AppModules extends React.Component {

  constructor(props) {
    super(props);
    console.log("COnstructor AppModules");
  }

  static propTypes = {
  };


  render() {
    console.log("App.render")
    return (
        <div className="content">
           <Routes>
            <Route exact path="/" element={<LpowMapContainer />}  onEnter={collapseNav} />
            <Route exact path="/map/"  element={<LpowMapContainer />} onEnter={collapseNav} />

            <Route exact path="/auth/" element={<AuthModuleContainer />} onEnter={collapseNav} />
            <Route exact path="/register/" element={<RegisterContainer />} onEnter={collapseNav} />
            <Route exact path="/login/passwd/" element={<LoginWithPasswContainer />} onEnter={collapseNav} />
            <Route exact path="/login/no-passwd/" element={<LoginNoPasswContainer />} onEnter={collapseNav}  />
            <Route exact path="/profile/" element={<ProfileContainer />} onEnter={collapseNav} />

            <Route exact path="/ligplek/categorie/" element={<LigplekCategorieListContainer />} />
            <Route exact path="/ligplek/vaarweg/" element={<LigplekVaarwegContainer />} />
            <Route exact path="/ligplek/favorieten/" element={<FavorietListContainer />} />
            <Route exact path="/ligplek/adopted/" element={<AdoptedListContainer />} />


            <Route exact path="/settings/" element={<LpowSettingsContainer /> } />
            <Route exact path="/search/" element={<LigplekSearchContainer />} onEnter={collapseNav} />

            <Route exact path="/about/" element={<LpowAboutContainer /> } />

           </Routes>

          <div className="lpow-footer">Ligplekken Onderweg - Lvbhb</div>

        </div>
    );
  }

}

export default AppModules;

// Onderstaande naar Containers?
//            <Route exact path="/ligplek/:id/view/" element={<LigplekDetails />} />
//            <Route exact path="/ligplek/:id/update/" element={<LigplekUpdate />} />
