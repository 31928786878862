import React, { Component } from 'react'
import PropTypes from "prop-types"
import { FormGroup, Input, Label } from 'reactstrap'
import DatePicker from 'react-date-picker'


class FilterText extends Component {

  constructor(props) {
    super(props);
    // this.setFilterValue = this.setFilterValue.bind(this)
  }

  state = {
  }

  static propTypes = {
     setFilterValue: PropTypes.func,
     value: PropTypes.string
  }

  render() {
    return (
       <FormGroup>
        <Label className="lpow-label">Text</Label>
        <Input value={this.props.value} type="text" name="filterText"
               id="id_filterText" size="30" className="lpow-input"
               onChange={this.props.setFilterValue} placeholder="Tiep voor Filteren"/>
       </FormGroup>
     )
  }

}

const Option = ({ value, selected, display }) => (
  <option value={value}>
          {display}</option>
);


class FilterDraft extends Component {

  constructor(props) {
    super(props);
  }

  state = {
  }

  static propTypes = {
     value: PropTypes.number.isRequired,
     setFilterValue: PropTypes.func,
  }

  render() {
    // console.log("FilterDraft: this.props.value: "+this.props.value);
    const option_list = [{key:0, display:"Geen beperking"},
                          {key:50, display:"0m50"},
                          {key:100, display:"1m00"},
                          {key:150, display:"1m50"},
                          {key:200, display:"2m00"},
                          {key:250, display:"2m50"},
                          {key:300, display:"3m00"},
                          {key:400, display:"4m00"},
                          {key:500, display:"Meer dan 5 meter"},
                          ]
    return (
       <FormGroup>
       <Label className="lpow-label">Diepgang</Label>
       <Input className="lpow-input" type="select" name="filterDraft" id="id_filterDraft"
              value={this.props.value} onChange={this.props.setFilterValue}>
          {option_list.map((option) => (
             <Option key={option.key} value={option.key} display={option.display} />
          ))}
       </Input>
       </FormGroup>
     )
  }
}


class FilterDistance extends Component {

  constructor(props) {
    super(props);
  }

  state = {
  }

  static propTypes = {
     value: PropTypes.number.isRequired,
     setFilterValue: PropTypes.func.isRequired,
     position: PropTypes.object
  }


  renderCurrentLocationKnown() {
      if(this.props.position) {
         return (<span>Locatie <br/> bekend</span>)
      }
      return (<span>Locatie <br/>Niet bekend</span>)
  }

  render() {
    const option_list = [{key:1000, display:"Geen beperking"},
                          {key:5, display:"tot 5 km"},
                          {key:10, display:"tot 10 km"},
                          {key:25, display:"tot 25 km"},
                          {key:50, display:"tot 50 km"},
                          ]

    return (
       <FormGroup>
       <Label className="lpow-label">Afstand</Label>
       <Input className="lpow-input" type="select" name="filterDistance" id="id_filterDistance"
              value={this.props.value}  onChange={this.props.setFilterValue} >
          {option_list.map((option) => (
             <Option key={option.key} value={option.key} display={option.display} />
          ))}
       </Input>
       </FormGroup>
     )
  }
}


class FilterFoo extends Component {
  render() {
    // console.log("FilterFoo Render  ")
    // const nearByLayerSelected = this.props.curent_location_set;
    return (
       <FormGroup>
       <label className="lpow-label">Foo</label>
       <Input className="lpow-input" type="select" name="filterFoo" id="id_filterFoo">
          <option value="1000">Geen beperking</option>
          <option value="5">tot 5 km</option>
          <option value="10">tot 10 km</option>
          <option value="25">tot 25 km</option>
          <option value="50">tot 50 km</option>
       </Input>
       </FormGroup>
     )
  }

}


class FilterDateLastChanged extends Component {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this)
    //var date = this.props.value; // new Date();
    //if (this.props.value) {
    //    date = new Date(this.props.value)
    //}
    //this.state = {date}
  }

  static propTypes = {
     value: PropTypes.object.isRequired,
     setFilterValue: PropTypes.func.isRequired,
  }

  state = {
  }

  onChange = (date) => {this.props.setFilterValue(date)
                       }

  render() {
    return (
       <FormGroup>
         <Label className="lpow-label">Datum Laatste Aanpassing</Label>
         <div className="lpow-input">
           <DatePicker onChange={this.onChange}
                       value={this.props.value} />
         </div>
       </FormGroup>
     )
  }
}


class OrderDateLastChanged extends Component {
  constructor(props) {
    super(props);
  }

  state = {
  }

  render() {
    return (
       <FormGroup>
        <Label>Datum Laatste Aanpassing</Label>
        <div>
          <DatePicker/>
        </div>
       </FormGroup>
     )
  }
}

module.exports = {
   FilterText:  FilterText,
   FilterDraft: FilterDraft,
   FilterDistance: FilterDistance,
   FilterDateLastChanged: FilterDateLastChanged,
   OrderDateLastChanged: OrderDateLastChanged,
}
