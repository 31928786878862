import * as searchActions from '../actions/SearchActions';
import { toggleElement, getJsonFromLocalStorage } from "../components/LpowUtils";

const initialState = {
  filter_text: "",
  ligplekken: getJsonFromLocalStorage('lpowLigplekken'),
  search_result: [],
  selected_ligplekken: []
}

// Niet hier doen, maar in LigplekSearchContainer
function createSearchResult(state){
      // console.log("SearchReducer::createSearchResult "+state+" ............ "+state.filter_text);
      var re = null;
      if (state.filter_text && state.filter_text.length>=1) {
         re =new RegExp(state.filter_text, 'i');
      }
      let newFilteredLigpleklist = [];
      state.ligplekken.forEach(function(ligplek) {
              // console.log("test ligplek "+ligplek.id);
              //if (ligplek.max_length < action.searchFilters.lengthFilter) {
              //   return // reject
              //}
              //if (ligplek.min_length < action.searchFilters.lengthFilter) {
              //   return // reject
              //}

              if (ligplek.distance &&
                  ligplek.distance > state.distance_filter) {
                 return // reject
              }

              if (ligplek.maximale_diepgang < state.draft_filter) {
                 return // reject
              }

              if(re) {
                  if (ligplek.name.search(re) == -1 &&
                      ligplek.description.search(re) == -1 &&
                      ligplek.description_long.search(re) == -1){
                     return // reject
                  }
              }
              // accept
              newFilteredLigpleklist.push(ligplek);
      });
      // console.log("newFilteredLigpleklist.length: "+newFilteredLigpleklist.length);
      return newFilteredLigpleklist
}



const SearchReducer= (state = initialState, action) => {

  // console.log("SearchReducer: "+action.type);
  switch (action.type) {
    case searchActions.TOGGLE_LIGPLEK_TO_SEARCH_RESULT_ON_MAP:
      let _search_result_selected = state.selected_ligplekken;
      // de liglek uit het getoonde zoekresultaat halen
      let selected_ligplek_2_toggle = state.search_result.find(ligplek => ligplek.id == action.ligplek_id);
      if(selected_ligplek_2_toggle) {
         // en nu togglelen in het geselecteerde_ligplek lijstje
         _search_result_selected = toggleElement(_search_result_selected, selected_ligplek_2_toggle);
      }
      return Object.assign({},
                           state,
                           { selected_ligplekken: _search_result_selected,
                             changed: Date.now()
                           })


    case searchActions.CLEAR_SEARCH_RESULT:
      return Object.assign({},
                           state,
                           {selected_ligplekken: []}
                           )


    case searchActions.SET_DRAFT_FILTER:
      return Object.assign({},
                           state,
                           { draft_filter: action.value}
                           )

    case searchActions.SET_TEXT_FILTER:
      return Object.assign({},
                           state,
                           {text_filter: action.value,
                            filter_text: action.value }
                            )

    case searchActions.SET_DISTANCE_FILTER:
      return Object.assign({},
                           state,
                           {distance_filter: action.value}
                           )

    case searchActions.SET_DATE_CHANGED_FILTER:
      let _value = action.value ? action.value.toJSON() : Date.now();
      return Object.assign({},
                           state,
                           {date_last_changed: _value }
                           )


    case searchActions.CREATE_SEARCH_RESULT:
      //console.log("action.searchFilters: "+action.searchFilters);
      //console.log("state.search.draft_filter: "+state.draft_filter);
      //console.log("state.search.ligplek_length_filter: "+state.ligplek_length_filter);
      //console.log(state+" ............ "+state.lpowState);
      let re = null;
      if (action.searchFilters.filter_text && action.searchFilters.filter_text.length>=1) {
         re =new RegExp(action.searchFilters.filter_text, 'i');
      }
      let search_result = createSearchResult(state);
      return Object.assign({},
                           state,
                           {filter_draft: action.searchFilters.draftFilter,
                            filter_distance: action.searchFilters.draftDistance,
                            filter_text: action.searchFilters.filter_text, // todo: to text_filter
                            search_result
                            }
                            )


    case searchActions.SHOW_SEARCH_RESULT_ON_MAP:
      return Object.assign({},
                           state,
                           {selected_ligplekken: state.search_result}
                           )

    case searchActions.ADD_TO_SEARCH_RESULT_ON_MAP:
      let _new_selected_ligplekken = state.selected_ligplekken.concat(state.search_result);
      return Object.assign({},
                           state,
                           {selected_ligplekken: _new_selected_ligplekken}
                           )

    default:
      // console.log("SearchReducer Default");
      return state
    }

}

export default SearchReducer
