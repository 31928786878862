export const GET_VERSION = "FETCH_VERSION_DATE";
export const SET_VERSION = "SET_VERSION_DATE";


export function setVersionDate(version_state) {
  return { type: SET_VERSION,
           version_state,
         }
}

export function getVersionDate() {

  return function (dispatch) {
        let _version_state = {version: '0.0.0',
                              date: '00-00-0000'
                             };

        try {
            cordova.getAppVersion.getVersionNumber((version)=>{
                // console.log(version);
                _version_state.version = version;
                dispatch(setVersionDate(_version_state))
            });
        } catch (e) {
            console.error(e); // report, but ignore
            _version_state.version = version;
            dispatch(setVersionDate(_version_state))
        }
  }
}
