import * as lpowActions from '../actions/LpowActions';
// import { SHOW_ADOPT_LIGPLEK, } from '../actions/AdoptedLigplekActions';
import { toggleElement, distance, precisionOnClass} from '../components/LpowUtils'

import { authReducer, } from './AuthReducer'
import { profileReducer, } from './ProfileReducer'
import { adoptedLigplekReducer, } from './AdoptedLigplekReducer'

import { getJsonFromLocalStorage, } from '../components/LpowUtils'

let ligplekken_json = getJsonFromLocalStorage('lpowLigplekken');

const initialState = {
  karakterestieken: { karakterestieken: [],
  },
  ligplekken: { ligplekken: getJsonFromLocalStorage('lpowLigplekken'), // ligplekken_json
                isFetching: false
  },
  ligplekkenByCategorie: {ligplekkenByCategorie: [],
                          show_list_id: 0, // for /Categorie
                          isFetching: false
  },
  ligplekkenByVaarweg: {ligplekkenByVaarweg: [],
                        show_list_id: 0, // for /Vaarweg
                        isFetching: false},
  favourite_ligplekken: { ligplekken: [],
                          isFetching: false,
  },
  //adopted_ligplekken: { ligplekken: [],
  //                        isFetching: false,
  //},
  created_ligplekken: { ligplekken: [],
                          isFetching: false,
  },
  selected_ligplekken: { ligplekken: [],
                          isFetching: false,
  },
  selected_categorieen: {categorieen: [],
                         ligplekken: [],
                         isFetching: false
  },
  selected_vaarwegen: { vaarwegen: [],
                        ligplekken: [],
                        isFetching: false
  }
}

const LpowReducer= (state = initialState, action) => {

  let _lpowMap = null;
  let _ligplekken = null;
  let _karakterestieken = null;
  let _ligplekkenByCategorie = null;
  let _ligplekkenByVaarweg = null;
  let _created_ligplekken = null;
  let index_cat_id = null;
  let _selected_categorieen = null; //w ordt nu niet gebruikt. moet eigenlijk wel
  let _selected_vaarwegen = null;
  let new_lpow_markers_near_location = [];
  let precision_class = null;
  let new_zoom = null;


  switch (action.type) {
    case lpowActions.SET_REFRESH_DATA:
      console.error("SET_REFRESH_DATA: IS NOG NIET KLAAR......"+action.value);
      _settings = Object.assign({},
                               state.settings,
                               { refresh_data: true});
      //console.log("Schoonmaken LocalStorage");
      localStorage.removeItem('lpowLigplekken');
      localStorage.removeItem('lpowCategorieen');
      localStorage.removeItem('lpowVaarwegen');
      return Object.assign({},
                           state,
                           {settings: _settings} // dit moet hier niet}
                           )


    case lpowActions.RECEIVE_CREATED_LIGPLEKKEN:
      _created_ligplekken = state.created_ligplekken ? state.created_ligplekken : [];
      _created_ligplekken.ligplekken = action.ligplekken;
      return Object.assign({},
                           state,
                           {created_ligplekken: _created_ligplekken}
                           )

    /*
    case lpowActions.SHOW_ADOPT_LIGPLEK:
      // console.log("Als ingelogd kunnen Adopteren Ligplek: "+action.id);
      return Object.assign({}, state, {
          ligplekModal: {id: action.id,  // hiermee kan ligplekdata  opgehaald worden van de server
                         displayModal: true,
                         displayLigplekUpdate: false,
                         displayLigplekAdopt: true,
                         displayLigplekDetails: false,
                         displayLigplekCreate: false }
        })
    */

    //case VIEW_CREATED_LIGPLEKKEN:
    //case HIDE_CREATED_LIGPLEKKEN:
    //case REQUEST_CREATED_LIGPLEKKEN:
    //case RECEIVE_CREATED_LIGPLEKKEN:





    case lpowActions.REQUEST_KARAKTERESTIEKEN:
      _karakterestieken = Object.assign({},
                                  state.karakterestieken,
                                  {isFetching: true,
                                   didInvalidate: false,
                                   karakterestieken: [],
                                   requestedAt: action.requestedAt}
                                  )
      return Object.assign({},
                           state,
                           {karakterestieken: _karakterestieken}
                          )

    case lpowActions.RECEIVE_KARAKTERESTIEKEN:
      _karakterestieken = Object.assign({},
                                  state.karakterestieken,
                                  {isFetching: false,
                                   karakterestieken: action.karakterestieken,
                                   receivedAt: action.receivedAt}
                                  )
      return Object.assign({},
                           state,
                           {karakterestieken: _karakterestieken,
                           })

    case lpowActions.REQUEST_LIGPLEKKEN:
      _ligplekken = Object.assign({},
                                  state.ligplekken,
                                  { isFetching: true,
                                    // ligplekken: _updated_ligplekken,
                                    requestedAt: action.requestedAt,})
      return Object.assign({},
                           state,
                           {ligplekken:_ligplekken})

    case lpowActions.RECEIVE_LIGPLEKKEN:
        console.log("action.ligplekken: "+action.ligplekken);
        if(action.ligplekken.length==0) {
         return state;
        }

        if(action.ligplekken.length>0 &&
         state.ligplekken.ligplekken.length>0) {
        let _updated_ligplekken = state.ligplekken.ligplekken.filter(function(ligplek){
           // loop over liglekken.ligplekken, and return ligplek if not in action.ligplekken
           let find_result = action.ligplekken.findIndex(function(new_ligplek) {
                         return new_ligplek.id == ligplek.id;
           });
           if (find_result == -1) {
             return ligplek;
           }
           //else {
           //console.log("Update ligplek "+ligplek.id);
           //}
        });
        _updated_ligplekken = _updated_ligplekken.concat(action.ligplekken);
        _ligplekken = Object.assign({},
                                    state.ligplekken,
                                    { isFetching: false,
                                      ligplekken: _updated_ligplekken,
                                      receivedAt: action.receivedAt,})

        }
        if(action.ligplekken.length>0 &&
           state.ligplekken.ligplekken.length==0) {
                    _ligplekken = Object.assign({},
                                                state.ligplekken,
                                                { isFetching: false,
                                                  ligplekken: action.ligplekken,
                                                  receivedAt: action.receivedAt,})
        }

      if(_ligplekken) {
          localStorage.setItem('lpowLigplekken', JSON.stringify(_ligplekken.ligplekken));
          localStorage.setItem('lpowDateLastUpdate', Date.now());
          localStorage.setItem('lpowLigplekkenDateLastUpdate', Date.now());
      }

      return Object.assign({},
                           state,
                           {ligplekken: _ligplekken})


    case lpowActions.REQUEST_FAVOURITE_LIGPLEKKEN:
      return Object.assign({},
                           state,
                           {favourite_ligplekken:{ isFetching: true,
                                                   didInvalidate: false,
                                                   ligplekken: [],
                                                   requestedAt: action.requestedAt}
      })

    case lpowActions.RECEIVE_FAVOURITE_LIGPLEKKEN:
      return Object.assign({},
                           state,
                           {favourite_ligplekken:{ isFetching: false,
                                                   ligplekken: action.favourite_ligplekken,
                                                   receivedAt: action.receivedAt}
      })

    case lpowActions.REQUEST_SELECTED_LIGPLEKKEN:
      return Object.assign({},
                           state,
                           { selected_ligplekken:{ isFetching: true,
                                                   didInvalidate: false,
                                                   ligplekken: [],
                                                   requestedAt: action.requestedAt}
      })

    case lpowActions.RECEIVE_SELECTED_LIGPLEKKEN:
      // console.log("RECEIVE_SELECTED_LIGPLEKKEN action.selected_ligplekken", action.selected_ligplekken)
      return Object.assign({}, state, {
        selected_ligplekken:{ isFetching: false,
                               ligplekken: action.selected_ligplekken,
                               receivedAt: action.receivedAt,}
      })

    case lpowActions.TOGGLE_SELECT_LIGPLEK:
      let the_selected_ligplekken = state.selected_ligplekken.ligplekken;
      let selected_ligplek_toggle = state.ligplekken.ligplekken.find(function(ligplek) {
                         return ligplek.id == action.id;
                      });
      the_selected_ligplekken = toggleElement(the_selected_ligplekken, selected_ligplek_toggle);
      localStorage.setItem('lpowSelectedLigplekken', JSON.stringify(the_selected_ligplekken));
      let _selected_ligplekken = Object.assign({}, state.selected_ligplekken,
                                                { isFetching: false,
                                                  ligplekken: the_selected_ligplekken,
                                                 })
      // _lpowMap = utils.disableAllOverlays(_lpowMap);
      _lpowMap = Object.assign({},
                               state.lpowMap,
                               { // refresh: !state.lpowMap.refresh,
                                 overlay_alleligplekken: false,
                                 overlay_geselecteerdeligplekken: true,
                                 zoom: 7,
                               })
      return Object.assign({}, state, {
        selected_ligplekken: _selected_ligplekken,
        lpowMap: _lpowMap,
      })

    case lpowActions.TOGGLE_FAVOURITE_LIGPLEK:
      let the_favourite_ligplekken = state.favourite_ligplekken.ligplekken;
      let favourite_ligplek_toggle = state.ligplekken.ligplekken.find(function(ligplek) {
                         return ligplek.id == action.id;
                      });
      console.log("favourite_ligplek_toggle: "+favourite_ligplek_toggle);
      the_favourite_ligplekken = toggleElement(the_favourite_ligplekken, favourite_ligplek_toggle);
      localStorage.setItem('lpowFavouriteLigplekken', JSON.stringify(the_favourite_ligplekken));
      let _favourite_ligplekken = Object.assign({}, state.favourite_ligplekken,
                                                { isFetching: false,
                                                  ligplekken: the_favourite_ligplekken})
      return Object.assign({}, state, {
        favourite_ligplekken: _favourite_ligplekken,
      })

    case lpowActions.REQUEST_CATEGORIEEN:
      return Object.assign({}, state, {
        ligplekkenByCategorie:{ isFetching: true,
                                didInvalidate: false,
                                ligplekkenByCategorie: [],
                                requestedAt: action.requestedAt,}
      })

    case lpowActions.RECEIVE_CATEGORIEEN:
      _ligplekkenByCategorie = [];
      if(state.ligplekken.ligplekken) {
          action.categorieen.map(function(categorie){
             let _ligplek_list = [];
             categorie.ligplek_id_list.map(function(ligplek_id) {
                 let _ligplek = state.ligplekken.ligplekken.find(function(ligplek) {
                             return ligplek.id == ligplek_id;
                 });
                 if (_ligplek) {
                    _ligplek_list.push(_ligplek);
                 }
             })
             categorie.ligplek_list = _ligplek_list;
             _ligplekkenByCategorie.push(categorie)
          });
      }

      return Object.assign({}, state, {
        ligplekkenByCategorie:{ isFetching: false,
                                ligplekkenByCategorie: _ligplekkenByCategorie,
                                show_categorie_id: 0,
                                show_list_id: 0,
                                receivedAt: action.receivedAt,}
      })


    case lpowActions.RECEIVE_SELECTED_CATEGORIEEN: // Dit is een Profile ding
        _ligplekken = [];
        action.selected_categorieen.map(function(categorie_id){
            // console.log("categorie_id: "+categorie_id);
            let categorie = state.ligplekkenByCategorie.ligplekkenByCategorie.find(function(categorie){
                return categorie.id == categorie_id;
            })
            if( categorie && categorie.ligplek_list) {
              _ligplekken = _ligplekken.concat(categorie.ligplek_list);
            }
        })

      _selected_categorieen = Object.assign({},
                                                state.selected_categorieen,
                                                { isFetching: false,
                                                  categorieen: action.selected_categorieen,
                                                  ligplekken: _ligplekken,
                                                  receivedAt: action.receivedAt,
                                                })

      return Object.assign({},
                           state, {
                           selected_categorieen: _selected_categorieen
                           }
      )


    case lpowActions.RECEIVE_SELECTED_VAARWEGEN: // dit is een Profile ding
      _ligplekken = [];  // alle ligplekken in de geselecteerde vaarwegen
      try {
            console.log("action: "+action);
            console.log("action.selected_vaarwegen: "+action.selected_vaarwegen);
            action.selected_vaarwegen.map(function(vaarweg_id){
                let vaarweg = state.ligplekkenByVaarweg.ligplekkenByVaarweg.find(function(vaarweg){
                    return vaarweg.id == vaarweg_id;
                })
                if(vaarweg && vaarweg.ligplek_list) {
                    _ligplekken = _ligplekken.concat(vaarweg.ligplek_list);
                }
            })

            _selected_vaarwegen = Object.assign({},
                                                state.selected_vaarwegen,
                                                { isFetching: false,
                                                  vaarwegen: action.selected_vaarwegen,
                                                  ligplekken: _ligplekken,
                                                  receivedAt: action.receivedAt
                                                }
                                                )

      }
      catch (e) {
         console.error("Fout Seleeteerde Vaarwegen.: "+e);
         // list = [];
         //is_fetching = false;
         _selected_vaarwegen = Object.assign({},
                                             { isFetching: false,
                                                  vaarwegen: [],
                                                  ligplekken: _ligplekken,
                                                  receivedAt: action.receivedAt
                                                }
                                              )

      }

      return Object.assign({},
                           state,
                           { selected_vaarwegen: _selected_vaarwegen
                           }
      )


    case lpowActions.REQUEST_VAARWEGEN:
      return Object.assign({}, state, {
        ligplekkenByVaarweg:{ isFetching: true,
                              didInvalidate: false,
                              ligplekkenByVaarweg:[],
                              show_list_id: 0,
                              requestedAt: action.requestedAt,}
      })


    case lpowActions.RECEIVE_VAARWEGEN:
      _ligplekkenByVaarweg = [];
      if(state.ligplekken.ligplekken) {
          action.vaarwegen.map(function(vaarweg){
             let _ligplek_list = [];
             vaarweg.ligplek_id_list.map(function(ligplek_id) {
                 let _ligplek = state.ligplekken.ligplekken.find(function(ligplek) {
                             return ligplek.id == ligplek_id;
                 });
                 if (_ligplek) {
                    _ligplek_list.push(_ligplek);
                 }
             })
             vaarweg.ligplek_list = _ligplek_list;
             _ligplekkenByVaarweg.push(vaarweg)
          });
      }

      return Object.assign({}, state, {
        ligplekkenByVaarweg:{ isFetching: false,
                              ligplekkenByVaarweg: _ligplekkenByVaarweg,
                              show_list_id: 0,
                              receivedAt: action.receivedAt,}
      })


    case lpowActions.SHOW_CATEGORIE_LIGPLEKLIST: // For /Categorie
      _ligplekkenByCategorie =  Object.assign({},
                                              state.ligplekkenByCategorie,
                                              {show_list_id: action.categorie_id}
                                              )

      return Object.assign({},
                           state,
                           {ligplekkenByCategorie: _ligplekkenByCategorie}
                          )

    case lpowActions.SHOW_VAARWEG_LIGPLEKLIST: // For: /Vaarweg
      _ligplekkenByVaarweg =  Object.assign({},
                                            state.ligplekkenByVaarweg,
                                            {show_list_id: action.vaarweg_id}
                                            )
      return Object.assign({},
                           state,
                           {ligplekkenByVaarweg: _ligplekkenByVaarweg}
      )

    case lpowActions.TOGGLE_CATEGORIE_ON_MAP:
      //console.log("TOGGLE_CATEGORIE_ON_MAP "+action.categorie_id)
      index_cat_id = state.selected_categorieen.categorieen.indexOf(action.categorie_id);
      if (index_cat_id>=0) {
          state.selected_categorieen.categorieen.splice( index_cat_id, 1 );
      } else {
          state.selected_categorieen.categorieen.push(action.categorie_id);
      }
      return Object.assign({},
                           state,
                           {// selected_categorieen: _selected_categorieen
                           }
                            )

    case lpowActions.SHOW_CATEGORIE_ON_MAP:
      //console.log("SHOW_CATEGORIE_ON_MAP "+action.categorie_id);
      index_cat_id = state.selected_categorieen.categorieen.indexOf(action.categorie_id);
      _ligplekken = [];
      if (index_cat_id>=0) {
        state.selected_categorieen.categorieen.splice( index_cat_id, 1 );
        //console.log("Er is een Categorie uitgeschakeld, helemaal opnieuw genereren")
        state.selected_categorieen.categorieen.map(function(categorie_id){
            //console.log("categorie_id: "+categorie_id);
            let categorie = state.ligplekkenByCategorie.ligplekkenByCategorie.find(function(categorie){
                return categorie.id == categorie_id;
            })
            if(categorie.ligplek_list) {
              _ligplekken = _ligplekken.concat(categorie.ligplek_list);
            }
        })
      } else {
          state.selected_categorieen.categorieen.push(action.categorie_id);
          //console.log("Er is een Categorie ingeschakeld, alleen wat toevoegen");
          let categorie = state.ligplekkenByCategorie.ligplekkenByCategorie.find(function(categorie){
                return categorie.id == action.categorie_id;
          })
          _ligplekken = state.selected_categorieen.ligplekken;
          if(categorie.ligplek_list) {
             _ligplekken = _ligplekken.concat(categorie.ligplek_list);
          }
      }


      _selected_categorieen = Object.assign({},
                                            state.selected_categorieen,
                                                {ligplekken: _ligplekken

                                                 }
                                               )
      //console.log("1 Local Storage lpowSelectedCategorieen : "+JSON.stringify(_selected_categorieen.categorieen))
      // localStorage.setItem('lpowSelectedCategorieen', JSON.stringify(the_selected_categorieen));
      localStorage.setItem('lpowSelectedCategorieen', JSON.stringify(_selected_categorieen.categorieen));
      return Object.assign({},
                           state,
                           {selected_categorieen: _selected_categorieen}
                           )

    case lpowActions.HIDE_CATEGORIE_ON_MAP:
      //console.log("HIDE_CATEGORIE_ON_MAP "+action.categorie_id)
      // let the_selected_categorieen = state.selected_categorieen.categorieen;
      index_cat_id = state.selected_categorieen.categorieen.indexOf(action.categorie_id);
      _ligplekken = [];
      if (index_cat_id>=0) {
          state.selected_categorieen.categorieen.splice( index_cat_id, 1 );
          //console.log("Er is een Categorie uitgeschakeld, helemaal opnieuw genereren")
          state.selected_categorieen.categorieen.map(function(categorie_id){
            //console.log("categorie_id: "+categorie_id);
            let categorie = state.ligplekkenByCategorie.ligplekkenByCategorie.find(function(categorie){
                return categorie.id == categorie_id;
            })
            // cat_ligplek_list = categorie.ligplek_list;
            if(categorie.ligplek_list) {
              _ligplekken = _ligplekken.concat(categorie.ligplek_list);
            }
          })
      } else {
          state.selected_categorieen.categorieen.push(action.categorie_id);
          // console.log("Er is een Categorie ingeschakeld, alleen wat toevoegen");
          // pak de toe te voegen gategorie
          let categorie = state.ligplekkenByCategorie.ligplekkenByCategorie.find(function(categorie){
                // console.log(categorie.id+" ?? categorie_id: "+action.categorie_id);
                return categorie.id == action.categorie_id;
          })
          // voeg de ligplekkenvan categorie toe aan de reeds bestaande lijst
          _ligplekken = state.selected_categorieen.ligplekken;
          if(categorie.ligplek_list) {
             _ligplekken = _ligplekken.concat(categorie.ligplek_list);
          }
      }

      _selected_categorieen = Object.assign({},
                                            state.selected_categorieen,  // deze state is al met de juiste selected_categorien
                                            {ligplekken: _ligplekken }
                                               )
      // bewaar alleen de lijst met categorie_id's
      localStorage.setItem('lpowSelectedCategorieen', JSON.stringify(_selected_categorieen.categorieen));

      return Object.assign({},
                           state,
                           {selected_categorieen: _selected_categorieen}
                            )


    case lpowActions.SHOW_VAARWEG_ON_MAP:
      _ligplekken = [];  // alle ligplekken uit de geselecteerde  Vaarwegen
      let index_vaarweg_id = state.selected_vaarwegen.vaarwegen.indexOf(action.vaarweg_id);
      if (index_vaarweg_id>=0) {
            // helemaal opnieuw genereren
            state.selected_vaarwegen.vaarwegen.splice( index_vaarweg_id, 1 );
            state.selected_vaarwegen.vaarwegen.map(function(vaarweg_id){
                let vaarweg = state.ligplekkenByVaarweg.ligplekkenByVaarweg.find(function(vaarweg){
                    return vaarweg.id == vaarweg_id;
                })
                vrwg_ligplek_list = vaarweg.ligplek_list;
                if(vaarweg.ligplek_list) {
                  _ligplekken = _ligplekken.concat(vaarweg.ligplek_list);
                }
            })
      } else {
          // alleen wat toevoegen
          state.selected_vaarwegen.vaarwegen.push(action.vaarweg_id);
          let vaarweg = state.ligplekkenByVaarweg.ligplekkenByVaarweg.find(function(vaarweg){
                return vaarweg.id == action.vaarweg_id;
          })
          _ligplekken = state.selected_vaarwegen.ligplekken;
          if(vaarweg.ligplek_list) {
            _ligplekken = _ligplekken.concat(vaarweg.ligplek_list);
          }
      }

      _selected_vaarwegen = Object.assign({},
                                          state.selected_vaarwegen,  // deze state is al met de juiste selected_vaarwegen
                                          {ligplekken: _ligplekken }
                                );
      // bewaar alleen de lijst met vaarweg-id's
      localStorage.setItem('lpowSelectedVaarwegen', JSON.stringify(_selected_vaarwegen.vaarwegen));

      // make sure the map shows the vaarwegen overlay
      // Alle ligplekken laag gaat uit
      return Object.assign({},
                           state,
                           {selected_vaarwegen: _selected_vaarwegen}
                            )


    case lpowActions.SET_LOCATION_POPUP_SHOW:
      _lpowMap = Object.assign({},
                               state.lpowMap,
                               { location_popup_show: action.value }
                )
      return Object.assign({}, state, {
         lpowMap: _lpowMap
      })


    case lpowActions.SAVE_CREATED_LIGPLEK:
      // Save the new Ligplek to the server.
      // Only 'this' user on this app-instance (browser) can edit it (until it's status is changed)
      _created_ligplekken = state.created_ligplekken ? state.created_ligplekken : [];
      action.ligplekjson.local_msg = "Alleen Hier Aan Te Passen"
      console.log("UPDATE ligplek JSON name add (Alleen Hier Aan Te passen): "+action.ligplekjson.local_msg);
      _created_ligplekken.ligplekken.push(action.ligplekjson);
      localStorage.setItem("lpowCreatedLigplekken", JSON.stringify(_created_ligplekken.ligplekken));
      return Object.assign({},
                           state,
                           { created_ligplekken: _created_ligplekken,
        })

    default:
      return state
    }
};

export default LpowReducer