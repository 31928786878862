import React from 'react';
import { connect } from 'react-redux'
import LigplekCategorie from '../components/LigplekCategorie'
import { toggleSelectLigplek, showCategorieLigplekList, showCategorieOnMap  } from '../actions/LpowActions'
// import { showCategorieOnMap  } from '../actions/MapActions'


const mapStateToProps = state => {
    // console.log( "list_data: "+ state.lpowState.ligplekkenByCategorie.ligplekkenByCategorie);
    return {
       isFetching: state.lpowState.ligplekkenByCategorie.isFetching,
       list_data: state.lpowState.ligplekkenByCategorie.ligplekkenByCategorie,  // lijstVanLijsten
       selected_ligplekken: state.lpowState.selected_ligplekken.ligplekken,
       selected_id_list: state.lpowState.selected_categorieen.categorieen,  // ? naar lpowState.ligplekkenByCategorie
       show_list_id: state.lpowState.ligplekkenByCategorie.show_list_id // voor /Categorie
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch,
      // onderstaande functies worden via algemene functie-calls gemapt naar specefieke uitvoeringen
      showGroupOnMap: id => {
        dispatch( showCategorieOnMap(id));
      },
      showGroupLigpleklist: id => {
        dispatch( showCategorieLigplekList(id));
      },
      selectLigplek: id => {
        // console.log("container selectLigplek: "+id);
        dispatch( toggleSelectLigplek(id) );
      }
    }
}

const LigplekCategorieListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LigplekCategorie)

export default LigplekCategorieListContainer;
