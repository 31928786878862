export const SET_TEXT_FILTER = 'SET_TEXT_FILTER';
export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER';
export const SET_DRAFT_FILTER = 'SET_LIGPLEK_DRAFT_FILTER';
export const SET_LIGPLEK_LENGTH_FILTER = 'SET_LIGPLEK_LENGTH_FILTER';
export const SET_DISTANCE_FILTER = 'SET_DISTANCE_FILTER';
export const SET_DATE_CHANGED_FILTER = 'SET_DATE_CHANGED_FILTER';
export const CLEAR_SEARCH_RESULT = 'CLEAR_SEARCH_RESULT';
export const CREATE_SEARCH_RESULT = "CREATE_SEARCH_RESULT"; // is dus eigenlijk geen SearchAction
export const SHOW_SEARCH_RESULT_ON_MAP = "SHOW_SEARCH_RESULT_ON_MAP";
export const ADD_TO_SEARCH_RESULT_ON_MAP = "ADD_TO_SEARCH_RESULT_ON_MAP";
export const ADD_LIGPLEK_TO_SEARCH_RESULT_ON_MAP = "TOGGLE_LIGPLEK_TO_SEARCH_RESULT_ON_MAP";
export const TOGGLE_LIGPLEK_TO_SEARCH_RESULT_ON_MAP = "TOGGLE_LIGPLEK_TO_SEARCH_RESULT_ON_MAP";


export const VisibilityFilters = {
  SHOW_ALL: 'SHOW_ALL',
  SHOW_COMPLETED: 'SHOW_COMPLETED',
  SHOW_ACTIVE: 'SHOW_ACTIVE',
}

export const LigplekFilters = {
  SET_VISIBILITY_FILTER: 'SET_VISIBILITY_FILTER',
  SET_LIGPLEK_DRAFT_FILTER: 'SET_LIGPLEK_DRAFT_FILTER',
  SET_LIGPLEK_LENGTH_FILTER: 'SET_LIGPLEK_LENGTH_FILTER',
}


export function clearSearchResultOnMap() {
  return { type: CLEAR_SEARCH_RESULT,
         }
}

export function createSearchResult(searchFilters) {
  return { type: CREATE_SEARCH_RESULT,
           searchFilters
         }
}

export function setDistanceFilter(value) {
  return { type: SET_DISTANCE_FILTER,
           value,
         }
}


export function setDraftFilter(value) {
  return { type: SET_DRAFT_FILTER,
           value,
         }
}

export function setLigplekLengthFilter(value) {
  return { type: SET_LIGPLEK_LENGTH_FILTER,
           value,
         }
}

export function setTextFilter(value) {
  return { type: SET_TEXT_FILTER,
           value,
         }
}

export function setDateLastChanged(value) {
  // console.log("Action setDateLastChanged: "+value);
  return { type: SET_DATE_CHANGED_FILTER,
           value,
         }
}


export function setRefreshData(value) {
  return { type: SET_REFRESH_DATA,
           value,
         }
}

export function showSearchResultOnMap() {
  return { type: SHOW_SEARCH_RESULT_ON_MAP,
         }
}

export function addToSearchResultOnMap() {
  return { type: ADD_TO_SEARCH_RESULT_ON_MAP,
         }
}

export function toggleLigplekToSearchResult(ligplek_id) {
  return { type: ADD_LIGPLEK_TO_SEARCH_RESULT_ON_MAP,
           ligplek_id,
  }
}


