import React from "react";
import PropTypes from "prop-types";
import shortid from "shortid";
import {
  Circle, FeatureGroup,
  LayerGroup, LayersControl, Map,
  Marker, Popup,
} from 'react-leaflet';
// import { useDispatch } from 'react-redux';
import parse from 'html-react-parser';
const { BaseLayer, Overlay } = LayersControl;


import { createIcon, } from "./LpowUtils"
import { showLigplek, } from "../actions/ModalLigplekActions";
const uuid = shortid.generate;


class LpowMarker extends React.Component {

  constructor(props) {
    super(props);
    this.showLigplek = this.showLigplek.bind(this);
    this.onOpenHandle = this.onOpenHandle.bind(this);
    //this.getKey = this.getKey.bind(this);
  }

  static propTypes = {
     data: PropTypes.object.isRequired,
     dispatch: PropTypes.func.isRequired
  };

  showLigplek() {
      this.props.dispatch(showLigplek(this.props.data.id));
  }

  onOpenHandle(e ) {
     // console.log("Popup onOpenHandle e: "+e)
     // console.log("Popup onOpenHandle popup: ");
  }

  // shadowSize, shadowAnchor
  render() {
     let data = this.props.data;
     if (data === undefined) {console.error("marker data undefined. Refresh All Data"); return };
     let pos = [Number(data.lat), Number(data.lng)];
     let icon = createIcon(data.categorie_icon_url);
     let marker_description = data.description_long; // ? data.description_long : "";
     //console.log("marker_description: >"+marker_description+"<");
     return (
        <Marker position={pos} icon={icon} >
          <Popup onOpen={this.onOpenHandle}>
                <span onClick={this.showLigplek}>
                  <b>{data.name}</b>
                  <br />{ parse(marker_description) }
                </span>
          </Popup>
        </Marker>
     )
  }
}

// shadowSize, shadowAnchor
// const icon_2 = createIcon(data.categorie_icon_url);
const iconPath = "/img/favourite_2_512x512.png";
const favourite_icon = createIcon(iconPath);
const shadowPath = "/img/marker-shadow.png";
const shadow_icon = createIcon(shadowPath);
const shadowAnchor = [4, 62];


class LpowFavouriteMarker extends React.Component {

  constructor(props) {
    super(props);
    this.showLigplek = this.showLigplek.bind(this);
  }

  static propTypes = {
     data: PropTypes.object.isRequired,
     dispatch: PropTypes.func.isRequired
  };

  showLigplek() {
     if(this.props.togglePane) {
         this.props.togglePane(this.props.data.id);
     }
     this.props.dispatch(showLigplek(this.props.data.id));

  }

  render() {
     let data = this.props.data;
     let pos = [Number(data.lat), Number(data.lng)];
     let marker_key="favourite-"+data.id;

     return (
        <Marker position={pos} icon={favourite_icon}
                shadowUrl={shadowPath} shadow={shadow_icon} shadowAnchor={shadowAnchor} >
          <Popup>
                <span onClick={this.showLigplek}>
                  <b>{data.name}</b>
                  <br />{ parse(data.description_long) }
                </span>
          </Popup>
        </Marker>
     )
  }
}


class LpowSearchMarker extends React.Component {

  constructor(props) {
    super(props);
    this.showLigplek = this.showLigplek.bind(this);
  }

  static propTypes = {
     data: PropTypes.object.isRequired,
     // togglePane: PropTypes.func.isRequired,
     dispatch: PropTypes.func.isRequired
  };

  showLigplek() {
      // console.log("LigplekId: ", this.props.data.id);
      // this.props.togglePane(this.props.data.id, this.props.data); Todo: Refactoren naar popup via redux
      if(this.props.togglePane) {
         this.props.togglePane(this.props.data.id);
      }
      this.props.dispatch(showLigplek(this.props.data.id));

  }

  // shadowSize, shadowAnchor
  render() {
             // console.log("LpowSearchMarker this.props.data: "+this.props.data);
             let data = this.props.data;
             let pos = [Number(data.lat), Number(data.lng)];
             let icon_2 = createIcon(data.categorie_icon_url);
             let iconPath = "/img/search_result_512x512.png";
             let icon = createIcon(iconPath);
             let shadowPath = "/img/marker-shadow.png";
             let shadowIcon = createIcon(shadowPath);
             let shadowAnchor = pos; // [4, 62]; // todo: controlleer waarom dit zo vast staat. kan weg?
             let marker_key="search-"+data.id;
             return (
                <Marker position={pos} icon={icon}
                        key={marker_key}
                        shadowUrl={shadowPath} shadow={shadowIcon} shadowAnchor={shadowAnchor} >
                  <Popup>
                        <span onClick={this.showLigplek}>
                          <b>{data.name}</b>
                          <br />{ parse(data.description_long) }
                        </span>
                  </Popup>
                </Marker>
             )
  }
}


class LpowOverlayMarkers extends React.Component {

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error+" "+ info);
  }

  render() {
        let count = 0;
        let data = this.props.data;
        let marker_list = data.map(el => {
               count=count+1;
               if (count<10) {
                 return <LpowMarker data={el} />
               }
        });
        return marker_list
  }

}

LpowOverlayMarkers.propTypes = {
  data: PropTypes.array.isRequired
};


// =====================================================
class LpowOverlay extends Overlay {

  static propTypes = {
     data: PropTypes.array.isRequired
  };

  render() {
        let data = this.props.data;
        let marker_list = data.map(el => {
                 return <LpowMarker  prefix="bar-foo" data={el} />
        });
        return( <Overlay name="Alle Ligplekken 2" >
                {marker_list}
                </Overlay>
        )
  }

}





class LpowLayerGroup extends React.Component {

  constructor(props) {
    super(props);
    this.createMarkers = this.createMarkers.bind(this);
  }

  static propTypes = {
     data: PropTypes.array.isRequired,
     prefix: PropTypes.string.isRequired,
     dispatch: PropTypes.func.isRequired
  };

  state = {
      marker_list: null,
  }

  createMarkers(){
    let data = this.props.data;
    if (this.state.marker_list == null) {
        let new_marker_list = data.map((ligplek) => (
                 <LpowMarker key={this.props.prefix+"-"+ligplek.id}
                             data={ligplek}
                             dispatch={this.props.dispatch} />
              ))
        this.setState({marker_list: new_marker_list});
    }
  }

  componentDidMount() {
     this.createMarkers();
  }

  render() {
        if (this.state.marker_list == null) {
            return( <LayerGroup>

                    </LayerGroup>
                    )
        }
        return( <LayerGroup>
                   {this.state.marker_list}
                </LayerGroup>
        )
  }
}


module.exports = {
    LpowMarker,
    LpowFavouriteMarker,
    LpowSearchMarker,
    LpowOverlayMarkers,
    LpowLayerGroup
}
