import * as mapActions from '../actions/MapActions';

const initialState = {
             zoom: 7,
             lpow_markers: [], // deze en hieronder moeten uitgerekeend worden maar. Niet 'Gecached'
             lpow_markers_at_location: [], // Todo: verplaatsen naar eigen State?
             center: [51.6666, 4.444],
             //curent_location_set: false,  // moet weg
             refresh: false, // zou niet ndig moeten zijn

             base_layer_mapnik: true,
             base_layer_black_white: false,

             overlay_indebuurt: true,
             overlay_categorieen: false,
             overlay_vaarwegen: false,
             overlay_alleligplekken: true,
             overlay_geselecteerdeligplekken: false,
             overlay_zoekresultaat: false,
             overlay_favorieteligplekken: false,
             overlay_adoptedligplekken: false,
             overlay_createdligplekken: false,

             precision_class: 'precision_error',
             location_popup_show: true
}

var index_cat_id = null;

const MapReducer= (state = initialState, action) => {
  // console.log("MapReducer: "+action.type);
  let _selected_categorieen = null;
  let the_selected_categorieen = null;
  let vaarweg = null;
  let _ligplekken = [];
  let vrwg_ligplek_list;

  switch (action.type) {

    case mapActions.SHOW_OVERLAY:
          return Object.assign({},
                               state,
                               {[action.overlay_name]: true,}
                               )

    case mapActions.HIDE_OVERLAY:
          return Object.assign({},
                               state,
                               {[action.overlay_name]: false})

    case mapActions.HIDE_ALL_OVERLAYS:
          return Object.assign({},
                               state,
                               {overlay_indebuurt: false,
                                overlay_categorieen: false,
                                overlay_vaarwegen: false,
                                overlay_alleligplekken: false,
                                overlay_geselecteerdeligplekken: false,
                                overlay_zoekresultaat: false,
                                overlay_favorieteligplekken: false,
                                overlay_adoptedligplekken: false,
                                overlay_createdligplekken: false})


    case mapActions.SET_MAP_CENTER:
      return Object.assign({},
                           state,
                           {center: action.center,
                            refresh: Date.now() }
                           )

    case mapActions.SET_ZOOM_LEVEL:
      return Object.assign({},
                           state,
                           { zoom: action.zoom }
                           )

    default:
      return state
    }


}

export default MapReducer
