import React from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { NavLink, NavItem } from 'reactstrap'
import parse from 'html-react-parser';
import { showLigplek  } from '../actions/ModalLigplekActions'
import { siteDomain, protDomPort } from '../actions/LpowActions'
import { showCategorieOnMap } from '../actions/LpowActions'
import { getLocalStorageLigpleklist, } from './LpowUtils'
import { isMobile } from 'react-device-detect';

class ListOfLigplekLists extends React.Component {

  constructor(props) {
    super(props);
  }

  static propTypes = {
      list_data: PropTypes.array.isRequired,
      item_icon: PropTypes.string, //.isRequired,
      isFetching: PropTypes.bool.isRequired,
      dispatch: PropTypes.func.isRequired,
      selectLigplek: PropTypes.func.isRequired,
      selected_ligplekken: PropTypes.array.isRequired,
      showGroupOnMap: PropTypes.func.isRequired,
      showGroupLigpleklist: PropTypes.func.isRequired,
      show_list_id: PropTypes.number.isRequired, // list item die uitgeklapt moet worden
      selected_id_list: PropTypes.array.isRequired // id list geselecteerde ListOfListsItem's
  }

  renderLoading() {
    return <div>Ladende...</div>;
  }

  renderError() {
    return <div>Er is een fout opgetreden. Ververs pagina.</div>;
  }

  renderList() {
    // console.log("Render ListOfLists show_list_id: "+this.props.show_list_id);
    return(  this.props.list_data.map( ligpleklist =>  {
                                      // console.log("this.props.show_list_id: "+this.props.show_list_id+" ?== "+ligpleklist.id)
                                      if(this.props.show_list_id == 0 || this.props.show_list_id == ligpleklist.id) {
                                        let _listitem_is_selected = this.props.selected_id_list.includes(ligpleklist.id);
                                        let icon_url = (ligpleklist.icon ? ligpleklist.icon : protDomPort+"/lpow-app/img/vaarweg-default.png")
                                        return <ListOfListsItem key={ligpleklist.id}
                                                               item={ligpleklist}
                                                               item_icon={icon_url}
                                                               dispatch={this.props.dispatch}
                                                               selectLigplek={this.props.selectLigplek}
                                                               isFetching={this.props.isFetching}
                                                               selected_ligplekken={this.props.selected_ligplekken}
                                                               showGroupOnMap={this.props.showGroupOnMap}
                                                               showGroupLigpleklist={this.props.showGroupLigpleklist}
                                                               selected={_listitem_is_selected}
                                                               show_list_id={this.props.show_list_id}
                                                               />
                                      }
                                    })
    );
  }

  renderListOfLists() {
    if (this.props.isFetching) {
      return this.renderLoading();
    } else if (this.props.list_data.length==0) {
      return this.renderLoading();
    } else if (this.props.list_data.length>0) {
      return this.renderList();
    } else {
      return this.renderError();
    }
  }

  render() {
    return (
        <ul className="list-group list-of-ligplek-list">
        { this.renderListOfLists() }
        </ul>
    );
  }

}


class ListOfListsItem extends React.Component {

  constructor(props) {
    super(props);
    this.thisLi = React.createRef();
    this.toggleShowContent = this.toggleShowContent.bind(this);
    this.setFilterValue = this.setFilterValue.bind(this);
    this.doFiltering = this.doFiltering.bind(this);
    this.toggleSelectCategorieOnMap = this.toggleSelectCategorieOnMap.bind(this);
    this.toggleSelectLigplekOnMap = this.toggleSelectLigplekOnMap.bind(this);
  }

  state = { loading: false,
            filter_value: '',
            filteredLigpleklist: this.props.item.ligplek_list,
            selected: false,
  }

  static propTypes = {
      item: PropTypes.object.isRequired,
      item_icon: PropTypes.string.isRequired,
      isFetching: PropTypes.bool.isRequired,
      showGroupOnMap: PropTypes.func.isRequired,
      showGroupLigpleklist: PropTypes.func.isRequired,
      selectLigplek: PropTypes.func.isRequired,
      selected_ligplekken: PropTypes.array.isRequired,
      selected: PropTypes.bool.isRequired,
      show_list_id: PropTypes.number.isRequired,
      dispatch: PropTypes.func.isRequired, // todo: uitzoeken of dit gewoon wegkan.
  }

  componentDidMount() {
    this.setState({selected: this.props.selected});
  }

  stopClickInFilter(event) {
       event.preventDefault();
       return false;
  }

  renderLoading() {
    return <div>Ladend...</div>;
  }

  renderError() {
    return <div>Er is een fout opgetreden.</div>;
  }

  toggleShowContent() {
    this.props.showGroupLigpleklist(this.props.item.id);
    //this.setState({show_content: !this.state.show_content});  // todo: het zou allemaal via dispatch moeten
  }

  //showAllListOfListItems() {
  //  this.props.dispatch()
  //}

  setFilterValue(event) {
      this.setState({filter_value: event.target.value});
      this.doFiltering(event.target.value);
  }

  doFiltering(filter_value) {
      // console.log("ListOfListsItem doFiltering: this.state.filter_value: "+this.state.filter_value);
      console.log("ListOfListsItem doFiltering: this.state.filter_value: "+filter_value);
      if (filter_value.length>=1) {
        let re = new RegExp(filter_value, 'i');
        let newFilteredLigpleklist = [];
        this.props.item.ligplek_list.forEach(function(ligplek) {
              if(ligplek.name.search(re) >= 0 ||
                 ligplek.description.search(re) >= 0 ||
                 ligplek.description_long.search(re) >= 0){
                   newFilteredLigpleklist.push(ligplek);
              }
        });
        // console.log("newFilteredLigpleklist: "+newFilteredLigpleklist.length);
        this.setState({filteredLigpleklist: newFilteredLigpleklist })
      } else {
        this.setState({filteredLigpleklist: this.props.item.ligplek_list })
      }

  }

  toggleSelectCategorieOnMap() {
      // console.log("Toggle deze Ligplek Verzameling: "+this.props.item.id);
      this.setState(prevState => ({
                 selected: !prevState.selected
           }));
      this.props.showGroupOnMap(this.props.item.id);
  }

  toggleSelectLigplekOnMap(id) {
      this.props.selectLigplek(id);
      this.setState({"now": Date.now()});  // force redraw
  }

  renderListOfListsItemClosed() {
       const checkbox_id = "categorie-checkbox-"+this.props.item.id;
       return <li className="list-group-item" data-list-id={this.props.item.id}
                  url={this.props.item.ligplek_list_url}>
                 <div className="categorie-item" onClick={this.toggleShowContent} >
                   <img className="categorie-icon" src={this.props.item_icon} />
                   <div className="categorie-name-desc">
                     <div className="categorie-name">{this.props.item.name}</div>
                     <div className="categorie-desc d-none d-sm-block">{parse(this.props.item.description)}</div>
                   </div>
                 </div>
                 <div className="round list-of-lists">
                         <input type="checkbox" id={checkbox_id}
                                onChange={this.toggleSelectCategorieOnMap}
                                checked={this.state.selected} />
                         <label htmlFor={checkbox_id}></label>
                 </div>
              </li>
  }

  renderListOfListsItemOpen() {
       // console.log("renderLOL renderListOfListsItemOpen: " + this.state.filteredLigpleklist.length);
       const checkbox_id = "categorie-checkbox-"+this.props.item.id
       return <li className="list-group-item" data-list-id={this.props.item.id} ref={this.thisLi} >
                 <div className="categorie-item"  >
                   <img className="categorie-icon" src={this.props.item_icon} onClick={this.toggleShowContent} />
                   <div className="categorie-name-desc" >
                     <div className="categorie-name" onClick={this.toggleShowContent} >{this.props.item.name}</div>
                     <span>Filteren: <input type="text" placeholder="tiep om filteren"
                                            value={this.state.filter_value}
                                            onInput={this.setFilterValue}
                                            />
                     </span>
                   </div>
                 </div>
                 <div className="round list-of-lists">
                         <input type="checkbox" id={checkbox_id}
                                onChange={this.toggleSelectCategorieOnMap}
                                checked={this.state.selected} />
                         <label htmlFor={checkbox_id}></label>
                 </div>
                 <LigplekList isFetching={this.state.loading}
                              id={this.props.item.id}
                              ligpleklist={this.state.filteredLigpleklist}
                              list_data={this.props.item}
                              dispatch={this.props.dispatch}
                              selectLigplek={this.toggleSelectLigplekOnMap}
                              selected_ligplekken={this.props.selected_ligplekken} />
              </li>
  }

  render() {
    if (this.props.show_list_id>0) {
      return this.renderListOfListsItemOpen();
    } else{
      return this.renderListOfListsItemClosed();
    }
  }

}






class ListItem extends React.Component {

   constructor(props) {
      super(props)
      this.showLigplekDetails = this.showLigplekDetails.bind(this);
      this.toggleSelectOnMap = this.toggleSelectOnMap.bind(this);
      // this.state = {selected: this.props.selected};  // dit ververst 1 keer
   }

   static propTypes = {
      item: PropTypes.object.isRequired,
      selectLigplek: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
      selected: PropTypes.bool.isRequired,
   }

   state = {
     selected: this.props.selected
   }

   showLigplekDetails() {
       this.props.dispatch(showLigplek(this.props.item.id));
   }

   toggleSelectOnMap(event) {
       console.log("toggleSelectOnMap: "+this.props.item.id +"  "+this.state.selected );
       this.setState(prevState => ({
                 selected: !prevState.selected
       }));
       this.props.selectLigplek(this.props.item.id);
       console.log("toggleSelectCategorieOnMap: "+this.props.item.id +"  "+this.state.selected );
   }

   render() {
       // console.log(this.props.item.id+" this.props.selected: "+this.props.selected);
       this.state.selected = this.props.selected;
       var checkbox_id = "ligplek-checkbox-"+this.props.item.id;
       return ( <li className="list-group-item">
                 <div onClick={this.showLigplekDetails}>
                   <div className="ligplek-name-desc">
                     <div className="ligplek-name">{this.props.item.name}</div>
                     <div className="ligplek-desc d-none d-sm-block">
                         {parse(this.props.item.description)}</div>
                   </div>
                 </div>
                 <div className="round" >
                     <input type="checkbox" id={checkbox_id} checked={this.state.selected}
                            onChange={this.toggleSelectOnMap} />
                     <label htmlFor={checkbox_id}></label>
                 </div>
              </li>
       );
   }
}


class LigplekList extends React.Component {

  constructor(props) {
    super(props);
    this.selectLigplek = this.selectLigplek.bind(this);
  }

  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    ligpleklist: PropTypes.array.isRequired,
    list_data: PropTypes.object.isRequired,
    selectLigplek: PropTypes.func.isRequired,
    selected_ligplekken: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  selectLigplek(item_id) {
      this.props.selectLigplek(item_id)
      this.setState({"now": Date.now()});
  }

  renderLoading() {
    return <div>Ladend...</div>;
  }

  renderError() {
    return <div>Er is een Fout opgetreden. rapporteren bij lpow@steckelfisch.nl s.v.p.</div>;
  }

  renderEmptyList() {
    return <div>Geen Ligplekken</div>;
  }

  renderList() {
    // console.log("LigplekList renderList");
    // return this.props.list_data.ligplek_list.map( (ligplek) => {
    if(this.props.ligpleklist?.length == 0) {
        return this.renderEmptyList();
    }
    if(!this.props.ligpleklist) {
        return this.renderEmptyList();
    }
    return this.props.ligpleklist.map( (ligplek) => {
                      let is_item_checked = Boolean(false);
                      if (this.props.selected_ligplekken.length>0) {
                            is_item_checked = this.props.selected_ligplekken.find(function(ligplek_to_test){
                                                                            return ligplek_to_test.id == ligplek.id;
                                                                          });
                            if (is_item_checked) {
                               is_item_checked = true;
                            } else {
                               is_item_checked = false;
                            }
                      }
                      return <ListItem key={ligplek.id}
                                       item={ligplek}
                                       selectLigplek={this.selectLigplek}
                                       dispatch={this.props.dispatch}
                                       selected={is_item_checked} />
                   })

  }

  renderLigplekList() {
    if (this.props.isFetching) {
      return this.renderLoading();
    } else if (this.props.list_data.ligplek_list.length == 0) {
      return this.renderEmptyList();
    } else if (this.props.list_data.ligplek_list.length > 0) {
      return this.renderList();
    } else {
      return this.renderError();
    }
  }

  render() {
    return (
            <ul className="list-group ligplek-list">
              { this.renderLigplekList() }
            </ul>
    )
  }

}


class LigplekListWithFilter extends LigplekList {

  constructor(props) {
     super(props);
     this.setFilterValue = this.setFilterValue.bind(this);
     this.doFiltering = this.doFiltering.bind(this);
     this.renderFilteredList = this.renderFilteredList.bind(this);

     // console.log("LigplekListWithFilter.constructor");
  }

  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    list_data: PropTypes.object.isRequired,
    selectLigplek: PropTypes.func.isRequired,
    selected_ligplekken: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  state = {
     filter_value: "",
     filteredLigpleklist: []
  }

  componentDidMount(){
        this.setState({"filteredLigpleklist": this.props.list_data.ligplek_list});
  }

  setFilterValue(event) {
      this.setState({filter_value: event.target.value});
      this.doFiltering(event.target.value);
  }

  doFiltering(filter_value) {
      if (filter_value.length>0) {
        let re =new RegExp(filter_value, 'i');
        let newFilteredLigpleklist = [];
        this.props.list_data.ligplek_list.forEach( (ligplek) => {
              try {
                if(ligplek.name.search(re) >= 0 ||
                   ligplek.description.search(re) >= 0 ||
                   ligplek.description_long.search(re) >= 0){
                     newFilteredLigpleklist.push(ligplek);
                }
              } catch (e) {
                console.error(e)
              }
        });
        this.setState({"filteredLigpleklist": newFilteredLigpleklist});
      } else {
        this.setState({"filteredLigpleklist": this.props.list_data.ligplek_list});
      }

  }

  renderFilteredList(unfiltered_list) {
    console.log("this.state.filteredLigpleklist.length: "+this.state.filteredLigpleklist.length);
    if (this.state.filteredLigpleklist.length == 0) {
      return this.renderEmptyList();
    }
    let _ligplek_list_to_render = unfiltered_list;
    if(this.state.filter_value.length>0) {
       _ligplek_list_to_render = this.state.filteredLigpleklist;
    }
    if (_ligplek_list_to_render.length==0) {
       return this.renderEmptyList();
    }
    return _ligplek_list_to_render.map( (ligplek) => {
                      let is_item_checked = Boolean(false);
                      if (this.props.selected_ligplekken.length>0) {
                            is_item_checked = this.props.selected_ligplekken.find(
                                                                          function(ligplek_to_test){
                                                                             return ligplek_to_test.id == ligplek.id;
                                                                          });
                            if (is_item_checked) {
                               is_item_checked = true;
                            } else {
                               is_item_checked = false;
                            }
                      }
                      return <ListItem key={ligplek.id}
                                       item={ligplek}
                                       selectLigplek={this.selectLigplek}
                                       dispatch={this.props.dispatch}
                                       selected={is_item_checked} />
                   })
  }

  render() {
    console.log("LigplekListWithFilter.render this.props.list_data.ligplek_list:"+this.props.list_data.ligplek_list);
    return (<div>
                <span>Filteren: <input type="text" placeholder="tiep om filteren"
                                       value={this.state.filter_value}
                                       onInput={this.setFilterValue} />
                </span>
                <ul className="list-group ligplek-list">
                  { this.renderFilteredList(this.props.list_data.ligplek_list) }
                </ul>
            </div>
    );
  }
}


class ListOfListsPage extends React.Component {

  constructor(props) {
    super(props);
  }

  state = {
    show_content: false,
    show_list: 0,  // id van ligpleklijst
    show_list_item: 0 // id ligplek (data van ligplek)
  }

  showList(event) {
    //console.log('showList: '+event);
    this.props.showGroupLigpleklist(this.props.item.id);
    this.setState({show_content: !this.state.show_content});
  }

  showListItem(event) {
  }

  renderLoading() {
    return <div>Ladend...</div>;
  }

  renderError() {
    return <div>Er is een Fout opgetreden. rapporteren bij lpow@steckelfisch.nl s.v.p.</div>;
  }

  renderEmptyList() {
    return <div>Geen Ligplekken</div>;
  }

  renderEmptyListOfLists() {
    return <div>Geen Lijsten met Ligplekken</div>;
  }

  renderListOfLists() {
    if (this.props.isFetching) {
      return this.renderLoading();
    } else if (this.props.ligpleklist.length == 0) {
      return this.renderEmptyList();
    } else if (this.props.ligpleklist.length > 0) {
      return this.renderList();
    } else {
      return this.renderError();
    }
  }

  renderLigplekList() {
    if (this.props.isFetching) {
      return this.renderLoading();
    } else if (this.props.ligpleklist.length == 0) {
      return this.renderEmptyList();
    } else if (this.props.ligpleklist.length > 0) {
      return this.renderList();
    } else {
      return this.renderError();
    }
  }

  render() {
    return (
            <ul className="list-group ligplek-list">
              { this.renderLigplekList() }
            </ul>
    );
  }
}








module.exports = {
    ListOfLigplekLists,
    LigplekList,
    LigplekListWithFilter
}
