import React, { Component } from 'react'
import PropTypes from "prop-types"
import { FormGroup, Input, Label } from 'reactstrap'


class CategorieDropdown extends React.Component {

 constructor(props) {
    super(props);
    this.chooseOption = this.chooseOption.bind(this);
  }

  state = {
   categorielist: []
  }

  static propTypes = {
    onChange: PropTypes.func.isRequired,
  }

  componentDidMount() {
    let categorielist = JSON.parse(localStorage.getItem('lpowCategorien'))
    this.setState( {categorielist })
  }

  renderOptions() {
       // const categorielist =
       if(this.state.categorielist.length>0) {
         return  this.state.categorielist.map( categorie => {
              return <option  key={categorie.id} value={categorie.id}>{categorie.name}</option> })
       }
  }

  chooseOption(event) {
   //console.log('categorie dingen doen', e )
   this.props.onChange(event)
  }

  render() {
      return ( <Input type="select" name="categorie" id="id_categorie" required
                onChange={this.chooseOption} >
                <option>Kies catagorie</option>
                {this.renderOptions() }
               </Input>
              );
  }


}

class KarakterestiekDropdown extends React.Component {

 constructor(props) {
    super(props);
    this.chooseOption = this.chooseOption.bind(this);
  }

  state = {
    list: [],
  }

  static propTypes = {
    handleChange: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const karakterestieklist = JSON.parse(localStorage.getItem('lpowKarakterestieken'))
    this.setState( {list: karakterestieklist, })
  }

  renderOptions() {
       const theList = this.state.list
       if(theList.length>0) {
         return  theList.map( listitem => {
              return <option key={listitem.id} value={listitem.id}>{listitem.name}</option> })
       }
  }

  chooseOption(event) {
    this.props.handleChange(event)
  }

  render() {
      return ( <Input type="select" name="karakterestiek" id="id_karakterestiek" required
                      onChange={this.chooseOption}>
                <option>Kies karakterestiek</option>
                {this.renderOptions() }
               </Input>
              );
  }


}


class GenericDropdown extends React.Component {

 constructor(props) {
    super(props);
    this.chooseOption = this.chooseOption.bind(this);
  }

  state = {
   list: []
  }

  static propTypes = {
    dropdownName: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const storageKey = "lpow"+this.props.dropdownName
    // const list = JSON.parse(localStorage.getItem(storageKey))
    // this.setState( {list })
  }

  renderOptions() {
       // const theList = this.state.list
       if(this.state.list.length>0) {
         return  theList.map( listitem => {
              return <option key={listitem.id} value={listitem.id}>{listitem.name}</option> })
       }
  }

  chooseOption(e) {
   // console.log('list dingen doen', e )
   this.props.handleChange(e)
  }

  render() {
      thisId = "id_"+this.props.dropdownName
      return ( <Input type="select" name={this.props.dropdownName} id={thisId} onChange={this.chooseOption}>
                <option>Kies {this.props.dropdownName}</option>
                {this.renderOptions() }
               </Input>
              );
  }


}

module.exports = {
  CategorieDropdown: CategorieDropdown,
  KarakterestiekDropdown: KarakterestiekDropdown,
  GenericDropdown: GenericDropdown,
}

