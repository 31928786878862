
import * as profileActions from '../actions/ProfileActions';

const empty_profile = { user: {first_name: '',
                                     last_name: '',
                                     email: ''},
                                ship_name: '',
                                ship_img: '',
                                ship_img_thumbnail_url: ''};

// function profileReducer(state=[], action) {
const initialState = {
	// foo_bar: 1
}

const ProfileReducer= (state = initialState, action) => {

  var _profile = {};
  switch (action.type) {

    case profileActions.VIEW_PROFILE:
      return Object.assign({}, state,
                                { updating: false})

    case profileActions.EDIT_PROFILE:
      return Object.assign({}, state,
                                { updating: true})

    case profileActions.AUTH_PROFILE_SUCCES:
      localStorage.setItem('lpowProfile', JSON.stringify(action.profile));
      return Object.assign({},
                           state,
                           action.profile)

    case profileActions.AUTH_PROFILE_FAIL:
      return Object.assign({}, state,
                                action.profile)

    case profileActions.AUTH_PROFILE_UPDATE:
      return Object.assign({}, state.profile,
                               {})

    case profileActions.AUTH_PROFILE_UPDATE_SUCCES:
      localStorage.setItem('lpowProfile', JSON.stringify(action.profile));
      return Object.assign({}, state,
                               action.profile)

    case profileActions.AUTH_PROFILE_UPDATE_FAIL:
      return Object.assign({}, state,
                               state.profile)

    case profileActions.PROFILE_CLEAR:
      localStorage.removeItem('lpowProfile');
      return Object.assign({},
                           state,
                           empty_profile // { empty_profile,}
                          )


    default:
      return state
    }

}

//module.exports = {
//  profileReducer,
//}
export default ProfileReducer