// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `        div.jimbogo-container-list {
    padding-left: 30px;
    padding-right: 72px;
}

div.jimbogo-container {
    width: 66%;
    margin-left: auto;
    margin-right: auto;
    border-bottom: black;
    border-bottom-style: solid;
    border-bottom-width: 0.5px;
    margin-bottom: 10px;
    display: inline-grid;
    font-size: smaller;
}

div.jimbogo-container > span.heading {
    font-weight: bold;
}
div.jimbogo-container > span.heading > span.name {
    display: inline-block;
    width: calc(100% - 31px);
}

div.jimbogo-container > img.img-small {
    max-width: 250px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
}

div.jimbogo-container > span {
    display: block;
}

div.jimbogo-container > span > label{
    margin: 0px;
}

div.jimbogo-container> span.heading > span.brand > img.img-brand {
    width: 30px;
    float: right;
}
`, "",{"version":3,"sources":["webpack://./www/css/JimboGo.css"],"names":[],"mappings":"QAAQ;IACJ,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,oBAAoB;IACpB,0BAA0B;IAC1B,0BAA0B;IAC1B,mBAAmB;IACnB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,qBAAqB;IACrB,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":["        div.jimbogo-container-list {\n    padding-left: 30px;\n    padding-right: 72px;\n}\n\ndiv.jimbogo-container {\n    width: 66%;\n    margin-left: auto;\n    margin-right: auto;\n    border-bottom: black;\n    border-bottom-style: solid;\n    border-bottom-width: 0.5px;\n    margin-bottom: 10px;\n    display: inline-grid;\n    font-size: smaller;\n}\n\ndiv.jimbogo-container > span.heading {\n    font-weight: bold;\n}\ndiv.jimbogo-container > span.heading > span.name {\n    display: inline-block;\n    width: calc(100% - 31px);\n}\n\ndiv.jimbogo-container > img.img-small {\n    max-width: 250px;\n    height: auto;\n    margin-left: auto;\n    margin-right: auto;\n    margin-bottom: 5px;\n}\n\ndiv.jimbogo-container > span {\n    display: block;\n}\n\ndiv.jimbogo-container > span > label{\n    margin: 0px;\n}\n\ndiv.jimbogo-container> span.heading > span.brand > img.img-brand {\n    width: 30px;\n    float: right;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
