// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1.lpow_help {
    background: #f2f2f2;
    border-bottom: 1px solid #ccc;
    color: #20435c;
    padding: 10px 0 10px 10px;
    margin: 0;
    font-size: 25px;
}

.lpow_help_wrapper {
    max-width: 830px;
    margin: auto;
}

div.lpwo_help_main {
    padding-left: 10px;
    max-width: 800px;
}
p.git-hash {
   font-size: xx-small;
}


div.wrapper {
    padding-left: 10px;
}

div.main > p {
    text-align: left;
}

div.lpwo_help_main > div.wrapper > div.main > p {
    text-align: left;
}

body.lpow_help {
    font-family: Lucida, Helvetica, Arial, sans-serif;
    font-size: 12px;
    padding: 0;
    margin: 0;
}

h1.flatpage {
    display: none;
    background: #f2f2f2;
    border-bottom: 1px solid #ccc;
    color: #20435c;
    padding: 10px 0 10px 10px;
    margin: 0;
}


#xx_flatpage_wrapper {
    max-width: 830px;
}

#xx_flatpage_main {
    float: right;
    width: 800px;
}

.XXclearfix:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.clearfix {
    display: inline-block;
}

html[xmlns] .XXclearfix {
    display: block;
}

* html .clearfix {
    height: 1%;
}
`, "",{"version":3,"sources":["webpack://./www/css/LpowHelp.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,6BAA6B;IAC7B,cAAc;IACd,yBAAyB;IACzB,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;AACA;GACG,mBAAmB;AACtB;;;AAGA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iDAAiD;IACjD,eAAe;IACf,UAAU;IACV,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,cAAc;IACd,yBAAyB;IACzB,SAAS;AACb;;;AAGA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,WAAW;IACX,kBAAkB;IAClB,cAAc;IACd,SAAS;AACb;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,UAAU;AACd","sourcesContent":["h1.lpow_help {\n    background: #f2f2f2;\n    border-bottom: 1px solid #ccc;\n    color: #20435c;\n    padding: 10px 0 10px 10px;\n    margin: 0;\n    font-size: 25px;\n}\n\n.lpow_help_wrapper {\n    max-width: 830px;\n    margin: auto;\n}\n\ndiv.lpwo_help_main {\n    padding-left: 10px;\n    max-width: 800px;\n}\np.git-hash {\n   font-size: xx-small;\n}\n\n\ndiv.wrapper {\n    padding-left: 10px;\n}\n\ndiv.main > p {\n    text-align: left;\n}\n\ndiv.lpwo_help_main > div.wrapper > div.main > p {\n    text-align: left;\n}\n\nbody.lpow_help {\n    font-family: Lucida, Helvetica, Arial, sans-serif;\n    font-size: 12px;\n    padding: 0;\n    margin: 0;\n}\n\nh1.flatpage {\n    display: none;\n    background: #f2f2f2;\n    border-bottom: 1px solid #ccc;\n    color: #20435c;\n    padding: 10px 0 10px 10px;\n    margin: 0;\n}\n\n\n#xx_flatpage_wrapper {\n    max-width: 830px;\n}\n\n#xx_flatpage_main {\n    float: right;\n    width: 800px;\n}\n\n.XXclearfix:after {\n    content: \".\";\n    display: block;\n    clear: both;\n    visibility: hidden;\n    line-height: 0;\n    height: 0;\n}\n\n.clearfix {\n    display: inline-block;\n}\n\nhtml[xmlns] .XXclearfix {\n    display: block;\n}\n\n* html .clearfix {\n    height: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
