import { connect } from 'react-redux'
import { protDomPort } from '../actions/LpowActions'
import { showLigplek, toggleSelectLigplek, showVaarwegOnMap, showVaarwegLigplekList  } from '../actions/LpowActions'
import LigplekVaarweg from '../components/LigplekVaarweg'


const mapStateToProps = state => {
    return {
       isFetching: state.lpowState.ligplekkenByVaarweg.isFetching,
       list_data: state.lpowState.ligplekkenByVaarweg.ligplekkenByVaarweg,
       selected_ligplekken: state.lpowState.selected_ligplekken.ligplekken,
       selected_id_list: state.lpowState.selected_vaarwegen.vaarwegen,
       show_list_id: state.lpowState.ligplekkenByVaarweg.show_list_id,  // toon deze Vaarweg
       item_icon: protDomPort+"/img/vaarweg-default.png"
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch,
      // onderstaande functies worden via algemene functie-calls, gemapt naar specefieke uitvoeringen
      showGroupOnMap: id => {
        dispatch( showVaarwegOnMap(id));
      },
      showGroupLigpleklist: id => {
        dispatch( showVaarwegLigplekList(id));
      },
      selectLigplek: id => {
        dispatch( toggleSelectLigplek(id) );
      }

    }
}

const LigplekVaarwegContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LigplekVaarweg)

export default LigplekVaarwegContainer;
