import fetch from 'cross-fetch';
import { getCookie } from "../components/LpowUtils";
import { Auth } from "../components/Auth"
import {protDomPort, requestMode} from './LpowActions'
import { setCenter } from "./MapActions";
import { setMessage } from './MessageActions';
import { useMap } from 'react-leaflet';


export const REQUEST_CURRENT_POSITION = 'REQUEST_CURRENT_POSITION';
export const RECEIVE_CURRENT_POSITION = 'RECEIVE_CURRENT_POSITION';

export const SET_POSITION_SOURCE = 'SET_POSITION_SOURCE';
export const POSITION_HAND = 'POSITION_SELECTOR_HAND';
export const POSITION_ACCURACY_BY_HAND = -1; // illegal value for GPS accuracy
export const POSITION_GPS_WATCH = 'POSITION_SELECTOR_GPS_WATCH';
export const POSITION_GPS_ONCE = 'POSITION_SELECTOR_GPS_ONCE';
export const POSITION_GPS_OFF = 'POSITION_SELECTOR_GPS_OFF';


// export const START_WATCH_POSITION = 'START_WATCH_POSITION';
export const STARTING_WATCH_POSITION = 'STARTING_WATCH_POSITION';
export const START_WATCH_POSITION_SUCCES = 'START_WATCH_POSITION_SUCCESS';
export const START_WATCH_POSITION_FAIL = 'START_WATCH_POSITION_FAIL';
export const STOP_WATCH_POSITION = 'STOP_WATCH_POSITION';
export const SUSPEND_WATCH_POSITION = 'SUSPEND_WATCH_POSITION';
export const RESTART_SUSPENDED_WATCH_POSITION = 'RESTART_SUSPENDED_WATCH_POSITION';


export const SHARE_POSITION_START = "START_SHARE_POSITION"
export const SHARE_POSITION_SUCCESS = "SHARE_POSITION_SUCCES"
export const SHARE_POSITION_FAIL = "SHARE_POSITION_FAIL"




//export var testWatchId = null;

/* selecting position aquistion: HAND | GPS_WATCH | GPS_ONCE | .. */
export function setPositionSource(positionSource) {
  return { type: SET_POSITION_SOURCE,
           positionSource
         }
}


export function requestCurrentPosition() {
  return { type: REQUEST_CURRENT_POSITION,
           requestedPositionAt: Date.now(),
         }
}


export function receiveCurrentPosition(position) {
  return { type: RECEIVE_CURRENT_POSITION,
           position
         }
}

export function startingWatchPosition() {
  return { type: STARTING_WATCH_POSITION
         }
}

export function startWatchPositionSuccess(watchId) {
  console.log("startWatchPositionSuccess: "+watchId);
  return { type: START_WATCH_POSITION_SUCCES,
           watch_id: watchId
         }
}

export function suspendPositionWatch() {
  return { type: SUSPEND_WATCH_POSITION
         }
}

// KAN WEG
export function restartSuspendedPositionWatch() {
  return { type: RESTART_SUSPENDED_WATCH_POSITION
         }
}

export function stopWatchPosition() {
  return { type: STOP_WATCH_POSITION
         }
}

const watchOptions = {enableHighAccuracy: true,
                      timeout: 20000,
                      maximumAge: 0 // geen minuut
                     };

/**
  Stopping WatchPosition seems to be a problem on Android(9). (that could happen when ShipMarker is moved by hand)
  Thus to indicate that that WatchPosition is still active, PositionSource is set to GPS_WATCH
**/
function watchPositionSuccess(position) {
        console.log("watchPositionSuccess: "+position);
}

function watchPositionFail(error) {
       console.log("watchPositionFail: "+error.code +" "+error.message);
}

export function startWatchPosition() {
    return function (dispatch) {
       console.log("Starting startWatchPosition");
       dispatch(startingWatchPosition());
       dispatch(setPositionSource(POSITION_GPS_WATCH));
       let watchId = navigator.geolocation.watchPosition(
                                       position =>  { // let time_sec = Math.floor(Date.now() / 1000);
                                                      // console.log(time_sec+" watchPositionSuccess: "+position);

                                                      dispatch(receiveCurrentPosition(position));
                                                      let new_center = [position.coords.latitude,
                                                                        position.coords.longitude];
                                                      dispatch(setCenter(new_center));
                                                      console.log("DELEN POSTITIE START "+position);
                                                      dispatch(sharePosition(position));
                                                      },
                                       error => { // dispatch(watchPositionFail(error));
                                                  console.log("watchPositionFail: "+error.code +" "+error.message);
                                                  const new_message = {
                                                      message:"Fout bij verkrijgen Locatie.\n("+error.message+")\nStaat de GPS aan? Mag de locatie opgevraagd worden?\n\nGPS Postitie Update wordt gestopt",
                                                      next: true,
                                                      error: false,
                                                      icon: "info",
                                                      duration:6000,
                                                  };
                                                  console.error("WatchPosition Error Message "+new_message.message);
                                                  dispatch(setMessage(new_message));
                                                  dispatch(stopWatchPosition());
                                                  },
                                       watchOptions
       );
       console.log("To Dispatch Success with watchId: "+watchId);
       dispatch(startWatchPositionSuccess(watchId));
       // console.log("return watchId: "+watchId);
    }
}


export function zoomToPositionFail(error) {
    const dummy_position = {
        coords: {latitude: 51.6,
                 longitude: 5.6,
                 altitude: 0,
                 accuracy: 9999,
                 altitudeAccuracy: 9999,
                 heading: 0,
                 speed:0},
        timestamp: Date.now()
    }

    return { type: ZOOM_TO_POSITION_FAIL,
             error,
             position: dummy_position
           }
}


export function zoomToPositionSucces() {
  return { type: ZOOM_TO_POSITION_SUCCES }
}


export function setPositionPopupShow(value) {
  return { type: SET_LOCATION_POPUP_SHOW,
           value,
         }
}



// update Position without the Zoom setting
export function updateCurrentPosition() {
  return function (dispatch) {
      console.log("Starting updateCurrentPosition");
      // initial action
      // inform that the API call is starting.
      dispatch(requestCurrentPosition());
      dispatch(setPositionSource(POSITION_GPS_ONCE));
      return navigator.geolocation.getCurrentPosition(
                                       position =>  { // console.log(watchId+" move to requestPositionSuccess: "+position);
                                                      // dispatch(requestPositionSucces(position));
                                                      dispatch(receiveCurrentPosition(position));
                                                      let new_center = [position.coords.latitude,
                                                                        position.coords.longitude];
                                                      dispatch(setCenter(new_center));
                                                      },
                                       error => { // dispatch(requestPositionFail(error));
                                                  console.log("move to WatchPositionFail: "+error.code +" "+error.message);
                                                  const new_message = {
                                                      message:"Fout bij verkrijgen Locatie.\n("+error.message+")\nStaat de GPS aan? Mag de locatie opgevraagd worden?",
                                                      next: true,
                                                      error: false,
                                                      icon: "info",
                                                      duration:6000,
                                                  };
                                                  console.error("update Once Message "+new_message);
                                                  dispatch(setMessage(new_message));
                                                  dispatch(stopWatchPosition());
                                                  },
                                       watchOptions
      )
  }
}

//export function calculateCurrentDistance() {
//  return { type: CALCULATE_CURRENT_DISTANCE,
//           caclulatedAt: Date.now(),
//         }
//}

export function sharePositionSucces(data) {
    return { type: SHARE_POSITION_SUCCESS,
             data
           }
}

export function sharePositionFail(data) {
    return { type: SHARE_POSITION_FAIL,
             data
           }
}

export function sharePosition(newPosition) {
    let requestOptions = { }; // foo: " bar" };
    let lpowAuth = localStorage.getItem('lpowAuth');
    if (lpowAuth != null) { // Using the locally stored data
      const _lpowAuth = JSON.parse(lpowAuth);
      const token_key = _lpowAuth.token_key;

      requestOptions = {
        method: 'POST',
        mode: requestMode,
        headers: { 'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Token '+token_key
                 },
       body: JSON.stringify(newPosition)
      };
    } else {
       // throw "Dit Zou niet Moeten Zijn"
       return; // Geen authenticatie, geen opsturen. De kop voor aanzetten zou al niet zichtbaar moeten zijn.
    }
    console.log("REQUEST OPTIONS: "+requestOptions)
    // Send Position to server.

    return function (dispatch) {
      return fetch(protDomPort+"/lpow/api/root-new/ship-spacetime/", requestOptions)
             .then(
                  (response) => {
                                 if (response.status == 200)  {
                                     response.json().then(function(data) {
                                       dispatch(sharePositionSucces(data));
                                     });
                                 } else if (response.status == 401)  {
                                     const errormsg = {'msg': "Authenticatie Fout Op dE Server"}
                                     dispatch(sharePositionFail(errormsg));
                                 } else if (response.status == 404)  {
                                     const errormsg = {'msg': "Service Fout Op dE Server"}
                                     dispatch(sharePositionFail(errormsg));
                                 } else {
                                     console.log("ResponseStatus "+response.status)
                                     response.json().then(function(data) {
                                       // console.log(data);
                                       // non_field_errors
                                       // email
                                       dispatch(sharePositionFail(data));
                                     });
                                 }
                              },
                  error => dispatch(sharePositionFail(error)) )
    }


}


