import React, { Component } from 'react';
import PropTypes from "prop-types";


import {
  MapContainer, useMap, useMapEvents,
  LayerGroup,  LayersControl,
  Marker,  Popup,  TileLayer, GeoJSON
  } from 'react-leaflet';
import { useDispatch } from 'react-redux';

const { BaseLayer, Overlay } = LayersControl;

import { ErrorBoundary } from "../components/LpowUtils"; // Deze ook naar MapActionsReducer
import { LpowLayerGroup, LpowMarker} from "../components/LpowOverlayModule";

import { createLigplek } from "../actions/LpowActions";
import { setZoomLevel, setCenter, showOverlay, hideOverlay} from "../actions/MapActions";

// import { setMessage, } from '../actions/MessageActions';
import { ShipMarker, ShipMarkerShade } from './LpowShipMarker';


function MapEventHandler() {
  const dispatch = useDispatch();
  const map = useMapEvents({
    zoomend(e) { // to use oom level in non-LpowMap stuff (e.g LpowNavBar)
      // console.log("MapEventHandler zoom End ");
      dispatch(setZoomLevel(map.getZoom()));
      let _center = map.getCenter();
      let new_center = [_center.lat,
                        _center.lng];
      dispatch(setCenter(new_center)); // Houdt de kaart op plek als een andere menu optie ertussen komt
    },

    dragend(e) {
      // console.log("MapEventHandler drag End ");
      let _center = map.getCenter();
      let new_center = [_center.lat,
                        _center.lng];
      dispatch(setCenter(new_center)); // Houdt de kaart op plek als een andere menu optie ertussen komt
    },

    overlayadd(event) {
      let overlay_name = "overlay_"+event.name.toLowerCase().replace(/ /g,'');
      dispatch(showOverlay(overlay_name));
    },

    overlayremove(event) {
      let overlay_name = "overlay_"+event.name.toLowerCase().replace(/ /g,'');
      dispatch(hideOverlay(overlay_name));
    },

    dblclick(event) {
      console.log("MAP DUBBEL KLICK: "+event);
    }
  })
  // We Rendere Nothing, We only Need The Events
  return null
}

// from https://stackoverflow.com/questions/64665827/react-leaflet-center-attribute-does-not-change-when-the-center-state-changes
// props of MapContainer are immutable, hence:
function ChangeView({ center, zoom }) {
  const map = useMap();
  map.panTo(center);
  // map.flyTo(center);
  map.setView(center, zoom);
  return null;
}


class LpowMap extends Component {

  constructor(props) {
    super(props);
    this.render_adopted_ligplekken = this.render_adopted_ligplekken.bind(this);
    // console.log("LpowMap Constructor");
    this.icon_shade = 'img/ship-marker-shade.png';
  }

  static propTypes = {
    zoom: PropTypes.number.isRequired,
    center: PropTypes.array.isRequired,
    position: PropTypes.object.isRequired,

    base_layer_mapnik: PropTypes.bool.isRequired,
    base_layer_black_white: PropTypes.bool.isRequired,

    overlay_alleligplekken: PropTypes.bool.isRequired,
    ligplek_list: PropTypes.array.isRequired,
    lpow_markers: PropTypes.array.isRequired,

    overlay_indebuurt: PropTypes.bool.isRequired,
    lpow_markers_at_location: PropTypes.array.isRequired,

    overlay_geselecteerdeligplekken: PropTypes.bool.isRequired,
    selected_lpow_markers: PropTypes.array.isRequired,

    overlay_zoekresultaat: PropTypes.bool.isRequired,
    search_result_lpow_markers: PropTypes.array.isRequired,

    overlay_favorieteligplekken: PropTypes.bool.isRequired,
    favourite_lpow_markers: PropTypes.array.isRequired,

    overlay_adoptedligplekken: PropTypes.bool.isRequired,
    adopted_lpow_markers: PropTypes.array.isRequired,

    overlay_createdligplekken: PropTypes.bool.isRequired,
    created_lpow_markers: PropTypes.array.isRequired,

    overlay_categorieen: PropTypes.bool.isRequired,
    lpow_markers_categorieen: PropTypes.array.isRequired,

    overlay_vaarwegen: PropTypes.bool.isRequired,
    lpow_markers_vaarwegen: PropTypes.array.isRequired,

    location_popup_show: PropTypes.bool.isRequired,
  }

  render_adopted_ligplekken() {
    try {
          return this.props.adopted_lpow_markers.map( ligplek => {
                                                        let key = "lpow-dpt-"+ligplek.id;
                                                        return <LpowMarker key={key}
                                                                           data={ligplek}
                                                                           dispatch={this.props.dispatch} />
                                                      })
    }
    catch (error) {
        console.error("Adopted Ligplekken Zijn Niet Goed.");
    }
  }


  render() {
    return (
     <ErrorBoundary>
      <MapContainer id="map"
                   center={this.props.center}
                   zoom={this.props.zoom}
                   maxZoom="18"
                  >

        <ChangeView center={this.props.center} zoom={this.props.zoom} />
        <MapEventHandler  />

        <LayersControl position="topright" >
          <BaseLayer name="LPOW.OSM.Mapnik" checked={this.props.base_layer_mapnik} >
            <TileLayer
              attribution="&amp;copy LPOW/OSM cache"
              url="https://ligplekkenonderweg.lvbhb.nl/osm-proxy/{z}/{x}/{y}.png" />
          </BaseLayer>

          <LayerGroup name="Alle Schepen" setZIndex="0">
              { this.props.overlay_indebuurt ? null : <ShipMarker state_position={this.props.position} />  }
              <ShipMarkerShade position={this.props.position} icon_url="img/ship-marker-shade.png" />

          </LayerGroup>

          <Overlay name="In de Buurt" id="overlay_indebuurt"
                   checked={this.props.overlay_indebuurt} >

              <LayerGroup setZIndex="0">
                   <ShipMarker state_position={this.props.position} />
                   {this.props.lpow_markers_at_location.map( ligplek => {
                                                             let key = "lpow-near-loc-"+ligplek.id;
                                                             return <LpowMarker key={key}
                                                                                data={ligplek}
                                                                                zIndexOffset="0"
                                                                                dispatch={this.props.dispatch} />
                                                             }
                                                            )}
              </LayerGroup>
          </Overlay>

         <Overlay name="Alle Ligplekken"
                   id="overlay_alleligplekken"
                   checked={this.props.overlay_alleligplekken} >

              <LayerGroup setZIndex="0">
                {this.props.ligplek_list.map((ligplek) => {
                     return <LpowMarker key={"selected-"+ligplek.id}
                                        data={ligplek}
                                        dispatch={this.props.dispatch} />
                     }
                )}
              </LayerGroup>


         </Overlay>



          <Overlay name="Geselecteerde Ligplekken"
                   id="overlay_geselecteerdeligplekken"
                   checked={this.props.overlay_geselecteerdeligplekken} >
           <LayerGroup>
                {this.props.selected_lpow_markers.map((ligplek) => {
                     return <LpowMarker key={"selected-"+ligplek.id}
                                        data={ligplek}
                                        dispatch={this.props.dispatch} />
                     }
                )}
           </LayerGroup>
          </Overlay>

          <Overlay name="Geselecteerde Categorien" id="overlay_categorieen"
                   checked={this.props.overlay_categorieen} >
               <LpowLayerGroup prefix="selected_by_cat"
                               data={this.props.lpow_markers_categorieen}
                               dispatch={this.props.dispatch} />
          </Overlay>

          <Overlay name="Geselecteerde Vaarwegen" id="overlay_vaarwegen"
                   checked={this.props.overlay_vaarwegen} >
               <LpowLayerGroup  prefix="selected_by_vrwg"
                                data={this.props.lpow_markers_vaarwegen}
                                dispatch={this.props.dispatch} />
          </Overlay>

          <Overlay name="Favoriete Ligplekken" id="overlay_favorieteligplekken"
                   checked={this.props.overlay_favorieteligplekken} >
               <LayerGroup>
                {this.props.favourite_lpow_markers.map( ligplek => {
                        return <LpowMarker key={"lpow-fav-"+ligplek.id}
                                           data={ligplek}
                                           dispatch={this.props.dispatch} />
                      }
                 )}
               </LayerGroup>
          </Overlay>

          <Overlay name="Geadopteerde Ligplekken" id="overlay_geadopteerdeligplekken"
                   checked={this.props.overlay_adoptedligplekken} >
               <LayerGroup>
                { this.render_adopted_ligplekken }
               </LayerGroup>
          </Overlay>

          <Overlay name="Aangebrachte Ligplekken" id="overlay_createdligplekken"
                   checked={this.props.overlay_createdligplekken} >
               <LayerGroup>
                {this.props.created_lpow_markers.map( ligplek => {
                        let key = "lpow-created-"+ligplek.id;
                        ligplek.lat = ligplek.latitude; // hier passen we de gecreerde ligplek minimaal aan
                        ligplek.lng = ligplek.longitude; // zodat ze door LigplekMadal en LpowMarker getoond kan worden
                        // ligplek.categorie_icon_url = "/img/favourite_2_512x512.png"; // een hartje omdatie van jezelf is
                        // ligplek.categorie_icon_url = "/img/aangebracht.png"; // een gele, omdat ie dan beter opvalt
                        ligplek.categorie_icon_url = "/img/aangebracht_ster_512x512.png"// geel met ster, want mooier
                        ligplek.adopted_by_name = ""; //"Niemand nog"; // kan de aanbrenger de plek gelijk adopteren
                        return <LpowMarker key={key} // LpowCreatedMarker (zodatie verwijderd kan worden vanuit de popup)
                                           data={ligplek}
                                           dispatch={this.props.dispatch} />
                      }
                 )}
               </LayerGroup>
          </Overlay>


          <Overlay name="Zoek resultaat" id="overlay_zoekresultaat"
                   checked={this.props.overlay_zoekresultaat} >
           <LayerGroup>
              {this.props.search_result_lpow_markers.map( ligplek => {
                   let key = "lpow-searchresult-"+ligplek.id;
                   return  <LpowMarker key={key}
                                       data={ligplek}
                                       dispatch={this.props.dispatch} />
                  }
              )}
           </LayerGroup>
          </Overlay>
        </LayersControl>

      </MapContainer>
     </ErrorBoundary>
    )
  }
}


export default LpowMap;
