import { connect } from 'react-redux'
import LpowNavBar from "../components/LpowNavBar";
// import { precisionOnClass } from "../components/LpowUtils"; // Deze ook naar MapActionsReducer


const mapStateToProps = state => {
    return { zoom: state.lpowMap.zoom,
             position: state.position,
             token: (state.auth?.token_key ? state.auth.token_key : ''),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch,
    }
}


const LpowNavBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LpowNavBar)

export default LpowNavBarContainer
