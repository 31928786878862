import React, { Component } from 'react'
import PropTypes from "prop-types"
import Switch from "react-switch";
import { Form, FormGroup, Input, Label } from 'reactstrap'
import { FilterDraft, FilterDistance, FilterDateLastChanged } from "./LpowFilters"
import { setSettingDistance, setSettingDraft, setDateLastChanged,
         setRefreshData, setLigplekOrder, storeSettings } from "../actions/SettingsActions"
import { setSettingZoomManual, setSettingZoomExternal} from "../actions/SettingsActions"


class ZoomManualSelect extends Component {

  state = {
      // value: false,
  }

  constructor(props) {
    super(props);
    this.state.value = this.props.value
    console.log("this.props.value: "+this.props.value)
  }

  static propTypes = {
     value: PropTypes.number.isRequired,
     setValue: PropTypes.func.isRequired
  }

  render() {
     return (
         <FormGroup>
             <Label className="lpow-label">Hand, Zoom naar </Label>
             <Input type="select"
                    name="zoom_manual" id="id_zoom_manual"
                    className="lpow-input"
                    value={this.props.value} onChange={this.props.setValue} >
               <option value="7">7</option>
               <option value="10">10</option>
               <option value="17">17</option>
             </Input>
         </FormGroup>
     )
  }

}

class ZoomExternalSelect extends Component {

  state = {
      // value: false,
  }

  constructor(props) {
    super(props);
    this.state.value = this.props.value
    console.log("this.props.value: "+this.props.value)
  }

  static propTypes = {
     value: PropTypes.number.isRequired,
     setValue: PropTypes.func.isRequired
  }

  render() {
     return (
         <FormGroup>
             <Label className="lpow-label">Extern, Zoom naar </Label>
             <Input type="select"
                    name="zoom_external" id="id_zoom_external"
                    className="lpow-input"
                    value={this.props.value} onChange={this.props.setValue} >
               <option value="7">7</option>
               <option value="14">14</option>
               <option value="17">17</option>
             </Input>
         </FormGroup>
     )
  }

}


class OrderSelect extends Component {

  state = {
      // value: false,
  }

  constructor(props) {
    super(props);
    this.state.value = this.props.value
    console.log("this.props.value: "+this.props.value)
  }

  static propTypes = {
     value: PropTypes.number.isRequired,
     setValue: PropTypes.func.isRequired
  }

  render() {
     return (
         <FormGroup>
             <Label className="lpow-label">Sorteren op </Label>
             <Input type="select" name="order" id="id_order"
                     className="lpow-input"
                    value={this.props.value} onChange={this.props.setValue} >
               <option value="1">Afstand</option>
               <option value="2">Diepgang</option>
               <option value="3">Datum Aangepast</option>
             </Input>
         </FormGroup>
     )
  }

}


class ToggleRefreshData extends Component {

  state = {
      // value: false,
  }

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.state = {
          value: this.props.value
    }
  }

  static propTypes = {
     value: PropTypes.bool,
     setValue: PropTypes.func,
  }

  onToggle(value) {
    const newValue = !this.state.value
    //this.setState({ value: newValue });
    this.props.setValue(newValue)
  }

  render() {
    return (
         <div>
         <FormGroup>
         <Label className="lpow-label">Ververs data bij opstarten </Label>
         <div className="lpow-input">
         <Switch className="lpow-input react-switch"
              checked={ this.props.value || false }
              onChange={this.onToggle} />
         </div>
         </FormGroup>
         </div>
     )
  }
}


class LpowSettings extends Component {

  constructor(props) {
    super(props);
    this.setOrderValue = this.setOrderValue.bind(this)
    this.setDistanceFilterValue = this.setDistanceFilterValue.bind(this)
    this.setDraftFilterValue = this.setDraftFilterValue.bind(this)
    // this.setShipLengthFilterValue = this.setShipLengthFilterValue.bind(this)
    this.setRefreshDataValue = this.setRefreshDataValue.bind(this)
    this.setDateLastChangedValue = this.setDateLastChangedValue.bind(this)
    this.setZoomManualValue = this.setZoomManualValue.bind(this)
    this.setZoomExternalValue = this.setZoomExternalValue.bind(this)

    console.log("LpowSettings dateLastChanged: "+this.props.dateLastChanged);
  }

  static propTypes = {
     refreshDataAtStartup: PropTypes.bool.isRequired,
     order: PropTypes.number.isRequired,
     draftFilter: PropTypes.number.isRequired,
     // shipLengthFilter: PropTypes.number.isRequired,
     distanceFilter: PropTypes.number.isRequired,
     dateLastChanged: PropTypes.number.isRequired,
     zoomManual: PropTypes.number.isRequired,
     zoomExternal: PropTypes.number.isRequired,
  }

  state = {
  }

  componentWillUnmount() {
     console.log("Instellingen worden verlaten: Bewaren.")
     this.props.dispatch(storeSettings());
  }

  setOrderValue(event) {
      this.props.dispatch(setLigplekOrder(event.target.value));
  }

  setDraftFilterValue(event) {
      this.props.dispatch(setSettingDraft(event.target.value));
  }

  setDistanceFilterValue(event) {
      this.props.dispatch(setSettingDistance(event.target.value));
  }

  setZoomManualValue(event) {
      this.props.dispatch(setSettingZoomManual(event.target.value));
  }

  setZoomExternalValue(event) {
      this.props.dispatch(setSettingZoomExternal(event.target.value));
  }

  setRefreshDataValue(value) {
      this.props.dispatch(setRefreshData(value));
  }

  setDateLastChangedValue(value) {
      // this.setState({dateLastChanged: value})
      //console.log("setDateLastChangedValue: "+value);
      this.props.dispatch(setDateLastChanged(value));
  }

//       <ToggleRefreshData value={this.props.refreshDataAtStartup} setValue={this.setRefreshDataValue} />
//       <FilterDateLastChanged value={_dateLastChangedFilterValue} setFilterValue={this.setDateLastChangedValue} />
/*
          <div className="zoomValues">
             <ZoomManualSelect value={this.props.zoomManual}
                               setValue={this.setZoomManualValue} />
             <ZoomExternalSelect value={this.props.zoomExternal}
                                 setValue={this.setZoomExternalValue} />
          </div>
*/
  render() {
    var _draftFilterValue = parseInt(this.props.draftFilter);
    var _distanceFilterValue = parseInt(this.props.distanceFilter);
    var _orderSelected = parseInt(this.props.order);
    // var _zoomManualSelected = parseInt(this.props.zoomManual);
    // var _zoomExternalSelected = parseInt(this.props.zoomExternal);

    //var _dateLastChangedFilterValue = new Date(this.props.dateLastChanged);

    console.log("this.props.zoomManual: "+this.props.zoomManual);
    console.log("this.props.zoomExternal: "+this.props.zoomExternal);
    return (
     <div className="small-not-on-mobile">
        <Form>
          <OrderSelect value={this.props.order} setValue={this.setOrderValue} />
          <div className="filterValues">
             <FilterDraft value={this.props.draftFilter}
                          setFilterValue={this.setDraftFilterValue} />
             <FilterDistance value={this.props.distanceFilter}
                             setFilterValue={this.setDistanceFilterValue}
                             position={this.props.position} />
          </div>
        </Form>
     </div>
    );
  }
}


export default LpowSettings;
