import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import { LigplekList, } from "../components/LigplekListModule"
import { showLigplek, toggleFavouriteLigplek  } from '../actions/LpowActions'


class FavorietList extends React.Component {

  constructor(props) {
    super(props);
  }

  static propTypes = {
      isFetching: PropTypes.bool.isRequired,
      list_data: PropTypes.object.isRequired,
      // showLigplek: PropTypes.func.isRequired,
      toggleFavouriteLigplek: PropTypes.func.isRequired,
  }

  render() {
    return (
        <div className="panel panel-primary favourites-list" id="result_panel">
          <div className="panel-heading">
             <h3 className="panel-title">Favorieten</h3></div>
          <div className="panel-body">
            <LigplekList isFetching={this.props.isFetching}
                         ligpleklist={this.props.list_data.ligplek_list}
                         list_data={this.props.list_data}
                         dispatch={this.props.dispatch}
                         selectLigplek={this.props.toggleFavouriteLigplek} // selecteren staat gelijk aan verwijderen uit favorieten lijst
                         selected_ligplekken={this.props.list_data.ligplek_list} // alle favoriete ligplekken krijgen een 'checked'
                         />
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
    // console.log("Do FavorietList state ", state.lpowState);
    let list = state.lpowState.favourite_ligplekken.ligplekken ? state.lpowState.favourite_ligplekken.ligplekken : [];
    const list_data = {
       name: "Favorieten",
       ligplek_list: list,
    }

    return {
       isFetching: state.lpowState.favourite_ligplekken.isFetching,
       list_data,
       lastUpdate: Date.now(),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch,
      // onderstaande functies worden via algemen functie-calls, gemapped naar specefieke uitvoeringen
      toggleFavouriteLigplek: id => {
         dispatch( toggleFavouriteLigplek(id ) )
      }

    }
}

const FavorietListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FavorietList)

export default FavorietListContainer;
