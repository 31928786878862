export const REQUEST_SETTINGS = 'REQUEST_SETTINGS';
export const RECEIVE_SETTINGS = 'RECEIVE_SETTINGS';
export const STORE_SETTINGS = 'STORE_SETTINGS';
export const SET_LIGPLEK_ORDER = 'SET_LIGPLEK_ORDER';

export const SET_SETTING_DRAFT = 'SET_SETTING_DRAFT';
export const SET_SETTING_DISTANCE = 'SET_SETTING_DISTANCE';
export const SET_SETTING_SHIP_LENGTH = 'SET_SETTING_SHIP_LENGTH';

export const SET_SETTING_ZOOM_MANUAL = 'SET_SETTING_ZOOM_MANUAL';
export const SET_SETTING_ZOOM_EXTERNAL = 'SET_SETTING_ZOOM_EXTERNAL';

export const ZOOM_MANUAL_DEFAULT = 10;
export const ZOOM_EXTERNAL_DEFAULT = 14;

export function fetchSettings() {

  return function (dispatch) {
      // initial action
      var lpowSettings = localStorage.getItem('lpowSettings');
      console.log("LpowSettingsContainer: "+lpowSettings);
      try {
         lpowSettings = JSON.parse(lpowSettings);
      } catch (e) {
                 console.error("Using empty Settings because of error: "+e+" "+lpowSettings);
                 lpowSettings = []; }
      if (lpowSettings) { // Using the locally stored data
          dispatch(receiveSettings(lpowSettings));
      }
      else {
          // for now:
          // initialize an empty favourite list.
          // if we are logged in, a serverside stored favourite list could be retrieved
          const json= []
          localStorage.setItem('lpowSettings', JSON.stringify(json));
          dispatch(receiveSettings(json));
          /* inform that the API call is starting.
          dispatch(requestSelectedLigplekken())
          return fetch('///'+siteDomain+'/lpow/api/selected-ligplek-list/')
                .then(
                      response => response.json(),
                      // Do not use catch,... https://github.com/facebook/react/issues/6895
                      error => console.log('An error occurred.', error)
                )
                .then(
                    // followup action
                    json=> { localStorage.setItem('lpowFavouriteLigplekken', JSON.stringify(json));
                             dispatch(receiveFavouriteLigplekken(json)) }
                )
          }
          */
      }
  }

}

export function storeSettings() {
  return { type: STORE_SETTINGS

         }
}

export function receiveSettings(json) {
  return { type: RECEIVE_SETTINGS,
           settings: json,
           receivedAt: Date.now(),
         }
}

export function setLigplekOrder(value) {
  return { type: SET_LIGPLEK_ORDER,
           value,
         }
}


export function setSettingDistance(value) {
  return { type: SET_SETTING_DISTANCE,
           value,
         }
}

export function setSettingZoomManual(value) {
  return { type: SET_SETTING_ZOOM_MANUAL,
           value,
         }
}

export function setSettingZoomExternal(value) {
  return { type: SET_SETTING_ZOOM_EXTERNAL,
           value,
         }
}

export function setSettingDraft(value) {
  return { type: SET_SETTING_DRAFT,
           value,
         }
}

