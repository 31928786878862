export const DISPLAY_LIGPLEK = 'SHOW_LIGPLEK';
export const HIDE_LIGPLEK = 'HIDE_LIGPLEK';
export const CREATE_LIGPLEK = 'CREATE_LIGPLEK';
// export const REMOVE_CREATED_LIGPLEK = 'REMOVE_CREATED_LIGPLEK';
export const UPDATE_LIGPLEK = 'UPDATE_LIGPLEK';
export const SHOW_ADOPT_LIGPLEK = 'SHOW_ADOPT_LIGPLEK';


// todo: alle functienamen moeten hier aangepast worden
// bv updateLigplek -> showUpdateLigplek
//    showLigplek -> showDetailsLigplek



export function showLigplek(id) {
  return { type: DISPLAY_LIGPLEK,
           isOpen: true,
           id,
         }
}

export function updateLigplek(id, ligplek) {
  return { type: UPDATE_LIGPLEK,
           isOpen: true,
           id //,
           // ligplek
         }
}

export function hideLigplek() {
  return { type: HIDE_LIGPLEK,
           isOpen: false
         }
}

export function showAdoptLigplek(ligplekId, token_key) {
  return { type: SHOW_ADOPT_LIGPLEK,
           id: ligplekId,
           token_key
         }
}
