import * as messageActions from '../actions/MessageActions';


const initialState = {
    message: "Foo Bar",
    next: false,
    error: true,
    icon: "warning",
    durationInSeconds: 2
}

const MessageReducer= (state = initialState, action) => {
  // console.log("MessageReducer: "+action.type);

  switch (action.type) {

    case messageActions.SET_MESSAGE:
      return Object.assign({},
                           state,
                           action.message_state)

    default:
      return state

  }
}

export default MessageReducer
