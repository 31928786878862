import LpowSettings from "../components/LpowSettings"
import { connect } from 'react-redux'


const mapStateToProps = state => {
    let _settings = state.settings;
    let orderSelected = _settings.order ? parseInt(_settings.order) : 1;
    let zoomManualSelected = _settings.zoom_manual ? parseInt(_settings.zoom_manual) : 10;
    let zoomExternalSelected = _settings.zoom_external ? parseInt(_settings.zoom_external) : 14;
    let distanceFilterSelected = _settings.distance_filter ? parseInt(_settings.distance_filter) : 9999;
    let draftFilterSelected = _settings.draft_filter ? parseInt(_settings.draft_filter) : 0;
    let dateLastChangedSelected = _settings.date_last_changed ? Date.parse(_settings.date_last_changed) : Date.now();
    let refreshDataSelected = _settings.refresh_data ? _settings.refresh_data : false;

    // console.log("zoomManualSelected: "+zoomManualSelected);

    return {
        refreshDataAtStartup: refreshDataSelected,
        dateLastChanged: dateLastChangedSelected,
        order: orderSelected,
        draftFilter: draftFilterSelected,
        distanceFilter: distanceFilterSelected,
        position: state.position,
        zoomManual: zoomManualSelected,
        zoomExternal: zoomExternalSelected
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    }
}


const LpowSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LpowSettings)

export default LpowSettingsContainer
