import React, { Component } from 'react'
import PropTypes from "prop-types"
import {LpowHelpTopic, LpowVersion} from './LpowHelp'
import {protDomPort } from '../actions/LpowActions'

class LpowAbout extends Component {

  constructor(props) {
    super(props);
  }

  static propTypes = {
     version: PropTypes.string.isRequired,
     date: PropTypes.string.isRequired,
     buildinfo: PropTypes.object.isRequired,
  }

  state = {
  }

  render() {
    return (
        <div className="panel panel-primary lpow-help" id="result_panel">
          <div className="panel-body">
              <div className="lpow-help">
                 <LpowVersion   version={this.props.version} //versie uit config.xml
                                buildinfo={this.props.buildinfo} // gegenereerde Bouw info
                                xurl="https://ligplekkenonderweg.lvbhb.nl/page/colofon/"
                                url="/page/colofon/" />
                 <LpowHelpTopic title="Registreren en Inloggen"
                                url="https://ligplekkenonderweg.lvbhb.nl/page/signup-signin/" />
                 <LpowHelpTopic title="Profiel"
                                url="https://ligplekkenonderweg.lvbhb.nl/page/profiel/" />
                 <LpowHelpTopic title="Instellingen"
                                url="https://ligplekkenonderweg.lvbhb.nl/page/instellingen/" />
                 <LpowHelpTopic title="Ligplek Aanmelden"
                                url="https://ligplekkenonderweg.lvbhb.nl/page/ligplek-aanmelden/" />
                 <LpowHelpTopic title="Ligplek Aanvullen"
                                url="https://ligplekkenonderweg.lvbhb.nl/page/ligplek-aanvullen/" />
                 <LpowHelpTopic title="Ligplek Adopteren"
                                url="https://ligplekkenonderweg.lvbhb.nl/page/ligplek-adopteren/" />
                 <LpowHelpTopic title="Pashouders"
                                url="https://ligplekkenonderweg.lvbhb.nl/page/pashouders/" />
              </div>
          </div>
        </div>
    )
  }

}


export default LpowAbout;
