import React, { Component } from 'react'
import PropTypes from "prop-types";
import Switch from "react-switch";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label } from "reactstrap";
import { LigplekDetails, LigplekUpdate, LigplekCreate } from "../components/LigplekModule";
import { LigplekAdopt } from "../components/LigplekModule";
import { showLigplek, hideLigplek, updateLigplek, createLigplek, showAdoptLigplek} from '../actions/ModalLigplekActions';
import {  saveCreatedLigplek, toggleFavouriteLigplek, toggleSelectLigplek } from "../actions/LpowActions";
import { doAdoptLigplek } from "../actions/AdoptedLigplekActions"
import { showOverlay } from "../actions/MapActions";


class FutureLigplekModal extends Modal {

  constructor(props) {
    super(props);
  }

  static propTypes = {
       ligplekModule: PropTypes.object.isRequired,
  }

  renderLigplekStuff() {
    // De specifieke module is meegegeven als propertie
    return ( this.props.LigplekModule )
  }

  render () {
      <Modal isOpen={this.props.isPaneOpen}>
         { this.renderLigplekStuff() }
      </Modal>
  }
}


class ToggleFavourite extends Component {

  state = {
      // value: false,
  }

  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
    this.state.value = this.props.value
  }

  static propTypes = {
     value: PropTypes.bool,
     setValue: PropTypes.func,
  }

  onToggle(value) {
    const newValue = !this.state.value
    this.setState({ value: newValue });
    this.props.setValue(newValue)
  }

  render() {
    return (
         <div className="float-right btn btn-primary lvbhb-toggle-button" >
             <FormGroup>
                 <Label className="toggle-label">Favoriet</Label>
                 <div className="favourite-button-container">
                 <Switch className="react-switch"
                      checked={ this.state.value || false }
                      onChange={this.onToggle} />
                 </div>
             </FormGroup>
         </div>
     )
  }
}

/*****
 * Soort van Manager voor Modale Ligplek dingen
 *
 *
 ****/
class LigplekModal extends Modal {

  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);
    this.updateLigplek = this.updateLigplek.bind(this);
    this.updateLigplekAfterCreate = this.updateLigplekAfterCreate.bind(this);
    this.showAdoptLigplek = this.showAdoptLigplek.bind(this);
    this.doAdoptLigplek = this.doAdoptLigplek.bind(this);
    this.toggleFavouriteLigplek = this.toggleFavouriteLigplek.bind(this);
    this.renderLigplekModalButtons = this.renderLigplekModalButtons.bind(this);
    this.renderAdoptButton = this.renderAdoptButton.bind(this);

    this.state.ligplekIsFavourite = this.props.ligplekIsFavourite;
  }

  static propTypes = {
      ligplekId: PropTypes.number.isRequired,
      ligplek: PropTypes.object,
      ligplekIsFavourite: PropTypes.bool.isRequired,
      username_email: PropTypes.string.isRequired, // todo: python style wegrefactoren
      token_key: PropTypes.string.isRequired  // todo: python style wegrefactoren
  }

  state = {
      validUpdate: false,
      validCreate: false,
      ligplekIsFavourite: false
  }

  // Todo: uitzoeken wat hier de bedoeling van is.
  // createLigplek() {
  //  this.props.dispatch( createLigplek()); //this.props.ligplekId));
  // }

  updateLigplekAfterCreate(ligplekJSON) {
    this.props.dispatch( updateLigplek(ligplekJSON.id)); //
    this.props.dispatch( saveCreatedLigplek(ligplekJSON) ); // bewaren in de localstorage
    this.props.dispatch( showOverlay("overlay_createdligplekken") );
  }

  updateLigplek() {
    this.props.dispatch( updateLigplek(this.props.ligplekId));
  }

  showAdoptLigplek() {
    if( this.props.token_key) {
        this.props.dispatch( showAdoptLigplek(this.props.ligplekId, this.props.token_key));
    }
  }

  doAdoptLigplek() {
    console.log(this.props.ligplek+" "+this.props.ligplekId+" "+this.props.token_key);
    if( this.props.token_key) {
        this.props.dispatch( doAdoptLigplek(this.props.ligplekId, this.props.token_key));
    }
  }

  toggleFavouriteLigplek() {
    this.setState({ligplekIsFavourite: !this.state.ligplekIsFavourite,})
    this.props.dispatch( toggleFavouriteLigplek(this.props.ligplekId ))
  }

  hideModal() {
    // verbergen Leaflet Pupup
    // introduceert wel een loze klik als je dezelfde plek nog een keer wilt laten zien
    let popupElementList = document.getElementsByClassName("leaflet-popup-pane");
    if(popupElementList && popupElementList[0]) {
       popupElementList[0].innerHTML = ""
    }
    this.props.dispatch( hideLigplek() );
  }


 renderLigplekModalButtons() {
    if(this.props.displayLigplekDetails)
        return(
              <ModalHeader className="buttonContainer bg-info clearfix">
                <Button color="primary" onClick={this.hideModal}>Sluiten</Button>
                <ToggleFavourite value={this.props.ligplekIsFavourite}
                                 setValue={this.toggleFavouriteLigplek} />
              </ModalHeader>
    )
    if(this.props.displayLigplekCreate)
        return(
              <ModalHeader className="buttonContainer bg-info clearfix">
                <Button color="primary" onClick={this.hideModal}>Sluiten</Button>
              </ModalHeader>
    )
    if(this.props.displayLigplekUpdate)
        return(
              <ModalHeader className="buttonContainer bg-info clearfix">
                <Button color="primary" onClick={this.hideModal}>Sluiten</Button>
              </ModalHeader>
    )
    if(this.props.displayLigplekAdopt)
        return(
              <ModalHeader className="buttonContainer bg-info clearfix">
                <Button color="primary" onClick={this.hideModal}>Sluiten</Button>
              </ModalHeader>
    )
 }

 renderAdoptButton() {
     let adopted_by = this.props.ligplek?.adopted_by_name ? this.props.ligplek.adopted_by_name : "";
     if(adopted_by!="") {  // server retourneerd "" als niet geadopteerd
         return( <span className="lpow-adopted-by">
                  Geadopteerd door <span> {adopted_by}</span>
                 </span> )
     }
     if( this.props.token_key) {
         return(<Button color="primary" onClick={this.showAdoptLigplek}>Adopteren</Button>)
     }
     return(<Button color="secondary">Login voor Adopteren</Button>)
 }

 renderLigplekModalFooter() {
    if(this.props.displayLigplekDetails) {
         return (
           <ModalFooter className="buttonContainer bg-info clearfix">
                    <Button color="primary" onClick={this.updateLigplek}>Aanvullen</Button>
                    { this.renderAdoptButton() }
           </ModalFooter>
         )
    }
 }

 renderLigplekModalContent() {
     // console.log("this.props.displayLigplekDetails: "+this.props.displayLigplekDetails);
     if(this.props.displayLigplekDetails) {
        return (<LigplekDetails ligplekId={this.props.ligplekId}
                                ligplek={this.props.ligplek} />)
     }
     if(this.props.displayLigplekCreate){
        return (<LigplekCreate latlng={this.props.latlng}
                               doLigplekCreate={this.updateLigplekAfterCreate} />)
     }
     if(this.props.displayLigplekUpdate){
        return (<LigplekUpdate ligplekId={this.props.ligplekId}
                               doLigplekUpdate={this.props.doLigplekUpdate} />)
     }
     if(this.props.displayLigplekAdopt){
        return (<LigplekAdopt ligplekId={this.props.ligplekId}
                              doLigplekAdopt={this.doAdoptLigplek}
                              username_email={this.props.username_email} />)
     }
  }

  render () {
     // console.log("LigplekModal this.props.ligplekId: "+this.props.ligplekId);
     // console.log("LigplekModal this.props.ligplek: "+this.props.ligplek);
     return (
      <Modal isOpen={this.props.isPaneOpen}
             toggle={this.hideModal}
             className={this.props.className}
             zIndex="1000" >

          {this.renderLigplekModalButtons()}

          <ModalBody>
            { this.renderLigplekModalContent() }
          </ModalBody>

          {this.renderLigplekModalFooter()}

      </Modal>
     )

  }
}


export default LigplekModal
