import { connect } from 'react-redux'
import ReactMessages from 'react-messages';

const mapStateToProps = state => {
    const durationInMilliseconds = 4000; // state.react_message.durationInSeconds * 1000
    return {
          message: state.react_message.message,
          next: state.react_message.next,
          error: state.react_message.error,
          icon: state.react_message.icon,
          duration: state.react_message.duration //InMilliseconds,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      dispatch,

    }
}


const ReactMessagesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReactMessages)

export default ReactMessagesContainer
