import React, { Component } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { Form, FormGroup, Input, Label, Button, ButtonGroup } from 'reactstrap'

import { LigplekList } from "./LigplekListModule"
import {showOverlay, hideAllOverlays } from "../actions/MapActions"

import { FilterDraft, FilterText, FilterDistance} from "./LpowFilters"
import { setDraftFilter, setDistanceFilter,
         setTextFilter, showSearchResultOnMap, addToSearchResultOnMap,
         clearSearchResultOnMap, createSearchResult } from "../actions/SearchActions"

class LigplekSearchResult extends Component {

  constructor(props) {
    super(props);
  }

  state = {
  }

  static propTypes = {
     ligpleklist: PropTypes.array,
     selectLigplek: PropTypes.func.isRequired,
     selected_ligplekken: PropTypes.array.isRequired,
  }

  render() {
    return (
       <div>
         <LigplekList ligpleklist={this.props.selected_ligplekken} />
       </div>
     )
  }
}


class LigplekSearch extends Component {

  constructor(props) {
    super(props);
    this.setTextFilterValue = this.setTextFilterValue.bind(this);
    this.setDraftFilterValue = this.setDraftFilterValue.bind(this);
    this.setDistanceFilterValue = this.setDistanceFilterValue.bind(this);
    this.doFiltering = this.doFiltering.bind(this);
    this.gotoMap = this.gotoMap.bind(this);
    this.currentResultOnMap = this.currentResultOnMap.bind(this);
    this.clearResultOnMap = this.clearResultOnMap.bind(this);
  }

  state = {
     filter_text: this.props.filter_text,
     switch_to_map: false
  }

  static propTypes = {
     search_result: PropTypes.array.isRequired, // de getoonde lijst
     selected_ligplekken: PropTypes.array.isRequired, // (reeds) geselecteerde ligplekken in zoekresultaat
     filter_draft: PropTypes.number.isRequired,
     filter_distance: PropTypes.number.isRequired,
     filter_text: PropTypes.string.isRequired,
     position: PropTypes.object.isRequired,
  }

  setDraftFilterValue(event) {
      this.props.dispatch(setDraftFilter(event.target.value));
      this.state.filter_draft = event.target.value;
      this.doFiltering();
  }

  setDistanceFilterValue(event) {
      this.props.dispatch(setDistanceFilter(event.target.value));
      this.state.filter_distance = event.target.value;
      this.doFiltering();
  }

  setTextFilterValue(event) {
      this.props.dispatch(setTextFilter(event.target.value));
      this.state.filter_text = event.target.value;
      this.doFiltering();
  }

  doFiltering() {
      this.props.dispatch( createSearchResult(this.state) )
  }

  currentResultOnMap() {
      this.props.dispatch(addToSearchResultOnMap());
  }

  clearResultOnMap() {
      this.props.dispatch(clearSearchResultOnMap());
  }

  gotoMap() {
      this.props.dispatch(hideAllOverlays());
      this.props.dispatch(showOverlay("overlay_zoekresultaat"));
      this.setState({switch_to_map: true});
      //let navigate = useNavigate();
      // navigate('/search/');
  }

  render() {
    if(this.state.switch_to_map) {
       return (<div>
                <Navigate to="/map/" />
               </div>)
    }

    let resultOnMapClass = "disabled";
    let resultOnMapDisabled = true;
    if (this.props.search_result.length>0) {
      resultOnMapClass = "";
      resultOnMapDisabled = false;
    }

    let clearOnMapClass = "disabled";
    let clearOnMapDisabled = true;
    if(this.props.selected_ligplekken) {
      clearOnMapClass = "";
      clearOnMapDisabled = false;
    }

    let toMapClass = ""; // "enabled";
    let toMapDisabled = false;
    let search_data = {
        name: "Zoek Resultaat",
        ligplek_list: this.props.search_result,
    }
    return (
      <div  className="small-not-on-mobile">
          <div className="filterValues">
             <FilterDraft value={this.props.filter_draft}
                          setFilterValue={this.setDraftFilterValue} />
             <FilterDistance value={this.props.filter_distance}
                             setFilterValue={this.setDistanceFilterValue}
                             position={this.props.position}/>
             <FilterText value={this.props.filter_text}
                         setFilterValue={this.setTextFilterValue} />

             <ButtonGroup>
             <Button disabled={resultOnMapDisabled} className={resultOnMapClass}
                     onClick={this.currentResultOnMap} >Huidig resutaat selecteren</Button>
             <Button disabled={clearOnMapDisabled} className={clearOnMapClass}
                     onClick={this.clearResultOnMap} >Schonen Selectie ({this.props.selected_ligplekken.length})</Button>
             <Button disabled={toMapDisabled} className={toMapClass}
                     onClick={this.gotoMap} >Naar Kaart</Button>
              </ButtonGroup>
          </div>
          <div className="filterResult">
             <LigplekList isFetching={false}
                          ligpleklist={search_data.ligplek_list}
                          list_data={search_data}
                          dispatch={this.props.dispatch}
                          selectLigplek={this.props.selectLigplek}
                          selected_ligplekken={this.props.selected_ligplekken} />
          </div>
      </div>
    );
  }
}


export default LigplekSearch;
