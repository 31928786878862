import React from 'react'
import PropTypes from "prop-types";
import {ListOfLigplekLists, LigplekListWithFilter, LigplekList  } from "../components/LigplekListModule";
import { showLigplek } from '../actions/ModalLigplekActions'
import { isMobile } from 'react-device-detect';


class LigplekCategorie extends React.Component {

   constructor(props) {
     super(props);
     this.toggleShowContent = this.toggleShowContent.bind(this);
     this.renderMobile = this.renderMobile.bind(this);
     this.renderMobilePanelHeading = this.renderMobilePanelHeading.bind(this);
     this.renderNotMobile = this.renderNotMobile.bind(this);
     this.renderNotMobileLigplekList = this.renderNotMobileLigplekList.bind(this);

   }

  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    list_data: PropTypes.array.isRequired,
    selected_id_list: PropTypes.array.isRequired,
    show_list_id: PropTypes.number.isRequired,

    selectLigplek: PropTypes.func.isRequired,
    selected_ligplekken: PropTypes.array.isRequired,
    showGroupOnMap: PropTypes.func.isRequired,
    showGroupLigpleklist: PropTypes.func.isRequired,
  }

  state = {
      filter_value: "",
  }



  toggleShowContent() {
    if(this.props.show_list_id>0) {
      this.props.showGroupLigpleklist(0);
    } else {
      this.props.showGroupLigpleklist(this.props.show_list_id);
    }
  }

  renderHeading() {
    if(this.props.show_list_id > 0) {
      return (<div className="panel-heading" onClick={this.toggleShowContent}>
               <h3 className="panel-title" >
                <span>&lt;&lt;</span>Categorie</h3>
               </div>)
    }
    return (<div className="panel-heading">
            <h3 className="panel-title">Categoriën</h3>
            </div>)
  }

  renderCategoriePanel() {
  }

  renderMobilePanelHeading(show_list_id) {
      if(show_list_id==0) {
          return( <div className="panel-heading">
                <h3 className="panel-title">Categoriën</h3>
              </div>)
      }
      return( <div className="panel-heading">
            <h3 className="panel-title">Categorie</h3>
          </div>)
  }

  renderMobile() {
    return (
        <div className="panel panel-primary categorie-ligplek-list" id="result_panel">
          {this.renderHeading(this.props.show_list_id)}
          <div className="panel-body">
             <ListOfLigplekLists isFetching={this.props.isFetching}
                                 list_data={this.props.list_data}
                                 selectLigplek={this.props.selectLigplek}
                                 selected_ligplekken={this.props.selected_ligplekken}
                                 showGroupOnMap={this.props.showGroupOnMap}
                                 showGroupLigpleklist={this.props.showGroupLigpleklist}
                                 show_list_id={this.props.show_list_id}
                                 selected_id_list={this.props.selected_id_list}
                                 dispatch={this.props.dispatch}
                                  />
          </div>
        </div>
        )
  }

  renderNotMobileLigplekList(list_data) {
    // console.log("renderNotMobileLigplekList: "+this.props.show_list_id+" Render list_data: "+list_data);
    if(this.props.show_list_id==0) {
       return (<div className="panel-body">
                <span>Kies een Categorie</span>
               </div>
               )
    }

    return (<div className="panel-body">
              <LigplekListWithFilter isFetching={this.props.isFetching}
                                    // ligpleklist={_ligpleklist}
                                    list_data={list_data} // hier zit de ligpleklist ook al in
                                    selectLigplek={this.props.selectLigplek}
                                    selected_ligplekken={this.props.selected_ligplekken}
                                    dispatch={this.props.dispatch}  />
            </div>
            )
  }


  renderNotMobile() {
    let _ligpleklist_data = {};
    if(this.props.show_list_id>0) {
        _ligpleklist_data = this.props.list_data.find(x => x.id == this.props.show_list_id);
    }

    return (
      <div className="no-mobile-row">
        <div className="panel panel-primary categorie-ligplek-list no-mobile-column" id="result_panel_1">
          <div className="panel-heading">
            <h3 className="panel-title">Categoriën</h3>
          </div>
          <div className="panel-body">
             <ListOfLigplekLists isFetching={this.props.isFetching}
                                 list_data={this.props.list_data}
                                 selectLigplek={function(){}} // we hoeven hier niets door te geven. gebeurt in deze context een niveau later
                                 selected_ligplekken={[]} // we hoeven hier niets door te geven. gebeurt in deze context een niveau later
                                 showGroupOnMap={this.props.showGroupOnMap}
                                 showGroupLigpleklist={this.props.showGroupLigpleklist}
                                 show_list_id={0}
                                 selected_id_list={this.props.selected_id_list} // lijst van geselecteerde lijsten
                                 dispatch={this.props.dispatch}
                                 />
          </div>
        </div>

        <div className="panel panel-primary ligplek-list no-mobile-column" id="result_panel_2">
          <div className="panel-heading">
            <h3 className="panel-title">Categorie: {_ligpleklist_data.name}</h3>
          </div>
           { this.renderNotMobileLigplekList(_ligpleklist_data) }
        </div>

      </div>
    )
  }

  render() {
    if(isMobile) {
       return this.renderMobile()
    }
    return this.renderNotMobile()
  }

}

export default LigplekCategorie
